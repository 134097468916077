import {
  Box,
  Button,
  Container,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { appContext } from "../../App";
import { getUserData, updateProfile, updateProperty } from "../../utils/api";
import toastr from "toastr";
import NewAddress from "../common/NewAddress";
import PhoneNumber from "../Phone/PhoneNumber";
import zIndex from "@material-ui/core/styles/zIndex";

function PropertySuccess(props) {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [errors, setErrors] = useState({});
  const [phone, setPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const phoneRef = useRef();
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setEmail(userData.email);
        setPhone(userData.phone);
        setIsPhoneVerified(userData.phone_verified);
        if (userData.address) {
          setAddress({
            line1: userData.address.hasOwnProperty("line1")
              ? userData.address.line1
              : "",
            line2: userData.address.hasOwnProperty("line2")
              ? userData.address.line2
              : "",
            city: userData.address.hasOwnProperty("city")
              ? userData.address.city
              : "",
            state: userData.address.hasOwnProperty("state")
              ? userData.address.state
              : "",
            zipcode: userData.address.hasOwnProperty("zipcode")
              ? userData.address.zipcode
              : "",
            country: userData.address.hasOwnProperty("country")
              ? userData.address.country
              : "",
          });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    if (authUser) {
      loadUserData(authUser.unique_id);
    }
  }, [authUser]);

  const handleUpdateContinue = () => {
    const errors = {};
    if (email === "") {
      errors.email = "Please enter email";
    }
    if (
      !tempAddress.city ||
      !tempAddress.state ||
      !tempAddress.country ||
      !tempAddress.zipcode
    ) {
      errors.address =
        "Please select Country, State and City and enter Zip code";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) return;
    const finalValues = {};
    finalValues.email = email;

    //finalValues.username = values.userName;

    //validate the phone input
    if (!phoneRef.current.isValid()) {
      return;
    }
    if (
      tempAddress &&
      (tempAddress.line1 ||
        tempAddress.line2 ||
        tempAddress.city ||
        tempAddress.state ||
        tempAddress.country ||
        tempAddress.zipcode)
    ) {
      const newAddress = {};
      newAddress.line1 = tempAddress.line1;
      newAddress.line2 = tempAddress.line2;
      newAddress.city = tempAddress.city;
      newAddress.state = tempAddress.state;
      newAddress.country = tempAddress.country;
      newAddress.zipcode = tempAddress.zipcode;
      finalValues.address = newAddress;
    }
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        setAuthUser(response.data.data);
        localStorage.setItem("authUser", JSON.stringify(response.data.data));
        toastr.success("Seller Contact Information Updated Successfully");
        props.history.push(`/sell-property`);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            toastr.error(error.response.data.errors[0].msg);
          } else {
            toastr.error(error.response.message);
          }
        } else {
          toastr.error(error);
        }
      });
  };
  const handleContinue = () => {
    props.history.push(`/sell-property`);
  };
  return (
    <Container maxWidth="lg">
      <Grid container className={classes.root} spacing={2}>
        <Grid item sm={12} md={9} spacing={2}>
          <Box className={classes.root} p={2} bgcolor="white">
            <Box
              my={2}
              variant="h6"
              component="h6"
              backgroundColor="white"
              fontSize="24px"
            ></Box>
            <Box
              component="body1"
              variant="body1"
              color="#2E2E2E"
              fontWeight="400"
              fontSize="18px"
              margin="30px 0px"
              padding="30px 0px"
            >
              Please check your contact information and update if required.
            </Box>
            <Grid container spacing={2} className="signphone phheight">
              <Grid item xs={12} md={6}>
                <InputLabel className="secFormLabel">Phone</InputLabel>
                <PhoneNumber
                  phone={phone}
                  setPhone={setPhone}
                  isPhoneVerified={isPhoneVerified}
                  ref={phoneRef}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Email</InputLabel>
                <TextField
                  type="text"
                  variant="outlined"
                  className="profile-fields email"
                  style={{ width: "100%" }}
                  name="email"
                  disabled={false}
                  value={email}
                  onChange={changeEmail}
                />
                {errors && errors.email && (
                  <Typography className="custom-error">
                    {errors.email}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="address">
              <InputLabel
                style={{ margin: "10px 0 0 0" }}
                className="secFormLabel"
              >
                Address
              </InputLabel>
              <NewAddress
                value={address}
                onChange={handleChangeAddress}
                disabled={false}
              />
              {errors && errors.address && (
                <Typography style={{ color: "red" }}>
                  {errors.address}
                </Typography>
              )}
            </Grid>
            <Grid container spacing={2} style={{ justifyContent: "flex-end" }}>
              <Button className="red_bg"
                color="primary"
                variant="contained"
                autoFocus
                style={{
                  borderRadius: 4,

                  margin: "5px",
                  padding: "15px",
                }}
                onClick={handleUpdateContinue}
              >
                Save and Continue...
              </Button>

              <Button className="red_bg"
                color="primary"
                variant="contained"
                autoFocus
                style={{
                  borderRadius: 4,
                  margin: "5px",
                  padding: "15px",
                }}
                onClick={handleContinue}
              >
                Everything looks good. Continue...
              </Button>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12} md={3}>
          <Box p={2} bgcolor="white">
            <Typography variant="h6" component="h4">
              Post a high quality listing
            </Typography>
            <p>
              to connnect with more buyers, make sure you are adding genuine
              images and share detailed information about the property
            </p>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
export default withRouter(PropertySuccess);
