import React, { useEffect, useContext, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link, TextareaAutosize } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import PodcastVideoPlayer from "../common/PodcastVideoPlayer";
import { Tooltip } from "@material-ui/core";
import PublicProfileSideBar from "../sidebars/publicProfileSidebar";
import IconButton from "@material-ui/core/IconButton";
import PropertyCard from "../PropertyCard/index";
import AddPhotos from "../common/AddPhotos";
import firebase from "../../firebase/firebase";
import StickyBox from "react-sticky-box";
import Button from "@material-ui/core/Button";
import AddVideo from "../common/AddVideo";
import DetailedPropertyCard from "../buy/DetailedPropertyCard";

import {
  getUserProperties,
  getUserDataWithUsername,
  updateProfile,
} from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { appContext } from "../../App";
import toastr from "toastr";
import Box from "@material-ui/core/Box";
import "./PublicProfile.scss";
import Share from "@material-ui/icons/Share";
import BriefPropertyCard from "../buy/BriefPropertyCard";
import ShareOnSocialMedia from "../Share/ShareOnSocialMedia";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // modalpaper: {
  //   backgroundColor: theme.palette.background.paper,
  //   textAlign: "center",
  //   borderRadius: 3,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
}));

const PublicProfile = (props) => {
  const {
    authUser,
    setAuthUser,
    isSuperAdmin,
    isAdmin,
    isVolunteer,
    region,
    rupee,
  } = useContext(appContext);
  // const { videos } = props;
  const classes = useStyles();
  // const [topDonationRequests] = useState([]);
  const [properties, setProperties] = useState([]);
  const [showCoverPhoto, setShowCoverPhoto] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [showProfilePhoto, setShowProfilePhoto] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    "/images/user_male_circle.svg"
  );
  const [coverImageUrl, setCoverImageUrl] = useState(
    "/images/celeb-profile-bg.jpeg"
  );
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [shareIcons, setShareIcons] = useState(false);
  const [selectedOption, setSelectedOption] = useState("timeline");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [showPhotos, setShowPhotos] = useState(false);

  const [noUser, setNoUser] = useState(false);
  const [open, setOpen] = useState(false);
  const [showVideos, setShowVideos] = useState(false);

  const loadProperties = (userId) => {
    getUserProperties("", "", userId, "1")
      .then((response) => {
        setProperties(response.data);
      })
      .catch((error) => {
        toastr.error("Error getting properties:" + error.message);
      });
  };
  const onSavingPhoto = (url, name) => {
    setImages([...images, { url, name }]);
  };

  const onSavingDocument = (url, name, userGivenName, note) => {
    setVideos([...videos, { url, name, userGivenName, note }]);
  };

  useEffect(() => {
    if (props.match.params.id) {
      getUserDataWithUsername(props.match.params.id)
        .then((response) => {
          setUserData(response);
          if (response.coverImageUrl) setCoverImageUrl(response.coverImageUrl);
          if (response.profile_image_url)
            setProfileImageUrl(response.profile_image_url);
          loadImages(response.unique_id);
          loadVideos(response.unique_id);
          loadProperties(response.unique_id);
        })
        .catch(() => {
          setNoUser(true);
        });
    }
  }, [props.match.params.id]);

  const removeFromList = (id) => {
    setProperties(properties.filter((property) => property._id !== id));
  };

  const makeIsContacted = (id) => {
    setProperties(
      properties.map((property) => {
        if (property._id === id) {
          return { ...property, isContacted: true };
        } else {
          return property;
        }
      })
    );
  };

  useEffect(() => {
    if (userData && userData.unique_id) {
      loadProperties(userData.unique_id);
      if (authUser && authUser.unique_id) {
        setEditMode(
          userData.unique_id === authUser.unique_id ||
            isSuperAdmin ||
            isAdmin ||
            isVolunteer
        );
      } else {
        setEditMode(false);
      }
    }
  }, [userData, authUser, region]);

  const loadImages = (uid) => {
    setImages([]);
    const path = `UserPictures/${uid}/images`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            item.getMetadata().then((metadata) => {
              setImages((prevImages) => [
                ...prevImages,
                { url: url, name: metadata.name },
              ]);
            });
          });
        });
      })
      .catch((e) => {
        toastr.error(e.message);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const loadVideos = (uid) => {
    setVideos([]);
    const path = `UserVideos/${uid}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              setVideos((prevVideos) => [
                ...prevVideos,
                {
                  url: url,
                  name: metadata.name,
                  userGivenName: metadata.customMetadata.userGivenName,
                  note: metadata.customMetadata.note,
                },
              ]);
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        });
      });
    });
  };

  useEffect(() => {
    if (userData && userData.unique_id) {
      if (selectedOption === "timeline") {
      } else if (selectedOption === "photos") {
      } else if (selectedOption === "videos") {
      }
    }
  }, [selectedOption]);

  const updateDefaultImageUrl = (url) => {
    setProfileImageUrl(url);
    const finalValues = {
      image_url: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then(() => {
        if (authUser.unique_id === userData.unique_id) {
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              profile_image_url: url,
            };
          });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const updateCoverImageUrl = (url) => {
    setCoverImageUrl(url);
    const finalValues = {
      coverImageUrl: url,
    };

    updateProfile(authUser.unique_id, finalValues)
      .then(() => {
        if (authUser.unique_id === userData.unique_id) {
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              coverImageUrl: url,
            };
          });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const onPlayVideo = () => {
    setIsPlayingVideo(true);
  };

  const onClosePlayVideo = () => {
    setIsPlayingVideo(false);
  };

  const viewAllPhotosHandler = () => {
    setSelectedOption("photos");
  };

  const viewAllVideosHandler = () => {
    setSelectedOption("videos");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="public-profile-container">
      <Container maxWidth="lg">
        <div class="public-main">
          {!noUser && (
            <Fragment>
              <div className="public-cover-page">
                {authUser &&
                  userData &&
                  authUser.unique_id === userData.unique_id && (
                    <div className="updateBannerBlk">
                      <CameraAltIcon
                        onClick={() => setShowCoverPhoto(true)}
                        className="updateIcon"
                      />
                    </div>
                  )}
                <Box
                  bgcolor="#e9eaec"
                  borderRadius="10px"
                  style={{ height: "300px", overflow: "hidden" }}
                >
                  <img
                    src={coverImageUrl}
                    className="cover-pic"
                    alt="cover-pic"
                    width="100%"
                  />
                </Box>

                <CardHeader
                  className="public-card-header"
                  avatar={
                    <div className="profile-pic-container">
                      <div className="profile-main">
                        {authUser &&
                          userData &&
                          authUser.unique_id === userData.unique_id && (
                            <div className="profileCamIcon">
                              <CameraAltIcon
                                onClick={() => setShowProfilePhoto(true)}
                                className="updateIcon"
                              />
                            </div>
                          )}
                        <div className="pulic-propic">
                          <img
                            src={profileImageUrl}
                            alt="cover-pic"
                            className="profile-pic"
                          />
                        </div>
                      </div>

                      <h2>
                        {userData &&
                          userData.name &&
                          userData.name.first_name +
                            " " +
                            userData.name.last_name}{" "}
                        <VerifiedUser
                          style={{ color: "green", fontSize: 18 }}
                        />
                      </h2>
                      {/* DO NOT delete */}
                      {/* <Button
                    className="public-cause-btn"
                    onClick={handleDonateToMyCauses}
                  >
                    Donate To My Causes
                  </Button> */}
                    </div>
                  }
                  title={
                    <div className="borderBottom-nav">
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={`${classes.root} public-page-navbar`}
                      >
                        <ListItem button className="options">
                          <ListItemText
                            primary="Timeline"
                            style={{
                              color:
                                selectedOption === "timeline"
                                  ? "#ec1c24"
                                  : "#000",
                              borderBottom:
                                selectedOption === "timeline"
                                  ? "4px solid #ec1c24"
                                  : "none",
                              textAlign: "center",
                              paddingBottom: 16,
                              margin: 0,
                            }}
                            className="tabname"
                            onClick={() => setSelectedOption("timeline")}
                          />
                        </ListItem>
                        <ListItem button className="options">
                          <ListItemText
                            primary="About"
                            style={{
                              color:
                                selectedOption === "about" ? "#ec1c24" : "#000",
                              borderBottom:
                                selectedOption === "about"
                                  ? "4px solid #ec1c24"
                                  : "none",
                              textAlign: "center",
                              paddingBottom: 15,
                              margin: 0,
                            }}
                            className="tabname"
                            onClick={() => setSelectedOption("about")}
                          />
                        </ListItem>
                        <ListItem button className="options">
                          <ListItemText
                            primary="Photos"
                            style={{
                              textAlign: "center",
                              color:
                                selectedOption === "photos"
                                  ? "#ec1c24"
                                  : "#000",
                              borderBottom:
                                selectedOption === "photos"
                                  ? "4px solid #ec1c24"
                                  : "none",
                              paddingBottom: 15,
                              margin: 0,
                            }}
                            className="tabname"
                            onClick={() => setSelectedOption("photos")}
                          />
                        </ListItem>
                        <ListItem button className="options">
                          <ListItemText
                            primary="Videos"
                            style={{
                              color:
                                selectedOption === "videos"
                                  ? "#ec1c24"
                                  : "#000",
                              borderBottom:
                                selectedOption === "videos"
                                  ? "4px solid #ec1c24"
                                  : "none",
                              textAlign: "center",
                              paddingBottom: 15,
                              margin: 0,
                            }}
                            onClick={() => setSelectedOption("videos")}
                            className="tabname"
                          />
                        </ListItem>
                        {/* Do Not Delete */}
                        {/* <ListItem button className="options">
                      <ListItemText
                        primary="Audio Rooms"
                        style={{
                          textAlign: "center",
                          color:
                            selectedOption === "Audio Rooms"
                              ? "#3C95E8"
                              : "#000",
                          borderBottom:
                            selectedOption === "Audio Rooms"
                              ? "4px solid #3C95E8"
                              : "none",
                          paddingBottom: 15,
                          margin: 0,
                        }}
                        className="tabname"
                        onClick={() => setSelectedOption("Audio Rooms")}
                      />
                    </ListItem> */}
                      </List>

                      <ListItem
                        button
                        className="share-option"
                        style={{ padding: "0", height: "50px" }}
                      >
                        <ListItemText>
                          <div
                            className="share-cont red_bg"
                            style={{borderRadius:"4px"}}
                            onClick={() => setShareIcons(true)}
                            onMouseLeave={() => setShareIcons(false)}
                          >
                            <Tooltip title="Share Public Profile">
                              <IconButton
                                aria-label="share"
                                className="share-icon-sizes"
                              
                                onClick={handleClickOpen}
                              >
                                <Box className="share-mobile-icon">
                                  <Share />
                                </Box>
                                <Box className="share-desk-icon">
                                  <ListItem style={{ fontSize: 14 }} button>
                                    <FontAwesomeIcon
                                      size="1px"
                                      icon={faShare}
                                      style={{ marginRight: 8 }}
                                    />{" "}
                                    Share
                                  </ListItem>
                                </Box>
                              </IconButton>
                            </Tooltip>

                            {shareIcons && (
                              <span>
                                <ShareOnSocialMedia
                                  open={open}
                                  handleClose={() => setOpen(false)}
                                  quote={userData && userData.summary}
                                  url={`${process.env.REACT_APP_BASE_URL}/${
                                    userData && userData.username
                                  }`}
                                  subject={`[Zenrth ] Have you seen this profile "${userData.username}"?`}
                                  content={`Hello,<br /><br />I thought you might be interested in this profile of ${
                                    userData.username
                                  } profile:<br /> ${
                                    process.env.REACT_APP_BASE_URL
                                  }/${userData && userData.username}
                                <br /><br /> Thanks, <br />Zenrth`}
                                  redirectPage={`/${userData.username}`}
                                  shareURL={`${
                                    process.env.REACT_APP_BASE_URL
                                  }/${userData && userData.username}`}
                                />
                              </span>
                            )}
                          </div>
                        </ListItemText>
                      </ListItem>
                    </div>
                  }
                />
              </div>
            </Fragment>
          )}
          {selectedOption === "timeline" && (
            <div className="public-page-body">
              {noUser ? (
                <h3 className="no-user-text">
                  No user found with the username...
                </h3>
              ) : (
                <Grid container spacing={2} className="public-body-grid">
                  <Grid item xs={12} sm={3} md={3} className="public-grid-left">
                    <StickyBox
                      offsetTop={20}
                      offsetBottom={20}
                      className="drop-box"
                      style={{ padding: "15px" }}
                    >
                      <PublicProfileSideBar
                        editMode={editMode}
                        userData={userData}
                        viewAllPhotos={viewAllPhotosHandler}
                        viewAllVideos={viewAllVideosHandler}
                        images={images}
                        videos={videos}
                        onSavingImage={onSavingPhoto}
                        onSavingVideo={onSavingDocument}
                      />
                    </StickyBox>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    md={9}
                    className="public-grid-right"
                  >
                    <Fragment>
                      {properties && properties.length > 0 ? (
                        properties.map((property, index) => {
                          return (
                            <DetailedPropertyCard
                              property={property}
                              key={property._id}
                              rupee={rupee}
                              removeFromList={() =>
                                removeFromList(property._id)
                              }
                              makeIsContacted={() =>
                                makeIsContacted(property._id)
                              }
                            />
                          );
                        })
                      ) : (
                        <p className="no-req-text">No Properties created</p>
                      )}
                    </Fragment>
                  </Grid>
                </Grid>
              )}
            </div>
          )}

          {selectedOption === "about" && (
            <div className="public-page-body about-option">
              <div className="about-section">
                <h2 className="red_text">About</h2>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Box
                        style={{
                          borderRight: "1px solid #FAFDF4",
                          minHeight: "100%",
                        }}
                      >
                        {userData && userData.facebookProfileUrl && (
                          <p>
                            <img
                              src="/images/public_facebook.png"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.facebookProfileUrl}
                              target="blank"
                            >
                              My Facebook Page
                            </a>
                          </p>
                        )}
                        {userData && userData.instagramProfileUrl && (
                          <p>
                            <img
                              src="/images/public_instagram.png"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.instagramProfileUrl}
                              target="blank"
                            >
                              My Instagram Page
                            </a>
                          </p>
                        )}
                        {userData && userData.twitterProfileUrl && (
                          <p>
                            <img
                              src="/images/public_twitter.png"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.twitterProfileUrl}
                              target="blank"
                            >
                              My Twitter Page
                            </a>
                          </p>
                        )}
                        {userData && userData.linkedInProfileUrl && (
                          <p>
                            <img
                              src="/images/public_linkedin.webp"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.linkedInProfileUrl}
                              target="blank"
                            >
                              My LinkedIn Page
                            </a>
                          </p>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={9}>
                      <Box style={{ background: "#FAFDF4", padding: "10px" }}>
                        <p
                          style={{
                            color: "rgb(205, 38, 39)",
                            fontWeight: "bold",
                            margin: "0",
                          }}
                        >
                          {userData && userData.title}
                        </p>

                        <p>
                          <TextareaAutosize
                            name="whyYouNeedHelp"
                            variant="outlined"
                            multiline
                            disabled
                            rows="4"
                            style={{
                              border: "none",
                              outline: "none",
                              resize: "none",
                              background: "transparent",
                              lineHeight: "20px",
                            }}
                            className={
                              "input-field request-title justifyToOthers"
                            }
                            label="Justify why you need help from others"
                            defaultValue={userData && userData.summary}
                          />
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          )}

          {selectedOption === "photos" && (
            <div className="public-page-body photos-option">
              <div className="photos-section">
                <Grid container>
                  <Grid md={6} sm={6} xs={6}>
                    {" "}
                    <h2 style={{ textAlign: "left" ,color:"#ec1c24"}}>Photos</h2>
                  </Grid>

                  <Grid md={6} sm={6} xs={6}>
                    {editMode && (
                      <Button
                        className="red_bg"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowPhotos(true)}
                      >
                        Upload Photos
                      </Button>
                    )}
                  </Grid>
                  {images.length > 0 ? (
                    images.map((image) => (
                      <div className="images-container">
                        <img src={image.url} alt={image.name} width="100%" />
                      </div>
                    ))
                  ) : (
                    <Grid xs={12}>
                      <p className="no-req-text">No photos uploaded</p>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          )}

          {selectedOption === "videos" && (
            <div className="public-page-body videos-option">
              <div className="videos-section">
                <Grid container>
                  <Grid md={6} sm={6} xs={6}>
                    {" "}
                    <h2 style={{ textAlign: "left",color:"#ec1c24" }}>Videos</h2>
                  </Grid>
                  {editMode && (
                    <Grid md={6} sm={6} xs={6}>
                      <Button
                        className="red_bg"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowVideos(true)}
                      >
                        Upload Videos
                      </Button>
                    </Grid>
                  )}
                  {videos.length > 0 ? (
                    videos.map((video, index) => (
                      <Grid md={3} sm={6} style={{ padding: "10px" }}>
                        <div className="images-container">
                          <PodcastVideoPlayer
                            key={index}
                            isOpen={isPlayingVideo}
                            onClose={onClosePlayVideo}
                            onPlayVideo={() => onPlayVideo(video)}
                            url={video.url}
                            userGivenName={video.userGivenName}
                            note={video.note}
                          />
                        </div>
                      </Grid>
                    ))
                  ) : (
                    <Grid xs={12}>
                      {" "}
                      <p className="no-req-text">No videos uploaded</p>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          )}

          <AddPhotos
            title="Manage Cover Photo"
            note="Please upload the .PNG, .JPG, .BMP, .MP4 images with these dimensions 1200 X 800"
            isOpen={showCoverPhoto}
            onClose={() => setShowCoverPhoto(false)}
            uid={authUser && authUser.unique_id}
            collection="CoverPictures"
            profilephotoURL={coverImageUrl}
            onProfilephotoURLChange={updateCoverImageUrl}
            canEdit={true}
            multiple={false}
          />
          <AddPhotos
            title="Manage Profile Photo"
            isOpen={showProfilePhoto}
            onClose={() => setShowProfilePhoto(false)}
            uid={authUser && authUser.unique_id}
            collection="ProfilePictures"
            profilephotoURL={profileImageUrl}
            onProfilephotoURLChange={updateDefaultImageUrl}
            canEdit={true}
            multiple={false}
          />

          <AddPhotos
            title="Manage Images"
            isOpen={showPhotos}
            onClose={() => setShowPhotos(false)}
            uid={userData && userData.unique_id}
            collection="UserPictures"
            canEdit={editMode}
            profileRequired={false}
            onSavingPhoto={onSavingPhoto}
            multiple={true}
          />
          <AddVideo
            title="Add Videos"
            isOpen={showVideos}
            onClose={() => setShowVideos(false)}
            uid={userData && userData.unique_id}
            collection="UserVideos"
            onSavingDocument={onSavingDocument}
            canEdit={editMode}
          />
        </div>
      </Container>
    </div>
  );
};

export default PublicProfile;
