import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { authorize } from "../../../utils/api";
import { appContext } from "../../../App";
import { DASHBOARD_PAGE, HOME_PAGE } from "../../../utils/utils";
import Loader from "../../common/Loader";
import firebase from "../../../firebase/firebase";

const Authorize = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(true);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let client_id = params.get("client_id");
    let access_token = params.get("access_token");
    var obj = {
      client_id: client_id,
      access_token: access_token,
    };
    authorize(obj)
      .then((response) => {
        setAuthUser(response.data.data);
        localStorage.setItem("authUser", JSON.stringify(response.data.data));
        localStorage.setItem(
          "stripeCustomerId",
          response.data.data.stripeCustomerId !== ""
            ? response.data.data.stripeCustomerId
            : "null"
        );
        setIsWaitingForResponse(false);
        const redirectUri = localStorage.getItem("redirectUri");
        if (redirectUri) {
          localStorage.removeItem("redirectUri");
          props.history.push(redirectUri);
        } else {
          props.history.push(DASHBOARD_PAGE);
        }
        firebase.analytics.logEvent("login", { method: "social" });
      })
      .catch((error) => {
        toastr.error("Error during authorizaton:" + error.message);
        props.history.push(HOME_PAGE);
      });
  }, []);

  return (
    <Loader
      isOpen={isWaitingForResponse}
      onClose={(e) => setIsWaitingForResponse(false)}
    />
  );
};

export default withRouter(Authorize);
