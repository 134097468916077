import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import {
  FavoriteOutlined,
  LocationOn,
  NetworkWifi,
  Share,
} from "@material-ui/icons";
import React, {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactImageGallery from "react-image-gallery";
import { withRouter } from "react-router";
import {
  DATE_FORMAT,
  formatNumber,
  getLocalityInStringFormat,
  getObjectValuesInStringFormat,
  isImage,
  rupeeValue,
  STATUS_APPROVED,
} from "../../utils/utils";
import ReactHtmlParser from "react-html-parser";
import Comments from "../PropertyReviews/Comments";
// import Faqs from "./Faqs";
import firebase from "../../firebase/firebase";
import { favourite, getBuyers, getProperty } from "../../utils/api";
import toastr from "toastr";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import ContactDeveloper from "./ContactDeveloper";
import GoogleMap from "./MapContainer";
import { makeStyles } from "@material-ui/core/styles";
import { appContext } from "../../App";
import ShareProperty from "../Share/ShareProperty";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ReactPlayer from "react-player";
import DocumentRow from "../common/DocumentRow";
import EditIcon from "@material-ui/icons/Edit";
import { roundToTwoPlaces } from "../../utils/utils";
import { styled } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  map: {
    "& div": {
      "& div": {
        position: "relative",
        height: "0px",
      },
    },
  },
  rightcard: {
    position: "sticky",
    top: "170px",
  },
  navmain: {
    backgroundColor: "#f8f8f8",
    display: "flex",
    gridGap: "30px",
    marginTop: "20px",
    borderBottom: "5px solid white",
    position: "sticky",
    top: "93px",
    zIndex: "5",

    [theme.breakpoints.down("sm")]: {
      display: "none",
      gridGap: "0",
    },
    "& span": {
      fontSize: "1.1rem",
      marginBottom: "20px",
    },
    "& div": {
      cursor: "pointer",
      borderBottom: "2px solid #f8f8f8",
      padding: "15px",
      "&:hover": {
        color: "#3D69B2",
        borderBottom: "2px solid #3D69B2",
      },
    },
  },
}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetailedPage = (props) => {
  const { authUser, setOpenLogin, isAdmin, rupee } = useContext(appContext);
  const [isCommentsDialogOpen, setIsCommentsDialogOpen] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [images, setImages] = useState([]);
  const [property, setProperty] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showFavorited, setShowFavorited] = useState("");
  const [shareIcons, setShareIcons] = useState(false);
  const [opened, setOpened] = useState(false);
  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [propConfigImages, setPropConfigImages] = useState([]);
  const [propConfigImageDocuments, setPropConfigImageDocuments] = useState([]);
  const [nonPropConfigImageDocuments, setNonPropConfigImageDocuments] =
    useState([]);
  const [rupeeToDollarExchangeValue, setRupeeToDollarExchangeValue] =
    useState(0);
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const months = [
    "January",
    "Febrauary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  useEffect(() => {
    if (rupee) {
      setRupeeToDollarExchangeValue(1 / rupee);
    }
  }, [rupee]);

  useEffect(() => {
    if (props.match.params.id) {
      loadProperty(props.match.params.id);
    }
  }, [props.match.params.id, authUser]);

  useEffect(() => {
    if (props.match.params.id) {
      fetchImages(props.match.params.id);
      fetchDocuments(props.match.params.id);
    }
  }, [props.match.params.id]);

  const loadProperty = (id) => {
    setIsLoading(true);
    getProperty(id, authUser ? authUser.unique_id : "")
      .then((response) => {
        setIsLoading(false);
        if (response) {
          setProperty(response);
          setShowFavorited(response.isFavourite);
          if (
            (isAdmin && response.createdUserId && response.createdUserId._id) ||
            (authUser &&
              response.createdUserId &&
              response.createdUserId._id === authUser.unique_id)
          ) {
            loadBuyers(response._id, response.createdUserId._id);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching property:" + error.message);
      });
  };
  const loadBuyers = (id, userId) => {
    setIsLoading(true);
    getBuyers(id, userId)
      .then((response) => {
        setIsLoading(false);
        if (response) {
          setBuyers(response.contactedUsersList);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching property:" + error.message);
      });
  };
  const fetchImages = (uid) => {
    // Fetch images
    const path = `Property/${uid}/images`;
    const storageRef = firebase.storageProperties.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        if (result.items && result.items.length > 0) {
          setImages([]);
          result.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              setImages((prevImages) => {
                return [
                  ...prevImages,
                  {
                    original: url,
                    thumbnail: url,
                  },
                ];
              });
            });
          });
        } else {
          setImages([
            {
              original: "/images/sell_home.jpg",
              thumbnail: "/images/sell_home.jpg",
            },
          ]);
        }
        fetchVideos(uid);
      })
      .catch(() => {
        fetchVideos(uid);
      });
  };
  const fetchVideos = (uid) => {
    // Fetch videos
    const path = `Property/${uid}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        if (result.items && result.items.length > 0) {
          result.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              setImages((prevImages) => {
                return [
                  ...prevImages,
                  {
                    original: "/images/sell_home.jpg",
                    thumbnail: "/images/sell_home.jpg",
                    embedUrl: url,
                    renderItem: _renderVideo.bind(this),
                  },
                ];
              });
            });
          });
        }
      })
      .catch(() => {});
  };
  const fetchDocuments = (uid) => {
    const path = `Property/${uid}/documents`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      setImageDocuments([]);
      setNonImageDocuments([]);
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              if (isImage(metadata.contentType)) {
                setImageDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    contentType: metadata.contentType,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              } else {
                setNonImageDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    contentType: metadata.contentType,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              }
            })
            .catch(() => {
              //toastr.error(error.message);
            });
        });
      });
    });
  };

  const fetchPropertyConfigurationImages = (uid, propertyConfigName) => {
    // Fetch images
    const path = `Property/${uid}/configuration/${propertyConfigName}/images`;
    const storageRef = firebase.storageProperties.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        if (result.items && result.items.length > 0) {
          setPropConfigImages([]);
          result.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              setPropConfigImages((prevImages) => {
                return [
                  ...prevImages,
                  {
                    original: url,
                    thumbnail: url,
                  },
                ];
              });
            });
          });
        } else {
          setPropConfigImages([
            {
              original: "/images/sell_home.jpg",
              thumbnail: "/images/sell_home.jpg",
            },
          ]);
        }
        fetchPropertyConfigurationVideos(uid, propertyConfigName);
      })
      .catch(() => {
        fetchPropertyConfigurationVideos(uid, propertyConfigName);
      });
  };
  const fetchPropertyConfigurationVideos = (uid, propertyConfigName) => {
    // Fetch videos
    const path = `Property/${uid}/configuration/${propertyConfigName}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef
      .listAll()
      .then((result) => {
        if (result.items && result.items.length > 0) {
          result.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              setPropConfigImages((prevImages) => {
                return [
                  ...prevImages,
                  {
                    original: "/images/sell_home.jpg",
                    thumbnail: "/images/sell_home.jpg",
                    embedUrl: url,
                    renderItem: _renderVideo.bind(this),
                  },
                ];
              });
            });
          });
        }
      })
      .catch(() => {});
  };
  const fetchPropertyConfigurationDocuments = (uid, propertyConfigName) => {
    const path = `Property/${uid}/configuration/${propertyConfigName}/documents`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      setPropConfigImageDocuments([]);
      setNonPropConfigImageDocuments([]);
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              if (isImage(metadata.contentType)) {
                setPropConfigImageDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    contentType: metadata.contentType,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              } else {
                setNonPropConfigImageDocuments((prevDocuments) => [
                  ...prevDocuments,
                  {
                    url: url,
                    name: metadata.name,
                    contentType: metadata.contentType,
                    userGivenName: metadata.customMetadata
                      ? metadata.customMetadata.userGivenName
                      : metadata.name,
                    note:
                      metadata.customMetadata && metadata.customMetadata.note,
                  },
                ]);
              }
            })
            .catch(() => {
              //toastr.error(error.message);
            });
        });
      });
    });
  };
  const _renderVideo = (item) => {
    return (
      <ReactPlayer
        url={item.embedUrl}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
      />
    );
  };

  const onClick = () => {
    setRefresh(!refresh);
  };
  const FancyButton = forwardRef(({ href, text, onClick }, ref) => (
    <Box ref={ref} href={href} onClick={onClick}>
      <Typography variant="h6" component="span">
        {text}
      </Typography>
    </Box>
  ));
  const makeFavourite = () => {
    setShowFavorited((currentValue) => {
      return !currentValue;
    });
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.propertyId = property["_id"];
      obj.createdUserId = authUser.unique_id;
      if (showFavorited === false) {
        obj.type = "favourite"; // favourite or unfavourite
      } else if (showFavorited === true) {
        obj.type = "unfavourite";
      }
      favourite(obj);
    } else {
      toastr.warning("Please signup or login to complete this action");
      setOpenLogin(true);
      setShowFavorited(property && property.isFavourite);
    }
  };
  const makeIsContacted = (id) => {
    property.isContacted = true;
  };
  const handleContactSeller = (id) => {
    if (authUser && authUser.unique_id) {
      setOpen(true);
    } else {
      localStorage.setItem("redirectUri", `/property/${id}`);
      setOpenLogin(true);
    }
  };
  const handleUser = (property) => {
    if (property.builderId && property.builderId !== "self") {
      window.open(`/builder/${property.builderId._id}`, "blank");
    } else {
      window.open(`/user/${property.createdUserId.username}`, "blank");
    }
  };
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    fetchPropertyConfigurationImages(props.match.params.id, panel);
    fetchPropertyConfigurationDocuments(props.match.params.id, panel);
  };
  return (
    <Container>
      <Box component="section" bgcolor="white" p={3} my={2}>
        <Grid container spacing={2} style={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={3} style={{ paddingTop: "0" }}>
            <Typography
              component="h5"
              variant="h5"
              style={{ color: "#ec1c24", fontSize: "1.2rem" }}
              className="prop_head"
            >
              {property.propertyName}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {property &&
                property.location &&
                property.location.line1 &&
                `${property.location.line1}, `}
              {property &&
                property.location &&
                property.location.line2 &&
                `${property.location.line2}, `}
              {property &&
                property.location &&
                property.location.locality &&
                `${property.location.locality}, `}
              {property &&
                property.location &&
                property.location.city &&
                `${property.location.city}, `}
              {property &&
                property.location &&
                property.location.state &&
                `${property.location.state}, `}
              {property &&
                property.location &&
                property.location.country &&
                `${property.location.country}, `}
              {property &&
                property.location &&
                property.location.zipcode &&
                `${property.location.zipcode}.`}

              <br />
              {property &&
                property.location &&
                property.location.landmark &&
                `Landmark: ${property.location.landmark}`}
            </Typography>
          </Grid>
          {property.propertyConfiguration &&
            property.propertyConfiguration.length === 1 &&
            property.propertyConfiguration.map((propertyConfig, index) =>
              propertyConfig.propertyType !== "Residential Land" ? (
                <Grid itam xs={12} sm={12} md={7}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        component="section"
                        marginRight="16px"
                        borderRight="2px solid #CCCCCC"
                        minHeight="100%"
                      >
                        <Box
                          component="p"
                          variant="p"
                          fontWeight="700"
                          fontSize="15px"
                          margin="0"
                        >
                          <FontAwesomeIcon size="1x" icon={faRupeeSign} />{" "}
                          {propertyConfig.cost !== 0 &&
                            formatNumber(roundToTwoPlaces(propertyConfig.cost))}
                        </Box>
                        <Box component="p" pt={1}>
                          (<FontAwesomeIcon size="1x" icon={faDollarSign} />{" "}
                          {propertyConfig.cost !== 0 &&
                            formatNumber(
                              roundToTwoPlaces(
                                propertyConfig.cost * rupeeToDollarExchangeValue
                              )
                            )}
                          )
                        </Box>
                      </Box>
                    </Grid>

                    <Grid container spacing={2} item xs={12} sm={12} md={3}>
                      {propertyConfig.numberOfBedrooms !== 0 && (
                        <Grid item xs={12} sm={12} md={6}>
                          <Box
                            component="section"
                            borderRight="2px solid #CCCCCC"
                            minHeight="100%"
                          >
                            <Box
                              component="p"
                              variant="p"
                              color="black"
                              fontWeight="700"
                              fontSize="18px"
                              margin="0"
                            >
                              {propertyConfig.numberOfBedrooms}
                            </Box>
                            <Box
                              component="body1"
                              variant="body1"
                              color="#7E7E7E"
                              fontSize="13px"
                              fontWeight="400"
                            >
                              Beds
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {propertyConfig.numberOfBathrooms !== 0 && (
                        <Grid item xs={12} sm={12} md={6} className="cntral">
                          <Box
                            component="section"
                            borderRight="2px solid #CCCCCC"
                            minHeight="100%"
                          >
                            <Box
                              component="p"
                              variant="p"
                              color="black"
                              fontWeight="700"
                              fontSize="18px"
                              margin="0"
                            >
                              {propertyConfig.numberOfBathrooms}
                            </Box>
                            <Box
                              component="body1"
                              variant="body1"
                              color="#7E7E7E"
                              fontSize="13px"
                              fontWeight="400"
                            >
                              Baths
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2} item xs={12} sm={12} md={3}>
                      {propertyConfig.numberOfBalconies !== 0 && (
                        <Grid item xs={12} sm={12} md={6} className="cntral">
                          <Box
                            component="section"
                            borderRight="2px solid #CCCCCC"
                            minHeight="100%"
                          >
                            <Box
                              component="p"
                              variant="p"
                              color="black"
                              fontWeight="700"
                              fontSize="18px"
                              margin="0"
                            >
                              {propertyConfig.numberOfBalconies}
                            </Box>
                            <Box
                              component="body1"
                              variant="body1"
                              color="#7E7E7E"
                              fontSize="13px"
                              fontWeight="400"
                            >
                              Balconies
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {propertyConfig.numberOfFloors !== 0 && (
                        <Grid item xs={12} sm={12} md={6}>
                          <Box
                            component="section"
                            borderRight="2px solid #CCCCCC"
                            minHeight="100%"
                          >
                            <Box
                              component="p"
                              variant="p"
                              color="black"
                              fontWeight="700"
                              fontSize="18px"
                              margin="0"
                            >
                              {propertyConfig.numberOfFloors}
                            </Box>
                            <Box
                              component="body1"
                              variant="body1"
                              color="#7E7E7E"
                              fontSize="13px"
                              fontWeight="400"
                            >
                              Floors
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                    {propertyConfig.posessionStatus && (
                      <Grid item xs={12} sm={12} md={3}>
                        <Box component="section">
                          <Box
                            component="p"
                            variant="p"
                            color="black"
                            fontWeight="700"
                            fontSize="18px"
                            margin="0"
                          >
                            {propertyConfig.posessionStatus}
                          </Box>
                          <Box
                            component="body1"
                            variant="body1"
                            color="#2E2E2E"
                            fontWeight="400"
                            fontSize="13px"
                          >
                            Posession Status
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        component="body1"
                        variant="body1"
                        color="black"
                        fontWeight="500"
                        fontSize="13px"
                      >
                        <FontAwesomeIcon size="1x" icon={faRupeeSign} />{" "}
                        {propertyConfig.cost !== 0 &&
                          propertyConfig.builtupArea &&
                          roundToTwoPlaces(
                            propertyConfig.cost /
                              propertyConfig.builtupArea.number
                          )}
                        {propertyConfig.cost !== 0 && "/"}
                        {propertyConfig.cost !== 0 &&
                          propertyConfig.builtupArea.units}
                      </Box>
                    </Grid>
                    {propertyConfig.builtupArea && (
                      <Grid item xs={12} sm={12} md={3}>
                        <Box
                          component="body1"
                          variant="body1"
                          color="black"
                          fontWeight="500"
                          fontSize="13px"
                        >
                          Built Up Area:{" "}
                          {propertyConfig.builtupArea &&
                            propertyConfig.builtupArea.number}{" "}
                          {propertyConfig.builtupArea &&
                            propertyConfig.builtupArea.units}
                        </Box>
                      </Grid>
                    )}
                    {propertyConfig.carpetArea && (
                      <Grid item xs={12} sm={12} md={3}>
                        <Box
                          component="body1"
                          variant="body1"
                          color="black"
                          fontWeight="500"
                          fontSize="13px"
                        >
                          Carpet Area:{" "}
                          {propertyConfig.carpetArea &&
                            propertyConfig.carpetArea.number}{" "}
                          {propertyConfig.carpetArea &&
                            propertyConfig.carpetArea.units}
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        component="body1"
                        variant="body1"
                        color="black"
                        fontWeight="500"
                        fontSize="13px"
                      >
                        Updated: {moment(property.updated).format(DATE_FORMAT)}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid xs={12} sm={12} md={7}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        component="body1"
                        variant="body1"
                        color="black"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        <FontAwesomeIcon size="1x" icon={faRupeeSign} />{" "}
                        {propertyConfig.cost !== 0 &&
                          formatNumber(
                            roundToTwoPlaces(propertyConfig.cost)
                          )}{" "}
                        &nbsp;&nbsp; (
                        <FontAwesomeIcon size="1x" icon={faDollarSign} />{" "}
                        {propertyConfig.cost !== 0 &&
                          formatNumber(
                            roundToTwoPlaces(
                              propertyConfig.cost * rupeeToDollarExchangeValue
                            )
                          )}
                        )
                      </Box>
                    </Grid>
                    {propertyConfig.landArea && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Box
                          component="body1"
                          variant="body1"
                          color="black"
                          fontWeight="500"
                          fontSize="16px"
                        >
                          Land Area:&nbsp;{" "}
                          {propertyConfig.landArea &&
                            propertyConfig.landArea.number}{" "}
                          {propertyConfig.landArea &&
                            propertyConfig.landArea.units}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container>
                    {propertyConfig.landArea && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Box
                          component="body1"
                          variant="body1"
                          color="black"
                          fontWeight="500"
                          fontSize="16px"
                        >
                          <FontAwesomeIcon size="1x" icon={faRupeeSign} />{" "}
                          {propertyConfig.cost !== 0 &&
                            propertyConfig.landArea &&
                            formatNumber(
                              roundToTwoPlaces(
                                propertyConfig.cost /
                                  propertyConfig.landArea.number
                              )
                            )}
                          {"/"}
                          {propertyConfig.landArea &&
                            propertyConfig.landArea.units}
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        component="body1"
                        variant="body1"
                        color="black"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        Updated:&nbsp;{" "}
                        {moment(property.updated).format(DATE_FORMAT)}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}

          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Box
              component="div"
              display="flex"
              justifyContent="end"
              flexDirection="column"
            >
              <Box display="flex" justifyContent="end" pb={2}>
                <Box component="span" p={2}>
                  {showFavorited === true ? (
                    <FavoriteIcon
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => makeFavourite()}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => makeFavourite()}
                    />
                  )}
                </Box>
                <Tooltip title="Share Property" style={{ padding: "0 15px" }}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    // onMouseLeave={() => setShareIcons(false)}

                    onClick={() => setOpened(true)}
                  >
                    <Share />
                  </IconButton>
                </Tooltip>
                <Dialog
                  onClose={() => setOpened(false)}
                  aria-labelledby="customized-dialog-title"
                  open={opened}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => setOpened(false)}
                  >
                    Share property
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box gutterBottom display="flex" gridGap={4}>
                      <ShareProperty property={property} />
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
              {property &&
              property.createdUserId &&
              authUser &&
              property.createdUserId._id === authUser.unique_id &&
              property.status != STATUS_APPROVED ? (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="btn-margin"
                >
                  <Button
                    marginTop="20px"
                    color="primary"
                    className="red_bg"
                    variant="contained"
                    onClick={() =>
                      props.history.push(`/sell-property/${property._id}`)
                    }
                  >
                    <EditIcon color="white" /> &nbsp; Edit
                  </Button>
                </Box>
              ) : authUser && property.isContacted && property.createdUserId ? (
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <Box
                    component="p"
                    variant="p"
                    color="black"
                    fontWeight="700"
                    fontSize="13px"
                    margin="0 0 1rem 0"
                  >
                    You contacted the seller
                  </Box>
                </Grid>
              ) : property &&
                authUser &&
                property.createdUserId &&
                property.createdUserId._id !== authUser.unique_id ? (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="btn-margin"
                >
                  <Button
                    marginTop="20px"
                    color="primary"
                    className="red_bg txt_white"
                    variant="contained"
                    onClick={() => handleContactSeller(property._id)}
                  >
                    Contact Seller
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.navmain} component="section" mb={1}>
          <ul class="prop-menu">
            <li>
              <a href="#"></a>
            </li>
            <li>
              <a href="#property">Property Configurations</a>
            </li>
            <li>
              <a href="#amenities">Amenities</a>
            </li>
            <li>
              <a href="#locality">Locality</a>
            </li>
            {((imageDocuments && imageDocuments.length > 0) ||
              (nonImageDocuments && nonImageDocuments.length > 0)) && (
              <li>
                <a href="#documents">Documents</a>
              </li>
            )}
            {(isAdmin ||
              (authUser &&
                property &&
                property.createdUserId &&
                property.createdUserId._id === authUser.unique_id)) && (
              <li>
                <a href="#potential">Buyer Leads</a>
              </li>
            )}
            <li>
              <a href="#reviews">Reviews Section</a>
            </li>
          </ul>
        </Box>
        <ReactImageGallery
          items={images}
          showBullets={true}
          defaultImage={property.defaultImageUrl}
          showThumbnails={false}
          lazyLoad={true}
          showPlayButton={false}
          showFullscreenButton={false}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <nav className="App-navigation">
              <Box>
                <div class="inter-links">
                  <span id=""></span>
                </div>
                <Box
                  variant="h3"
                  component="h3"
                  my={2}
                  className="prop_head main-title"
                >
                  {property.propertyName}
                </Box>
                {property && property.reraId && (
                  <Fragment>
                    <Box variant="h6" component="div" fontSize="24px" mt={4}>
                      RERA ID
                    </Box>

                    <Typography
                      component="p"
                      color="#565656"
                      fontSize="18px"
                      borderBottom=" 2px solid #f8f8f8"
                      style={{ textAlign: "justify" }}
                    >
                      {property.reraId}
                    </Typography>
                  </Fragment>
                )}
                <Box variant="h6" component="div" fontSize="24px" mt={4}>
                  Description
                </Box>

                <Typography
                  component="p"
                  color="#565656"
                  fontSize="18px"
                  borderBottom=" 2px solid #f8f8f8"
                  style={{ textAlign: "justify" }}
                >
                  {ReactHtmlParser(property.description)}
                </Typography>
              </Box>
              <Box borderBottom="2px solid #f8f8f8" mb={2} pb={2}>
                <div class="inter-links">
                  <span id="property"></span>
                </div>
                <Box variant="h6" my={2} mt={4} component="div" fontSize="24px">
                  {property.propertyConfiguration &&
                    property.propertyConfiguration.length > 0 &&
                    "Property Configurations"}
                </Box>
                {property.propertyConfiguration &&
                  property.propertyConfiguration.length > 0 &&
                  property.propertyConfiguration.map(
                    (propertyConfig, index) => (
                      <>
                        <Accordion
                          key={propertyConfig.slugName}
                          expanded={expanded === propertyConfig.slugName}
                          onChange={handleChange(propertyConfig.slugName)}
                        >
                          <AccordionSummary
                            aria-controls={`panel_${index}`}
                            id={`panel_${index}`}
                          >
                            <Typography>{propertyConfig.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid item xs={12}>
                                {propertyConfig.name && (
                                  <Grid item xs={12}>
                                    <Typography
                                      className="prop_head"
                                      style={{
                                        fontSize: "18px",
                                        paddingTop: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {propertyConfig.name + " :"}
                                    </Typography>
                                  </Grid>
                                )}
                                <Grid item xs={12}>
                                  {propertyConfig.defaultImageUrl &&
                                    propConfigImages && (
                                      <ReactImageGallery
                                        items={propConfigImages}
                                        showBullets={true}
                                        defaultImage={
                                          propertyConfig.defaultImageUrl
                                        }
                                        showThumbnails={false}
                                        lazyLoad={true}
                                        showPlayButton={false}
                                        showFullscreenButton={false}
                                      />
                                    )}
                                </Grid>
                                {propertyConfig.propertyType !==
                                "Residential Land" ? (
                                  <>
                                    <Grid container spacing={3}>
                                      {propertyConfig.propertyType && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Property Type
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.propertyType}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.furnishedStatus && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Furnished Status
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.furnishedStatus}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.facing !== "Facing" && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Facing
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.facing}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.availableFrom && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Available From
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {months[
                                              new Date(
                                                propertyConfig.availableFrom
                                              ).getMonth()
                                            ] +
                                              " " +
                                              new Date(
                                                propertyConfig.availableFrom
                                              ).getFullYear()}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                      {propertyConfig.numberOfBedrooms !==
                                        0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Bedrooms
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.numberOfBedrooms}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.numberOfBalconies !==
                                        0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Balconies
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.numberOfBalconies}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.numberOfBathrooms !==
                                        0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Bathrooms
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.numberOfBathrooms}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.numberOfOpenParkingSlots !==
                                        0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Open Parking
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {
                                              propertyConfig.numberOfOpenParkingSlots
                                            }
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                      {propertyConfig.numberOfCoveredParkingSlots !==
                                        0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Covered Parking
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {
                                              propertyConfig.numberOfCoveredParkingSlots
                                            }
                                          </Typography>
                                        </Grid>
                                      )}

                                      {propertyConfig.numberOfFloors !== 0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Number Of Floors
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.numberOfFloors}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.floorNumber !== 0 && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Floor Number
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.floorNumber}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.posessionStatus && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Possession Status
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.posessionStatus}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                      {
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Servent Room
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.serventRoom
                                              ? "YES"
                                              : "NO"}
                                          </Typography>
                                        </Grid>
                                      }
                                      {propertyConfig.builtupArea && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Built Up Area
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.builtupArea.number +
                                              " " +
                                              propertyConfig.builtupArea.units}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {propertyConfig.carpetArea && (
                                        <Grid item xs={6} sm={3}>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Carpet Area
                                          </Typography>
                                          <Typography
                                            className="donation-text"
                                            style={{
                                              fontSize: "16px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            {propertyConfig.carpetArea.number +
                                              " " +
                                              propertyConfig.carpetArea.units}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    {propertyConfig.landArea && (
                                      <Grid item xs={6} sm={3}>
                                        <Typography
                                          className="donation-text"
                                          style={{
                                            fontSize: "18px",
                                            paddingTop: "10px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Land Area
                                        </Typography>
                                        <Typography
                                          className="donation-text"
                                          style={{
                                            fontSize: "16px",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          {propertyConfig.landArea.number +
                                            " " +
                                            propertyConfig.landArea.units}
                                        </Typography>
                                      </Grid>
                                    )}

                                    {propertyConfig.availableFrom && (
                                      <Grid item xs={6} sm={3}>
                                        <Typography
                                          className="donation-text"
                                          style={{
                                            fontSize: "18px",
                                            paddingTop: "10px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Available From
                                        </Typography>
                                        <Typography
                                          className="donation-text"
                                          style={{
                                            fontSize: "16px",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          {months[
                                            new Date(
                                              propertyConfig.availableFrom
                                            ).getMonth()
                                          ] +
                                            " " +
                                            new Date(
                                              propertyConfig.availableFrom
                                            ).getFullYear()}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </>
                                )}
                                <Grid container spacing={3}>
                                  {
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Selling Price
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          size="1x"
                                          icon={faRupeeSign}
                                        />{" "}
                                        {propertyConfig.cost !== 0 &&
                                          formatNumber(
                                            roundToTwoPlaces(
                                              propertyConfig.cost
                                            )
                                          )}{" "}
                                        &nbsp;&nbsp; (
                                        <FontAwesomeIcon
                                          size="1x"
                                          icon={faDollarSign}
                                        />{" "}
                                        {propertyConfig.cost !== 0 &&
                                          formatNumber(
                                            roundToTwoPlaces(
                                              propertyConfig.cost *
                                                rupeeToDollarExchangeValue
                                            )
                                          )}
                                        )
                                      </Typography>
                                    </Grid>
                                  }
                                  {
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Cost per{" "}
                                        {propertyConfig.propertyType !==
                                        "Residential Land"
                                          ? propertyConfig.builtupArea &&
                                            propertyConfig.builtupArea.units
                                          : propertyConfig.landArea &&
                                            propertyConfig.landArea.units}
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          size="1x"
                                          icon={faRupeeSign}
                                        />
                                        {propertyConfig.propertyType !==
                                        "Residential Land"
                                          ? propertyConfig.cost !== 0 &&
                                            propertyConfig.builtupArea &&
                                            formatNumber(
                                              roundToTwoPlaces(
                                                propertyConfig.cost /
                                                  propertyConfig.builtupArea
                                                    .number
                                              )
                                            )
                                          : propertyConfig.cost !== 0 &&
                                            propertyConfig.landArea &&
                                            formatNumber(
                                              roundToTwoPlaces(
                                                propertyConfig.cost /
                                                  propertyConfig.landArea.number
                                              )
                                            )}
                                        {"/"}
                                        {propertyConfig.propertyType !==
                                        "Residential Land"
                                          ? propertyConfig.builtupArea &&
                                            propertyConfig.builtupArea.units
                                          : propertyConfig.landArea &&
                                            propertyConfig.landArea.units}
                                      </Typography>
                                    </Grid>
                                  }
                                </Grid>
                                <Grid container spacing={3}>
                                  {propertyConfig.registrationFees !== 0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Registration Fees
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {propertyConfig.registrationFees}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {propertyConfig.legalDocumentationFees !==
                                    0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Legal Documentation Fee
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {propertyConfig.legalDocumentationFees}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {propertyConfig.processingFees !== 0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Processing Fees
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {propertyConfig.processingFees}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid container spacing={3}>
                                  {propertyConfig.stampDutyCharges !== 0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        StampDuty Charges
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {propertyConfig.stampDutyCharges}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {propertyConfig.gstCharges !== 0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        GST Charges
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {propertyConfig.gstCharges}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {propertyConfig.corpusFund !== 0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Corpus Fund
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {propertyConfig.corpusFund}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {propertyConfig.monthlyMaintenanceCharges !==
                                    0 && (
                                    <Grid item xs={6} sm={3}>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Monthly Maintenance Charges
                                      </Typography>
                                      <Typography
                                        className="donation-text"
                                        style={{
                                          fontSize: "16px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        {
                                          propertyConfig.monthlyMaintenanceCharges
                                        }
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                                {propertyConfig.notes && (
                                  <Grid
                                    item
                                    style={{
                                      padding: "10px 0",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <Typography
                                      className="donation-text"
                                      style={{
                                        fontSize: "18px",

                                        fontWeight: "bold",
                                      }}
                                    >
                                      Notes
                                    </Typography>
                                    <Typography
                                      className="donation-text"
                                      style={{
                                        fontSize: "16px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      {propertyConfig.notes}
                                    </Typography>
                                  </Grid>
                                )}
                                {propertyConfig.description && (
                                  <Grid item>
                                    <Typography
                                      className="donation-text"
                                      style={{
                                        fontSize: "18px",
                                        paddingTop: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Description
                                    </Typography>
                                    <Typography
                                      className="donation-text"
                                      style={{
                                        fontSize: "16px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      {ReactHtmlParser(
                                        propertyConfig.description
                                      )}
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>

                              <Grid item xs={12}>
                                {((propConfigImageDocuments &&
                                  propConfigImageDocuments.length > 0) ||
                                  (nonPropConfigImageDocuments &&
                                    nonPropConfigImageDocuments.length >
                                      0)) && (
                                  <Box>
                                    <div class="inter-links">
                                      <span id="documents"></span>
                                    </div>
                                    <Box variant="h3" component="h3" mb={2}>
                                      Documents
                                    </Box>
                                    {propConfigImageDocuments &&
                                      (propConfigImageDocuments.length > 0 ||
                                        nonPropConfigImageDocuments.length >
                                          0) && (
                                        <Fragment>
                                          <Typography className="donation-header">
                                            Supporting Documents:
                                          </Typography>
                                          {propConfigImageDocuments.map(
                                            (document, index) => {
                                              return (
                                                <Fragment key={index}>
                                                  <figure
                                                    style={{
                                                      display: "grid",
                                                      placeContent: "center",
                                                      background: " #fafafa",
                                                      justifyContent: "start",
                                                    }}
                                                  >
                                                    <a
                                                      target="_blank"
                                                      href={
                                                        document && document.url
                                                      }
                                                    >
                                                      <img
                                                        style={{
                                                          maxHeight: "100px",
                                                        }}
                                                        alt={
                                                          document &&
                                                          document.userGivenName
                                                        }
                                                        src={
                                                          document &&
                                                          document.url
                                                        }
                                                      ></img>
                                                    </a>
                                                  </figure>
                                                </Fragment>
                                              );
                                            }
                                          )}
                                          {nonPropConfigImageDocuments.length >
                                            0 && (
                                            <Grid container>
                                              <Table celled striped>
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>
                                                      Document Name
                                                    </TableCell>
                                                    <TableCell>Note</TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {nonPropConfigImageDocuments.map(
                                                    (document, index) => {
                                                      return (
                                                        <Fragment key={index}>
                                                          <DocumentRow
                                                            key={index}
                                                            document={document}
                                                            canEdit={false}
                                                          />
                                                        </Fragment>
                                                      );
                                                    }
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Grid>
                                          )}
                                        </Fragment>
                                      )}
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )
                  )}
              </Box>
              <Box borderBottom="2px solid #f8f8f8" mb={2} pb={2}>
                <div class="inter-links">
                  <span id="amenities"></span>
                </div>
                <Box
                  variant="h6"
                  component="div"
                  cursor="pointer"
                  fontSize="24px"
                >
                  Amenities
                </Box>
                <Grid container>
                  {property.amenities &&
                    property.amenities.map((amenity) => (
                      <Grid item xs={12} sm={3}>
                        <Box
                          variant="p"
                          component="span"
                          color="rgb(126, 126, 126)"
                          fontSize="15px"
                          fontWeight="400"
                        >
                          {amenity}
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
              <Box>
                <div class="inter-links">
                  <span id="locality"></span>
                </div>
                <Box
                  variant="h6"
                  component="div"
                  cursor="pointer"
                  fontSize="24px"
                >
                  Locality
                </Box>
                <Typography variant="subtitle1" color="textSecondary">
                  {property &&
                    property.location &&
                    property.location.locality &&
                    `${property.location.locality}`}
                </Typography>

                <Typography variant="h6" component="div">
                  Insights
                </Typography>
                <Box>
                  <Grid container>
                    {property.type !== "Residential Land" && (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Box
                            component="body1"
                            variant="body1"
                            color="#7E7E7E"
                            fontSize="15px"
                            fontWeight="400"
                          >
                            Is it newly constructed
                          </Box>
                          <Box
                            component="p"
                            variant="p"
                            fontWeight="700"
                            fontSize="15px"
                            margin="0"
                          >
                            {property.newlyConstructed}
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
                <Box
                  component="div"
                  borderBottom="2px solid #f8f8f8"
                  mt={4}
                  mb={2}
                  pb={2}
                >
                  <Box
                    variant="h3"
                    component="h3"
                    cursor="pointer"
                    fontSize="24px"
                    fontWaite="700"
                  >
                    Neighbourhood
                  </Box>

                  <Box component="div">
                    {property.location && (
                      <GoogleMap
                        location={getLocalityInStringFormat(property.location)}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              {((imageDocuments && imageDocuments.length > 0) ||
                (nonImageDocuments && nonImageDocuments.length > 0)) && (
                <Box>
                  <div class="inter-links">
                    <span id="documents"></span>
                  </div>
                  <Box variant="h3" component="h3" mb={2}>
                    Documents
                  </Box>
                  {imageDocuments &&
                    (imageDocuments.length > 0 ||
                      nonImageDocuments.length > 0) && (
                      <Fragment>
                        <Typography className="donation-header">
                          Supporting Documents:
                        </Typography>
                        {imageDocuments.map((document, index) => {
                          return (
                            <Fragment key={index}>
                              <figure
                                style={{
                                  display: "grid",
                                  placeContent: "center",
                                  background: " #fafafa",
                                  justifyContent: "start",
                                }}
                              >
                                <a
                                  target="_blank"
                                  href={document && document.url}
                                >
                                  <img
                                    style={{
                                      maxHeight: "100px",
                                    }}
                                    alt={document && document.userGivenName}
                                    src={document && document.url}
                                  ></img>
                                </a>
                              </figure>
                            </Fragment>
                          );
                        })}
                        {nonImageDocuments.length > 0 && (
                          <Grid container>
                            <Table celled striped>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Document Name</TableCell>
                                  <TableCell>Note</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {nonImageDocuments.map((document, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <DocumentRow
                                        key={index}
                                        document={document}
                                        canEdit={false}
                                      />
                                    </Fragment>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                </Box>
              )}

              {(isAdmin ||
                (authUser &&
                  property &&
                  property.createdUserId &&
                  property.createdUserId._id === authUser.unique_id)) && (
                <Box>
                  <div class="inter-links">
                    <span id="potential"></span>
                  </div>
                  <Box
                    variant="h3"
                    component="h3"
                    mb={2}
                    style={{ marginTop: "10px" }}
                  >
                    Buyer Leads
                  </Box>
                  {buyers && buyers.length > 0 ? (
                    <Grid container style={{ overflowX: "auto" }}>
                      <Table celled striped>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Address</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {buyers.map((buyer, index) => {
                            return (
                              <TableRow key={buyer._id}>
                                <TableCell>
                                  {buyer.name &&
                                    buyer.name.first_name + " " + buyer.name &&
                                    buyer.name.last_name}
                                </TableCell>
                                <TableCell>{buyer.email}</TableCell>
                                <TableCell>{buyer.phone}</TableCell>
                                <TableCell>
                                  {getObjectValuesInStringFormat(buyer.address)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                  ) : (
                    <Typography>No Buyers Contacted</Typography>
                  )}
                </Box>
              )}
              <Box
                component="div"
                borderBottom="2px solid #f8f8f8"
                mt={4}
                mb={2}
                pb={2}
              >
                <div class="inter-links">
                  <span id="reviews"></span>
                </div>
                <Box variant="h3" component="h3" mb={2}>
                  Review about {property.propertyName}
                </Box>

                {property && property._id && (
                  <Comments
                    propertyData={property}
                    isOpen={isCommentsDialogOpen}
                    onClick={onClick}
                    onKeyPress={onClick}
                    onClose={() => setIsCommentsDialogOpen(false)}
                  />
                )}
              </Box>
              {/* <Box id="box-8">
                <Box variant="h3" component="h3" mb={2}>
                  Frequently Asked Questions
                </Box>
                <Faqs />
              </Box> */}
            </nav>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box
              component="div"
              width="100%"
              mt={3}
              className={classes.rightcard}
            >
              <Card style={{ margin: "10px 0" }}>
                <CardContent>
                  <Box
                    variant="h5"
                    component="h2"
                    gutterBottom
                    fontWaite="bold"
                    fontSize="18px"
                    className="prop_head"
                  >
                    {property.propertyName}
                  </Box>
                  <Typography color="textSecondary">
                    {getObjectValuesInStringFormat(property.location)}
                  </Typography>
                  <Box color="textSecondary" mt={1}>
                    <strong> Posted on : </strong>
                    {property && moment(property.createdAt).format(DATE_FORMAT)}
                  </Box>
                  <Box color="textSecondary" mt={1}>
                    {property && property.createdUserId && (
                      <Grid item xs>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{ fontWeight: "bold" }}
                        >
                          {" "}
                          Posted By :{" "}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleUser(property);
                          }}
                        >
                          {property &&
                          property.builderId &&
                          property.builderId.builderName
                            ? property.builderId.builderName
                            : property.createdUserId.name &&
                              property.createdUserId.name.first_name +
                                " " +
                                property.createdUserId.name.last_name}
                        </Typography>
                      </Grid>
                    )}
                  </Box>
                </CardContent>
              </Card>
              {property.createdUserId &&
              authUser &&
              property.createdUserId._id === authUser.unique_id ? (
                <Box></Box>
              ) : authUser && property.isContacted && property.createdUserId ? (
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      Contacted Seller
                    </Typography>
                    <Typography color="textSecondary">
                      {getObjectValuesInStringFormat(
                        property.createdUserId && property.createdUserId.address
                      )}
                    </Typography>
                    {property.propertyConfiguration &&
                      property.propertyConfiguration.length === 1 &&
                      property.propertyConfiguration.map(
                        (propertyConfig, index) => (
                          <>
                            <Box
                              variant="h5"
                              component="h4"
                              gutterBottom
                              pt={2}
                            >
                              {propertyConfig.cost !== 0 && (
                                <>
                                  <FontAwesomeIcon
                                    size="1x"
                                    icon={faRupeeSign}
                                  />
                                  {formatNumber(
                                    roundToTwoPlaces(propertyConfig.cost)
                                  )}
                                </>
                              )}
                            </Box>
                            <Typography color="textSecondary">
                              {propertyConfig.cost !== 0 && (
                                <FontAwesomeIcon size="1x" icon={faRupeeSign} />
                              )}
                              {propertyConfig.propertyType !==
                              "Residential Land"
                                ? propertyConfig.cost !== 0 &&
                                  propertyConfig.builtupArea &&
                                  formatNumber(
                                    roundToTwoPlaces(
                                      propertyConfig.cost /
                                        propertyConfig.builtupArea.number
                                    )
                                  )
                                : propertyConfig.cost !== 0 &&
                                  propertyConfig.landArea &&
                                  formatNumber(
                                    roundToTwoPlaces(
                                      propertyConfig.cost /
                                        propertyConfig.landArea.number
                                    )
                                  )}
                              {"/"}
                              {propertyConfig.propertyType !==
                              "Residential Land"
                                ? propertyConfig.builtupArea &&
                                  propertyConfig.builtupArea.units
                                : propertyConfig.landArea &&
                                  propertyConfig.landArea.units}
                            </Typography>

                            {propertyConfig.propertyType !==
                            "Residential Land" ? (
                              <Grid container>
                                {propertyConfig.builtupArea && (
                                  <Typography color="textSecondary">
                                    Built Up Area{" "}
                                    {propertyConfig.builtupArea.number}{" "}
                                    {propertyConfig.builtupArea &&
                                      propertyConfig.builtupArea.units}
                                  </Typography>
                                )}
                                {propertyConfig.carpetArea && (
                                  <Typography color="textSecondary">
                                    Carpet Area
                                    {propertyConfig.carpetArea &&
                                      propertyConfig.carpetArea.number}{" "}
                                    {propertyConfig.carpetArea &&
                                      propertyConfig.carpetArea.units}
                                  </Typography>
                                )}
                              </Grid>
                            ) : (
                              <Grid container>
                                {propertyConfig.landArea && (
                                  <Typography color="textSecondary">
                                    Land Area
                                    {property.landArea &&
                                      property.landArea.number}{" "}
                                    {property.landArea &&
                                      property.landArea.units}
                                  </Typography>
                                )}
                              </Grid>
                            )}
                          </>
                        )
                      )}
                    <Typography color="textSecondary">
                      {property.createdUserId && property.createdUserId.email}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {property.createdUserId && property.createdUserId.phone}
                    </Typography>
                  </CardContent>
                  <Box
                    style={{
                      background: "#4baf4f",
                      //background: "#3D69B2",
                      borderRadius: "0 0 4 4",
                      width: "100%",
                      color: "white",
                      lineHeight: "40px",
                      textAlign: "center",
                    }}
                  >
                    Contacted Seller
                  </Box>
                </Card>
              ) : (
                <Button
                  className="red_bg txt_white"
                  style={{
                    borderRadius: 4,
                    width: "100%",
                    marginTop: "20px",
                  }}
                  onClick={() => handleContactSeller(property._id)}
                >
                  Contact Seller
                </Button>
              )}
            </Box>

            <ContactDeveloper
              builder={property.createdUserId}
              propertyName={property.propertyName}
              open={open}
              id={property._id}
              makeIsContacted={makeIsContacted}
              handleClose={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default withRouter(DetailedPage);
