import React, { useContext, useState } from "react";
import { appContext } from "../../App";
//import  useInfiniteScroll  from "react-infinite-scroll-hook";
import { getAllProperties } from "../../utils/api";
import toastr from "toastr";
import { Typography } from "@material-ui/core";
import BriefPropertyCard from "../buy/BriefPropertyCard";
import PropertyListingCard from "./PropertyListingCard";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import useInfiniteScroll from "react-infinite-scroll-hook";

function ListProperties(props) {
  const { authUser, rupee } = useContext(appContext);
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const status = "0,1,3";

  const loadProperties = () => {
    setIsLoading(true);
    getAllProperties(10, offset, authUser.unique_id)
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const newOffset = offset + response.data.length;
          if (newOffset < response.totalCountOfRecords) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
          setOffset(newOffset);
          setProperties((prev) => [...prev, ...response.data]);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setHasNextPage(false);
        toastr.error(
          "Problem in fetching properties requests:" + error.message
        );
      });
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadProperties,
    // scrollContainer: "window",
  });

  const removeFromList = (id) => {
    setProperties(properties.filter((property) => property._id !== id));
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          <Paper>
            <Box /*ref={infiniteRef}*/ p={2}>
              <Typography variant="h5" component="h5">
                Listed Properties
              </Typography>
              {properties &&
                properties.length > 0 &&
                properties.map((property) => {
                  return (
                    <BriefPropertyCard
                      property={property}
                      key={property._id}
                      rupee={rupee}
                      removeFromList={() => removeFromList(property._id)}
                    />
                  );
                })}
              {hasNextPage && (
                <p ref={infiniteRef} style={{ textAlign: "center" }}>
                  Loading...
                </p>
              )}
              {!isLoading && properties && properties.length === 0 && (
                <p style={{ textAlign: "center" }}>No Properties are listed</p>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <PropertyListingCard />
        </Grid>
      </Grid>
    </Container>
  );
}
export default ListProperties;
