import validator from "validator";

const validateSeller = (values) => {
  let errors = {};
  if (!values.buyerEmail) {
    errors.buyerEmail = "Email Address is required";
  }
  if (!values.buyerName) {
    errors.buyerName = "Username is required";
  }
  return errors;
};

export default validateSeller;
