import React, { useState, useEffect, useContext, Fragment } from "react";
import toastr from "toastr";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  Link,
  Typography,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
//import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import { DATE_TIME_FORMAT, HOME_PAGE } from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import BuilderForm from "../Builder/BuilderForm";

import {
  getAllBuilders,
  updateBuilderStatus,
  deleteBuilder,
} from "../../utils/api";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "defaultImageUrl",
    numeric: false,
    disablePadding: true,
    label: "Builder Logo",
  },
  {
    id: "BuilderName",
    numeric: false,
    disablePadding: true,
    label: "Builder Name",
  },
  {
    id: "websiteUrl",
    numeric: true,
    disablePadding: false,
    label: "Web Site",
  },
  {
    id: "builderType",
    numeric: false,
    disablePadding: false,
    label: "Builder Type",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    "& td": {
      padding: "4px 16px",
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
    "& td:nth-child(6)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
    "& td:nth-child(7)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
  },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const AdminBuilder = (props) => {
  const { authUser, isAdmin, isVolunteer, isSuperAdmin, setBuilderList, builderList } =
    useContext(appContext);
  const [builder, setBuilder] = useState([]);
  const [openBuilderModal, setOpenBuilderModal] = useState(false);
  const [builderDetails, setBuilderDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleBuilder = (rowData) => {
    setOpenBuilderModal(true);
    setEdit(true);
    setBuilderDetails(rowData);
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const onSavingBuilder = (builderId) => {
    setOpenBuilderModal(false);
   // props.history.push(`/builder/${builderId}`);
  };

  const getStatusLabel = (status) => {
    if (status === -1) {
      return "Rejected";
    } else if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Approved";
    }
  };

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin) {
        getAllBuilders()
          .then((response) => {
            if (response && response.length > 0) {
              setBuilder(response);
            }
          })
          .catch((error) => {
            toastr.error("Problem in fetching. " + error.message);
          });
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  const approve = (id) => {
    updateStatus(id, 1);
  };

  const reject = (id) => {
    updateStatus(id, -1);
  };

  const updateStatus = (builderId, status) => {
    if (authUser) {
      const obj = {};
      obj.buildersId = builderId;
      obj.status = status;
      updateBuilderStatus(builderId, obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            setBuilderList(
              builderList.map((builder) => {
                if (builder.builderId._id === builderId) {
                  return { ...builder, builderId: { ...builder.builderId, status: status } };
                } else {
                  return builder;
                }
              })
            );
            setBuilder(
                builder.map((builder) => {
                if (builder._id === builderId) {
                  return { ...builder, ...response.data.data.data };
                } else {
                  return builder;
                }
              })
            );
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
        });
    }
  };
  const deleteBuild = (row) => {
    if (window.confirm("Are you sure you want to delete the builder?")) {
      deleteBuilder(row._id, row.user_info._id)
        .then((response) => {
          toastr.success(response.data.message);
          setBuilderList(builderList.filter((builder) => builder.builderId._id !== row._id));
          setBuilder(builder.filter((builder) => builder._id !== row._id));
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleBuilderDetails = (rowData) => {
    if (rowData) {
      props.history.push(`/builder/${rowData._id}`);
    }
  };

  return (
    <div className="myDonations-container">
      <div className="barnav">
        <Container MaxWidth="lg">
          <div style={{ display: "flex" }}>
            {authUser && (
              <Button onClick={handleCloseSideMenu}>
                {!closeSideMenu && <MenuIcon />}
                {closeSideMenu && <ArrowBackIcon />}
              </Button>
            )}
            <h4>Manage Builders</h4>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="menu-container">
          {/* {closeSideMenu && (
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          )} */}

          <div style={{ position: "sticky", top: "0", width: "100%" }}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(
                    builder && builder,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleBuilderDetails(row)}
                            >
                              {/* <Avatar alt="Remy Sharp" src={row.defaultImageUrl} /> */}
                              <img
                                src={
                                  row.defaultImageUrl
                                    ? row.defaultImageUrl
                                    : "/images/builderLogo.png"
                                }
                                style={{
                                  borderRadius: "100px",
                                  width: "40px",
                                  height: "40px",
                                }}
                                className="profile-pic"
                              />
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span
                              style={{ color: "#ec1c24", cursor: "pointer" }}
                              onClick={() => handleBuilderDetails(row)}
                            >
                              {row.builderName}
                            </span>
                          </TableCell>
                          <TableCell align="left">{row.websiteUrl}</TableCell>
                          <TableCell align="left">{row.builderType}</TableCell>
                          <TableCell align="left">
                            {moment(row.createdAt).format(DATE_TIME_FORMAT)}
                          </TableCell>
                          <TableCell align="left">
                            {getStatusLabel(row.status)}
                            <div>
                              <Fragment>
                                <Tooltip title="Approve the Builder">
                                  <Link
                                    onClick={() => approve(row._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <IconButton className="del-btn">
                                      <CheckCircleIcon
                                        style={{ color: "green" }}
                                      />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                                {" / "}
                                <Tooltip title="Reject the Builder">
                                  <Link
                                    onClick={() => reject(row._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <IconButton className="del-btn">
                                      <CancelIcon style={{ color: "red" }} />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              </Fragment>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div>
                              <Tooltip title="Edit">
                                <IconButton
                                  className="del-btn"
                                  onClick={() => handleBuilder(row)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>{" "}
                              <Tooltip title="Delete">
                                <IconButton
                                  className="del-btn"
                                  onClick={() => deleteBuild(row)}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {builder && builder.length === 0 && (
                <Typography className="no-req-text">
                  No Builders Found
                </Typography>
              )}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={builder.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{
                  position: "sticky",
                  background: "white",
                  bottom: "0",
                  borderTop: "1px solid #eaeaea",
                }}
              />
            </TableContainer>
            <BuilderForm
              openBuilderModal={openBuilderModal}
              onCloseModal={() => setOpenBuilderModal(false)}
              onSavingBuilder={onSavingBuilder}
              edit={edit}
              builderDetails={builderDetails}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AdminBuilder;