import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./style.css";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import { getAllProperties } from "../../utils/api";
import toastr from "toastr";
import { appContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { formatNumber, getObjectValuesInStringFormat } from "../../utils/utils";
import { withRouter } from "react-router";
import { roundToTwoPlaces } from "../../utils/utils";
const MenuItem = ({ property, rupee, ...props }) => {
  const convertRuppes2Doller = (amount) =>
    formatNumber((amount / rupee).toFixed(2));
  return (
    <div className="card">
      <Box
        component="button"
        onClick={() => props.history.push(`/property/${property._id}`)}
        borderRadius="4 4 0 0"
        border="none"
      >
        <div className="card_img">
          <img
            src={
              property && property.defaultImageUrl
                ? property.defaultImageUrl
                : "/images/sell_home.jpg"
            }
            alt="property"
          />
        </div>
      </Box>
      <Box className="card_text">
        <Box
          component="button"
          border="none"
          onClick={() => props.history.push(`/property/${property._id}`)}
        >
          <Box component="h1" className="propertyInfo" fontSize="22px">
            {property.propertyName}
          </Box>
        </Box>
        <Box
          component="p"
          color="black"
          fontSize="16px"
          className="propertyInfo"
          width="230px"
          style={{ overflowY: "auto" }}
        >
          {getObjectValuesInStringFormat(property.location)}
        </Box>
        {property.propertyConfiguration &&
        property.propertyConfiguration.length === 1 ? (
          property.propertyConfiguration.map((propertyConfig, index) =>
            propertyConfig.propertyType !== "Residential Land" &&
            propertyConfig.builtupArea ? (
              <Box
                component="p"
                color="#717171"
                fontSize="16px"
                className="propertyInfo"
              >
                {propertyConfig.numberOfBedrooms +
                  " Beds " +
                  propertyConfig.numberOfBathrooms +
                  " Baths " +
                  propertyConfig.builtupArea.number}{" "}
                {propertyConfig.builtupArea.units}
              </Box>
            ) : (
              propertyConfig.landArea && (
                <Box
                  component="p"
                  color="#717171"
                  fontSize="16px"
                  className="propertyInfo"
                >
                  {propertyConfig.landArea.number}{" "}
                  {propertyConfig.landArea.units}
                </Box>
              )
            )
          )
        ) : (
          <>
            {property.propertyConfiguration.length === 2 ? (
              <>
                <Typography variant="subtitle1" component="span">
                  {property.propertyConfiguration &&
                    property.propertyConfiguration.length === 2 &&
                    property.propertyConfiguration[0].name +
                      ", " +
                      property.propertyConfiguration[1].name}
                </Typography>
              </>
            ) : property.propertyConfiguration.length === 3 ? (
              <>
                {" "}
                <Typography variant="subtitle1" component="span">
                  {property.propertyConfiguration &&
                    property.propertyConfiguration.length === 3 &&
                    property.propertyConfiguration[0].name +
                      ", " +
                      property.propertyConfiguration[1].name +
                      ", " +
                      property.propertyConfiguration[2].name}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="subtitle1" component="span">
                  {property.propertyConfiguration &&
                    property.propertyConfiguration.length > 3 &&
                    property.propertyConfiguration[0].name +
                      ", " +
                      property.propertyConfiguration[1].name +
                      ", " +
                      property.propertyConfiguration[2].name +
                      " and more…"}
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export const Menu = (props, list, selected, rupee) =>
  list.map((property) => {
    return (
      <MenuItem
        property={property}
        key={property._id}
        {...props}
        rupee={rupee}
      />
    );
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};
Arrow.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export const ArrowLeft = Arrow({ text: "<", className: "arrow-prev red_bg" });
export const ArrowRight = Arrow({ text: ">", className: "arrow-next red_bg" });

class PropertyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alignCenter: true,
      clickWhenDrag: false,
      dragging: true,
      hideArrows: true,
      hideSingleArrow: true,
      itemsCount: 0,
      selected: "item1",
      translate: 0,
      transition: 0.4,
      wheel: false,
      isLoading: false,
      list: [],
    };
    this.menu = null;
  }

  onUpdate = ({ translate }) => {
    this.setState({ translate });
    this.loadProperties();
  };

  onSelect = (key) => {
    this.setState({ selected: key });
  };

  componentDidUpdate(prevProps, prevState) {
    const { alignCenter } = prevState;
    const { alignCenter: alignCenterNew } = this.state;
    if (alignCenter !== alignCenterNew) {
      this.menu.setInitial();
    }
  }
  componentDidMount() {
    this.loadProperties();
  }

  loadProperties = () => {
    this.setState({ isLoading: true });
    getAllProperties(4, this.state.itemsCount, "", "1")
      .then((response) => {
        this.setState({ isLoading: false });
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          this.setState({ list: [...this.state.list, ...response.data] });
          this.setState({
            itemsCount: response.data.length + this.state.itemsCount,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toastr.error(
          "Problem in fetching properties requests:" + error.message
        );
      });
  };

  setItemsCount = (ev) => {
    const { list, itemsCount, selected, rupee } = this.state;
    const val = +ev.target.value;
    const itemsCountNew =
      !isNaN(val) && val <= list.length && val >= 0
        ? +ev.target.value
        : list.length;
    const itemsCountChanged = itemsCount !== itemsCountNew;

    if (itemsCountChanged) {
      this.menuItems = Menu(list.slice(0, itemsCountNew), selected, rupee);
      this.setState({
        itemsCount: itemsCountNew,
      });
    }
  };

  setSelected = (ev) => {
    const { value } = ev.target;
    this.setState({ selected: String(value) });
  };

  render() {
    const {
      alignCenter,
      clickWhenDrag,
      hideArrows,
      dragging,
      hideSingleArrow,
      itemsCount,
      selected,
      translate,
      transition,
      wheel,
    } = this.state;
    //const menu = this.menuItems;
    const menu = Menu(
      this.props,
      this.state.list.slice(0, this.state.list.length),
      this.state.selected,
      this.props.rupee
    );
    return (
      <Box className="PropertyCard" pt={4}>
        <Container>
          <h1>Latest Properties</h1>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <Box component="p" fontSize="18px" fontWeight="400">
                Most recently launched projects to explore.
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} className="hyper_txt">
              <Box
                component="a"
                href="#"
                display="block"
                textAlign="right"
                color="#ec1c24"
                fontWeight="bold"
                onClick={() => this.props.history.push("/buy")}
              >
                See All
              </Box>
            </Grid>
          </Grid>

          <Box className="gallery">
            <ScrollMenu
              ref={(el) => (this.menu = el)}
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              hideArrows={hideArrows}
              hideSingleArrow={hideSingleArrow}
              transition={+transition}
              onUpdate={this.onUpdate}
              //   onSelect={this.onSelect}
              selected={selected}
              translate={translate}
              alignCenter={alignCenter}
              dragging={dragging}
              clickWhenDrag={clickWhenDrag}
              wheel={wheel}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}

export default withRouter(PropertyCard);
