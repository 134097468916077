import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import { appContext } from "../../App";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import NewUserProfile from "../userProfile/newUserProfile";
import ListProperties from "../NewRequest/ListProperties";
import Container from "@material-ui/core/Container";
import { getKeyByValue } from "../../utils/utils";
import MyFavoriteProperties from "../buy/MyFavoriteProperties";
import ContactedProperties from "../PropertiesContacted/ContactedProperties";
import Builders from "../Builder/Builders";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // marginBottom: 20,
  },

  in_menu: {
    backgroundColor: "white",
    margin: "1px 0px 2px",
    position: "fixed",
    top: "92px",
    zIndex: "1",
    left: "0",
    right: "0",

    [theme.breakpoints.down("sm")]: {
      top: "83px",
    },
  },
}));

function MenuBar(props) {
  const classes = useStyles();
  const { authUser, builderList } = useContext(appContext);
  const menus = {
    profile: 0,
    properties: 1,
    favourites: 2,
    contacted: 3,
    //manageproperties: 4,
    builder: 4,
  };
  const [value, setValue] = useState(menus[props.match.params.id]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.history.push(`/menubar/${getKeyByValue(menus, newValue)}`);
  };

  useEffect(() => {
    setValue(menus[props.match.params.id]);
  }, [props.match.params.id]);
  return (
    <Box component="div" pt={8}>
      <Box className={classes.root}>
        <Box className={classes.in_menu}>
          <Container maxWidth="lg">
            <Box position="sticky" top="0">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Menu Bar Tabs"
                style={{ padding: "0"}}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#ec1c24',
                  }
                }}
              >
                <Tab
                  label="Profile"
                  {...a11yProps(0)}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                />
                <Tab
                  label="Properties"
                  {...a11yProps(1)}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                />
                <Tab
                  label="Favourites"
                  {...a11yProps(2)}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                />
                <Tab
                  label="Properties Contacted"
                  {...a11yProps(3)}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                />
                <Tab
                  label="Builders"
                  {...a11yProps(4)}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                />
              </Tabs>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="div">
        <TabPanel value={value} index={0}>
          <NewUserProfile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListProperties />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MyFavoriteProperties />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ContactedProperties />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Builders />
        </TabPanel>
      </Box>
    </Box>
  );
}
export default MenuBar;
