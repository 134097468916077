import React, { useRef, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import MailIcon from "@material-ui/icons/Mail";
import ShareThroughEmail from "../common/ShareThroughEmail";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Box from "@material-ui/core/Box";
const ShareOnSocialMedia = (props) => {
  const [openShareEmail, setOpenShareEmail] = useState(false);
  const textAreaRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [showElement, setShowElement] = React.useState(true);

  const shareThroughMail = () => {
    setOpenShareEmail(!openShareEmail);
  };
  const { url, quote, subject, content, shareURL, redirectPage } = props;
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const copyCodeToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setShowElement(true);
    setCopySuccess("URL Copied!");
    setTimeout(function () {
      setShowElement(false);
      setCopySuccess("");
    }, 2000);
  };
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.handleClose}
        className="share-text"
      >
      Share public profile 
      </DialogTitle>
      <DialogContent dividers>
        <Grid container className="share-pop">
          <Grid>
            <Grid item>
              <Tooltip title="Share on Facebook">
                <div>
                  <FacebookShareButton url={url} quote={quote}>
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                </div>
              </Tooltip>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="caption" display="block" gutterBottom>
                Facebook
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Grid item>
              <Tooltip title="Share on Twitter">
                <div>
                  <TwitterShareButton url={url} title={quote}>
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>
                </div>
              </Tooltip>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="caption" display="block" gutterBottom>
                Twitter
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid item>
              <Tooltip title="Share on LinkedIn">
                <div>
                  <LinkedinShareButton url={url} quote={quote}>
                    <LinkedinIcon size={40} round={true} />
                  </LinkedinShareButton>
                </div>
              </Tooltip>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="caption" display="block" gutterBottom>
                LinkedIn
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid item>
              <Tooltip title="Share on WhatsApp">
                <div>
                  <WhatsappShareButton url={url} title={quote}>
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                </div>
              </Tooltip>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="caption" display="block" gutterBottom>
                WhatsApp
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Grid item>
              <Tooltip title="Share by Email">
                <div>
                  <MailIcon
                    onClick={(e) => shareThroughMail(e)}
                    style={{
                      color: "#fff",
                      background: "#7777ff",
                      borderRadius: "50%",
                      padding: 5,
                      fontSize: 40,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </Tooltip>
              {openShareEmail &&
                openShareEmail === true &&
                subject &&
                redirectPage && (
                  <ShareThroughEmail
                    isOpen={openShareEmail}
                    subject={subject}
                    content={content}
                    onClose={(e) => setOpenShareEmail(false)}
                    redirectPage={redirectPage}
                  />
                )}
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="caption" display="block" gutterBottom>
                Email
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
          <Grid item xs={12} md={9} sm={9} className="copy-url">
            <input
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              readOnly
              ref={textAreaRef}
              value={shareURL}
            />
            <div className="url-copy">{copySuccess}</div>
          </Grid>
          <Grid item xs={12} md={3} sm={3} className="copy-url">
            {document.queryCommandSupported("copy") && (
              <Button className="red_bg"
                color="primary"
                style={{ float: "right",
              // background:"#3D69B2",
              color:"#fff" }}
                onClick={copyCodeToClipboard}
              >
                Copy Link
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box className="fund-share-link">
              <p><strong>Tip : </strong>Paste this public profile  link anywhere.</p>
              <Box>
                <InstagramIcon size={40} round={true} />
                <YouTubeIcon size={40} round={true} />
                <img src="../images/slack.png"style={{width:"20px", height:"20px"}}/>
                <img src="../images/snapchat.png"style={{width:"20px", height:"20px"}}/>
                <img src="../images/tumblr.png"style={{width:"20px", height:"20px"}}/>
                <img src="../images/more.png"style={{width:"20px", height:"20px"}}/>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShareOnSocialMedia;
