import React, { useState, useContext, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MenuIcon from "@material-ui/icons/Menu";
import Login from "../auth/login/Login";
import SignUp from "../auth/signup/Signup";
import ForgotPassword from "../auth/forgotPassword/ForgotPassword";
import { appContext } from "../../App";
import { IconButton } from "@material-ui/core";
import "./Topbar.scss";
import { withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: "0",
    zIndex: "6",
    borderBottom: "1px solid #E5E5E5",
  },


  MuiIconButtonRoot: {
    "& hover": {
      backgroundColor: "transparent",
    },
  },
  paper: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  menu: {
    "& nav": {
      "& ul": {
        listStyleType: "none",
        margin: "0",
        padding: "0",
        overflow: "hidden",
        display: "flex",
        justifyContent: "flex-end",
        "& li": {
          display: "inline-block",
        },

        "& a": {
          display: "block",
          color: "#141B2D",
          textAlign: "center",
          padding: "15px",
          textDecoration: "none",
          fontSize: "17px",
          cursor: "pointer",
          "&:hover": {
            background: "#1D4A94",
            color: "white",
            borderRadius: "50px",
          },
        },
      },
    },
  },
}));

function CenteredGrid(props) {
  const classes = useStyles();
  const {
    setOpenLogin,
    setOpenSignup,
    authUser,
    setAuthUser,
    isSuperAdmin,
    isAdmin,
    setIsAdmin,
    setIsSuperAdmin,
  } = useContext(appContext);
  const [active, setActive] = useState("");
  const [myProfile, setMyProfile] = useState(false);

  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      return;
    }

    prevOpen.current = open;
  }, [open]);

  const handleMyProfile = () => {
    setActive("profile");
    setMyProfile(true);
  };
  const handleMyProfileOptions = (option) => {
    setMyProfile(false);
    props.history.push(`/menubar/${option}`);
    setOpen(false);
  };
  const handleViewMyPublicProfile = (option) => {
    setMyProfile(false);
    props.history.push(`/user/${authUser.username}`);
    setOpen(false);
  };

  const handleManageBuilders = () => {
    setMyProfile(false);
    setActive("builders");
    props.history.push("/admin/builder");
    setOpen(false);
  };
  const handleBuy = () => {
    setActive("buy");
    setMyProfile(false);
    props.history.push(`/buy`);
    setOpen(false);
  };
  const handleSell = () => {
    setActive("sell");
    setMyProfile(false);
    props.history.push(`/property-success`);
    setOpen(false);
  };
  const handleFavorites = () => {
    setActive("Favourites");
    setMyProfile(false);
    props.history.push(`/menubar/favourites`);
    setOpen(false);
  };

  const logout = () => {
    setMyProfile(false);
    setAuthUser(null);
    setIsAdmin(null);
    setIsSuperAdmin(null);
    localStorage.removeItem("authUser");
    props.history.push(`/`);
    setOpen(false);
  };
  return (
    <Box className={classes.root}>
      <Box component="div" className={classes.paper}>
        <Container>
          <Grid container>
            <Grid item xs={6}>
              <Box component="div">
                <a href="/">
                  <img
                    src="/images/zenrth-logo.png"
                    alt="Logo"
                    className= " logore"
                  />
                </a>
              </Box>
            </Grid>
            <Grid item xs={6} style={{ alignSelf: "center" }}>
              <Hidden smDown>
                <Box
                  component="div"
                  className={classes.menu}
                  justifyContent="flex-end"
                  display="flex"
                >
                  <nav className="top_menu">
                    <ul>
                      <li>
                        <a onClick={handleBuy}>Buy</a>
                      </li>
                      {!authUser ? (
                        <li>
                          <a onClick={() => setOpenLogin(true)}>Sell</a>
                        </li>
                      ) : (
                        <li>
                          <a onClick={handleSell}>Sell</a>
                        </li>
                      )}
                      {!authUser ? (
                        <li>
                          <a onClick={() => setOpenLogin(true)}>Favourites</a>
                        </li>
                      ) : (
                        <li>
                          <a onClick={handleFavorites}>Favourites</a>
                        </li>
                      )}
                      {!authUser ? (
                        <>
                          <li>
                            <a onClick={() => setOpenLogin(true)}>Login</a>
                          </li>
                          <li>
                            <a onClick={() => setOpenSignup(true)}>SignUp</a>
                          </li>
                        </>
                      ) : (
                        <li>
                          <IconButton
                            style={{
                              border: "1px solid #A0A0A0",
                              borderRadius: "2px",
                              marginLeft: "10px",
                            }}
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                          >
                            <Box component="p" m={0} fontSize="16px">
                              <img
                                style={{
                                  width: "29px",
                                  border: "2px solid white",
                                  height: "30px",
                                  marginRight: "5px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  authUser.profile_image_url
                                    ? authUser.profile_image_url
                                    : "/images/default-profile-photo.png"
                                }
                                alt="User Profile"
                              />
                              Hello,{" "}
                              {authUser &&
                                authUser.name &&
                                authUser.name.first_name}
                            </Box>
                          </IconButton>
                          <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper style={{ marginTop: "9px" }}>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <Box
                                      component="MenuList"
                                      autoFocusItem={open}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMyProfileOptions("profile")
                                        }
                                      >
                                        Manage Profile
                                      </MenuItem>
                                      <Divider />
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMyProfileOptions("properties")
                                        }
                                      >
                                        Manage Properties
                                      </MenuItem>
                                      <Divider />
                                      <MenuItem
                                        onClick={(e) =>
                                          handleViewMyPublicProfile(
                                            "myPublicProfile"
                                          )
                                        }
                                      >
                                        View My Public Profile
                                      </MenuItem>
                                      <Divider />
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMyProfileOptions("favourites")
                                        }
                                      >
                                        Favourites
                                      </MenuItem>
                                      <Divider />
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMyProfileOptions("contacted")
                                        }
                                      >
                                        Properties Contacted
                                      </MenuItem>
                                      <Divider />
                                      {(isSuperAdmin || isAdmin) && (
                                        <>
                                          <MenuItem
                                            onClick={(e) =>
                                              handleMyProfileOptions(
                                                "manageProperties"
                                              )
                                            }
                                          >
                                            Admin Manage Properties
                                          </MenuItem>

                                          <MenuItem
                                            onClick={(e) =>
                                              handleManageBuilders()
                                            }
                                          >
                                            Admin Manage Builders
                                          </MenuItem>
                                          <Divider />
                                        </>
                                      )}
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMyProfileOptions("contactUs")
                                        }
                                      >
                                        Contact Us
                                      </MenuItem>
                                      <Divider />
                                      <MenuItem onClick={logout}>
                                        Sign Out
                                      </MenuItem>
                                    </Box>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </li>
                      )}
                    </ul>
                  </nav>
                  {myProfile && <div className="tab-box"></div>}
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box justifyContent="flex-end" display="flex" py="17px">
                  <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <MenuIcon />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem onClick={handleBuy}>Buy</MenuItem>
                              <MenuItem onClick={handleSell}>Sell</MenuItem>

                              {!authUser ? (
                                <MenuItem onClick={() => setOpenLogin(true)}>
                                  Log In
                                </MenuItem>
                              ) : (
                                <>
                                  <MenuItem
                                    onClick={(e) =>
                                      handleMyProfileOptions("profile")
                                    }
                                  >
                                    Manage Profile
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) =>
                                      handleMyProfileOptions("properties")
                                    }
                                  >
                                    Manage Properties
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      props.history.push(
                                        `/user/${authUser.username}`
                                      )
                                    }
                                  >
                                    View My Public Profile
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) =>
                                      handleMyProfileOptions("favourites")
                                    }
                                  >
                                    Favourites
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) =>
                                      handleMyProfileOptions("contacted")
                                    }
                                  >
                                    Properties Contacted
                                  </MenuItem>
                                  {(isSuperAdmin || isAdmin) && (
                                    <>
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMyProfileOptions(
                                            "manageproperties"
                                          )
                                        }
                                        style={{ borderTop: "1px solid #ccc" }}
                                      >
                                        Admin Manage Properties
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) => handleManageBuilders()}
                                      >
                                        Manage Builders
                                      </MenuItem>
                                    </>
                                  )}
                                  <MenuItem
                                    onClick={logout}
                                    style={{ borderTop: "1px solid #ccc" }}
                                  >
                                    Sign out
                                  </MenuItem>
                                </>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Login />
      <SignUp />
      <ForgotPassword />
    </Box>
  );
}
export default withRouter(CenteredGrid);
