import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollTop";
import ChangePassword from "./components/auth/changePassword/ChangePassword";
import OtpValidation from "./components/auth/OTPValidation/OtpValidation";
import Authorize from "./components/auth/login/Authorize";
import Topbar from "./components/Topbar/Topbar";
import Footer from "./components/footer/Footer";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Home2 from "./components/FBLayout/Home2";
import NewUserProfile from "./components/userProfile/newUserProfile";
import EditUserName from "./components/EditUsername";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Tooltip } from "@material-ui/core";
import MenuBar from "./components/menubar/MenuBar";
import SellProperty from "./components/NewRequest/SellProperty";
import Buy from "./components/buy/Buy";
import AdminManageProperties from "./components/Admin/AdminManageProperties";
import ContactUs from "./components/ContactUs/ContactUs";
import privacypolicy from "./components/Privacypolicy/privacypolicy";
import cookiepolicy from "./components/cookiepolicy/Cookiepolicy";
import InviteUser from "./components/InviteUser/InviteUser";
import InviteGeneralUser from "./components/InviteUser/InviteGeneralUser";
import VerifyBuilderEmail from "./components/InviteUser/verifyBuilderEmail";
import Aboutus from "./components/aboutus/Aboutus";
import ProtectedRoute from "./ProtectedRoute";
import DetailedPage from "./components/Property/DetailedPage";
import PropertySuccess from "./components/NewRequest/PropertySuccess";
import MyFavoriteProperties from "./components/buy/MyFavoriteProperties";
import TermsOfUse from "./components/TermsofUse/termsofuse";
import AuthVerify from "./components/auth/login/AuthVerify";
import PublicProfile from "./components/PublicProfile/PublicProfile";
import BuilderForm from "./components/Builder/BuilderForm";
import BuildersDetails from "./components/BuildersDetails/BuildersDetails";
import AdminBuilder from "./components/Admin/AdminBuilders";
import PublicProfileLandingPage from "./components/PublicProfileLandingPage";

const Routes = () => {
  const [showArrow, setShowArrow] = React.useState(0);
  const path = window.location.pathname;

  useScrollPosition(({ currPos }) => {
    setShowArrow(currPos.y);
  });
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Topbar />
        <div>
          <Switch>
            <Route exact path="/" component={Home2} />
            <Route path="/search/:keyword" exact component={Home2} />
            <Route path="/search/" exact component={Home2} />
            <Route exact path="/invitation" component={InviteUser} />
            <Route
              exact
              path="/inviteGeneralUser"
              component={InviteGeneralUser}
            />
            <Route
              exact
              path="/verifyBuilderEmail/:builderId/:userId"
              component={VerifyBuilderEmail}
            />
            <Route exact path="/authorize" component={Authorize} />

            <Route exact path="/aboutus" component={Aboutus} />
            <Route exact path="/privacypolicy" component={privacypolicy} />
            <Route exact path="/termsOfUse" component={TermsOfUse} />
            <Route exact path="/Cookiepolicy" component={cookiepolicy} />
            <Route exact path="/menubar/contactUs" component={ContactUs} />

            <Route
              exact
              path="/newUserProfile/:id"
              component={NewUserProfile}
            />
            <ProtectedRoute path="/buy/:id" component={Buy} />
            <Route path="/buy" component={Buy} />
            <ProtectedRoute
              exact
              path="/menubar/manageProperties"
              component={AdminManageProperties}
            />
            <Route exact path="/user/:id" component={PublicProfile} />
            <ProtectedRoute exact path="/menubar/:id" component={MenuBar} />
            <ProtectedRoute
              exact
              path="/sell-property/:id"
              component={SellProperty}
            />
            <ProtectedRoute path="/sell-property" component={SellProperty} />
            <ProtectedRoute
              path="/my-favorites"
              component={MyFavoriteProperties}
            />
            <ProtectedRoute exact path="/add-builder" component={BuilderForm} />
            <Route exact path="/builder/:id" component={BuildersDetails} />
            <ProtectedRoute
              exact
              path="/property-success"
              component={PropertySuccess}
            />
            <ProtectedRoute
              exact
              path="/admin/builder"
              component={AdminBuilder}
            />

            <Route exact path="/property/:id" component={DetailedPage} />
            <ProtectedRoute
              exact
              path="/myfavourites"
              component={MyFavoriteProperties}
            />
            <Route exact path="/otp-validation" component={OtpValidation} />
            <Route exact path="/otp-validation/:id" component={OtpValidation} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/editUsername" component={EditUserName} />
            <Route exact path="/:id" component={PublicProfileLandingPage} />
          </Switch>
          <AuthVerify />
        </div>
        {showArrow < -500 && (
          <div
            style={{
              position: "fixed",
              bottom: 20,
              right: 30,
              background: " rgb(205, 38, 39)",
              padding: "6px 8px",
              borderRadius: "50%",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={scrollToTop}
          >
            <Tooltip title="Scroll To Top">
              <ArrowUpwardIcon />
            </Tooltip>
          </div>
        )}
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
