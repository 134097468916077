import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
  TableSortLabel,
  Link,
  Typography,
  TablePagination,
  Hidden,
  Button,
} from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";
import { SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { appContext } from "../../App";
import { HOME_PAGE, DATE_TIME_FORMAT } from "../../utils/utils";
import {
  getAllProperties,
  getUserProperties,
  updateProperty,
  deleteProperty,
} from "../../utils/api";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
//import { getDonationRequests } from "../../utils/api";

import "./Admin.scss";
import Container from "@material-ui/core/Container";

const headCells = [
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "updatedAt",
    numeric: true,
    disablePadding: false,
    label: "Updated Date",
  },
  {
    id: "propertyName",
    numeric: false,
    disablePadding: true,
    label: "Property Name",
  },

  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
  },
  bar_main: {
    display: "flex",
    justifyContent: "end",
  },

  table: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      "& Button": {
        padding: "8px",
      },
    },
    "& td .nomt": {
      marginLeft: "-8px",
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const AdminManageProperties = (props) => {
  const classes = useStyles();
  const { authUser, isAdmin, isVolunteer, isSuperAdmin } =
    useContext(appContext);
  const [propertyRequests, setPropertyRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadPropertyRequests = (page) => {
    setIsLoading(true);
    getAllProperties(rowsPerPage, page * rowsPerPage)
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);

          const propertyRequestArray = [];
          propertyRequests.map((data) => {
            const obj = data;
            if (data && data.status) {
              //obj.donee = ${data.user_info.name.first_name} ${data.user_info.name.last_name};
            }
            propertyRequestArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            if (data.user_info && data.user_info.name) {
              //obj.donee = ${data.user_info.name.first_name} ${data.user_info.name.last_name};
            }
            responseArray.push(obj);
            return null;
          });
          setPropertyRequests([...propertyRequestArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching property requests:" + error.message);
      });
  };

  const loadPropertyRequestsBasedOnKeyword = (
    keyword,
    page,
    propertyRequests
  ) => {
    getUserProperties(rowsPerPage, page * rowsPerPage, "","", keyword)
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);

          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            if (data.createdUserId && data._id) {
              obj.propertyName = `${data.propertyName}`;
            }
            responseArray.push(obj);
            return null;
          });
          setPropertyRequests([...propertyRequests, ...responseArray]);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching property requests:" + error.message);
      });
  };

  useEffect(() => {
    if (keyword) {
      setIsSearchMode(true);
      setPage(0);
      setPropertyRequests([]);
      loadPropertyRequestsBasedOnKeyword(keyword, 0, []);
    } else {
      setIsSearchMode(false);
      setPropertyRequests([]);
      loadPropertyRequests(page);
      setPage(0);
    }
  }, [keyword, rowsPerPage]);

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin) {
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  const editPropertyRequest = (row) => {
    props.history.push(`/sell-property/${row._id}`);
  };

  const handleUpdateProperty = (e, row) => {
    let properties = [...propertyRequests];
    let finalValues = {};
    finalValues["createdUserId"] = authUser && authUser.unique_id;
    finalValues.status = e.target.value;
    updateProperty(finalValues, row._id)
      .then(() => {
        properties = properties.map((property) => {
          if (property._id === row._id) {
            property = { ...property, status: e.target.value };
          }
          return property;
        });
        setPropertyRequests(properties);
        toastr.success("Property status updated successfully.");
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const onDeletePropertyRequest = (propertyRequestId) => {
    if (
      window.confirm(
        "The Property will be permenantly removed from database.Are you sure you want to delete the Property request?"
      )
    ) {
      deleteProperty(propertyRequestId, authUser.unique_id)
        .then(() => {
          // Update the list of Property requests
          setPropertyRequests((propertyRequests) => {
            return propertyRequests.filter(
              (propertyRequest) => propertyRequest._id !== propertyRequestId
            );
          });
          toastr.success("PropertyRequest is deleted successfully.");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (isSearchMode) {
      loadPropertyRequestsBasedOnKeyword(keyword, newPage, propertyRequests);
    } else {
      loadPropertyRequests(newPage);
    }
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (event.target.value) {
        event.target.blur();
        setKeyword(event.target.value);
      }
    }
  };

  const clearSearchResultsHandler = () => {
    setPropertyRequests([]);
    setIsSearchMode(false);
    setKeyword("");
    //setOffset(0);
    //setHasNextPage(true);
    setPage(0);
  };
  const EditDeleteTooltip = withStyles({
    tooltip: {
      color: "#fff",
      backgroundColor: "#6f6f70",
      marginTop: "0px",
    },
  })(Tooltip);
  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <Container MaxWidth="lg">
        <Box component="div" className="barnav">
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box
                component="h4"
                pt={1}
                color="primary.main"
                alignSelf="center"
                display="flex"
              > 
                Manage Properties 
              </Box>
            </Grid>
            <Grid item md={6}>
              <div className={classes.bar_main}>
                <div className="searchbar-dr">
                  <SearchRounded style={{ margin: "0 4px", height: "30px" }} />
                  <InputBase
                    placeholder="Search"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onKeyPress={(e) => handleEnterClick(e)}
                  />
                </div>

                {isSearchMode && (
                  <p className="result" style={{ marginLeft: "20px" }}>
                    Search Results for
                    <span
                      style={{
                        background: "white",
                        // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                        // borderRadius: "5px",
                        padding: "3px 10px 5px 10px",
                        marginLeft: "10px",
                        fontWeight: " bold",
                        color: "#686868",
                        background: "#f8f8f8",
                      }}
                    >
                      {keyword}
                    </span>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="close"
                      style={{
                        padding: "3px",
                        color: "rgb(205, 38, 39)",
                        position: "relative",
                        top: "-2px",
                        margin: "0 0 0 10px",
                        border: "1px solid #ededed",
                      }}
                    >
                      <Tooltip tilte="Clear Search Results">
                        <CloseIcon onClick={clearSearchResultsHandler} />
                      </Tooltip>
                    </IconButton>
                  </p>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>

        <div className="myDonations-container">
          <div className="menu-container">
            <div style={{ width: "100%", overflowX: "auto" }}>
              <div className="table-fixed drop-box">
                <TableContainer className="container">
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="sticky table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />

                    <TableBody>
                      {propertyRequests &&
                        propertyRequests
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                // onClick={event => handleClick(event, row.name)}
                                // role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell align="left">
                                  {moment(row.createdAt).format(
                                    DATE_TIME_FORMAT
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {moment(row.updatedAt).format(
                                    DATE_TIME_FORMAT
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className="hyper_txt"
                                >
                                  <Link
                                    href={`/property/${row._id}`}
                                    target="blank"
                                  >
                                    {row.propertyName}
                                  </Link>
                                </TableCell>
                                <TableCell align="left">{row.role}</TableCell>
                                <TableCell align="left">
                                  {/* {getStatusLabel(row.status)} */}
                                  <FormControl
                                    variant="outlined"
                                    style={{ width: "70%" }}
                                  >
                                    <Select
                                      labelId="action-label"
                                      id="action"
                                      value={row.status}
                                      onChange={(e) =>
                                        handleUpdateProperty(e, row)
                                      }
                                    >
                                      <MenuItem value="1">Approved</MenuItem>
                                      <MenuItem value="0">New/Pending</MenuItem>
                                      <MenuItem value="3">Sold</MenuItem>
                                      <MenuItem value="-1">Rejected</MenuItem>
                                      <MenuItem value="-2">Incomplete</MenuItem>
                                      <MenuItem value="-3">Spam</MenuItem>
                                      <MenuItem value="-4">Expired</MenuItem>
                                      <MenuItem value="-5">Deactive</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <EditDeleteTooltip title="Edit">
                                    <IconButton
                                      className="del-btn nomt"
                                      onClick={(e) => editPropertyRequest(row)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </EditDeleteTooltip>{" "}
                                  <EditDeleteTooltip title="Delete">
                                    <IconButton
                                      className="del-btn"
                                      onClick={(e) =>
                                        onDeletePropertyRequest(row._id)
                                      }
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </EditDeleteTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                  {isLoading && (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                  )}
                  {propertyRequests && propertyRequests.length === 0 && (
                    <Typography className="no-req-text">
                      Property Requests Not Found
                    </Typography>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AdminManageProperties;
