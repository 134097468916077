import React, { useContext, useState } from "react";
import { appContext } from "../../App";
//import  useInfiniteScroll  from "react-infinite-scroll-hook";
import { getContactedProperties } from "../../utils/api";
import toastr from "toastr";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router";
import DetailedPropertyCard from "../buy/DetailedPropertyCard";
import useInfiniteScroll from "react-infinite-scroll-hook";

function ContactedProperties(props) {
  const { authUser, rupee } = useContext(appContext);
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);

  const loadContactedProperties = () => {
    setIsLoading(true);
    getContactedProperties(10, offset, authUser.unique_id)
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const newOffset = offset + response.data.length;
          if (newOffset < response.totalCountOfRecords) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
          setOffset(newOffset);
          setProperties([...properties, ...response.data]);
        } else {
          setHasNextPage(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setHasNextPage(false);
        toastr.error(
          "Problem in fetching properties requests:" + error.message
        );
      });
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadContactedProperties,
    scrollContainer: "window",
  });

  const removeFromList = (id) => {
    setProperties(properties.filter((property) => property._id !== id));
  };

  return (
    <Container>
      <Box bgcolor="white" my={2}>
        <Box component="section" pt={2} px={2}>
          {props.match.params.id && (
            <Typography variant="h5" component="h5">
              Contacted Properties
            </Typography>
          )}
        </Box>

        <Box component="div" /*ref={infiniteRef}*/>
          {properties &&
            properties.length > 0 &&
            properties.map((property, index) => {
              return (
                <DetailedPropertyCard
                  property={property}
                  key={property._id}
                  rupee={rupee}
                  removeFromList={() => removeFromList(property._id)}
                />
              );
            })}
          {hasNextPage && (
            <p ref={infiniteRef} style={{ textAlign: "center" }}>
              Loading...
            </p>
          )}
          {!isLoading && properties && properties.length === 0 && (
            <p style={{ textAlign: "center" }}>
              No property is listed{" "}
              {props.match.params.id ? " from " + props.match.params.id : ""}
            </p>
          )}
        </Box>
      </Box>
    </Container>
  );
}
export default withRouter(ContactedProperties);
