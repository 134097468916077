import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router";
import { TextField } from "@material-ui/core";
import validator from "validator";
import { subscribeNewsLetter } from "../../utils/api";
import { appContext } from "../../App";
import toastr from "toastr";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: "#666",
    padding: theme.spacing(4),
    color: "white",
  },

  footer_menu: {
    padding: "0",
    "& h4": {
      color: "white",
    },

    "& ul": {
      padding: "0",
      "& li": {
        display: "block",
        "& a": {
          color: "#D9DBE1",
          display: "block",
          padding: "10px 10px 10px 0",
          fontSize: "17px",
          textAlign: "left",
          textDecoration: "none",
          /* width: "159px",*/

          "&:hover": {
            color: "white",
            fontWeight: "bold",
          },
        },
      },
    },
  },

  social_media: {
    marginTop: "20px",

    "& img": {
      width: "32px",
      height: "30px",
      backgroundColor: "#ffffff21",
      borderRadius: "50px",
      padding: "5px",
      margin: "0 10px",

      "&:nth-child(1)": {
        marginLeft: "0",
      },
    },
    "& img:hover": {
      backgroundColor: "red",
    },
  },

  news: {
    "& h4": {
      color: "white",
    },
    "& p": {
      color: "white",
    },

    "& input": {
      backgroundColor: "white",
      borderRadius: "5px",
      border: "none",
      padding: "10px",
      marginRight: "10px",
    },
    "& button": {
      backgroundColor: "white",
      borderRadius: "5px",
      border: "none",
      padding: "10px",
      color: "#ec1c24",
      fontWeights: "700",
      marginTop: "14px",
    },
  },
  footer_link: {
    "& a": {
      color: "white",
    },
    "&:hover": {
      color: "white",
    },
  },
}));
function Footer(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const { setOpenLogin, authUser, currentYear } = useContext(appContext);
  const [emailError, setEmailError] = useState("");
  const [active, setActive] = useState("");
  const [myProfile, setMyProfile] = useState(false);

  const [open, setOpen] = useState(false);
  const handleSubscribeEmail = (e) => {
    e.preventDefault();
    setEmailError("");
    if (email === "") {
      alert("Please enter e-mail");
      setEmailError("Please enter e-mail");
      return;
    } else if (!validator.isEmail(email)) {
      alert("Enter a valid e-mail");
      setEmailError("Enter a valid e-mail");
      return;
    }
    subscribeNewsLetter(email)
      .then((response) => {
        if (response) {
          setEmail("");
          toastr.success(response.message);
        }
      })
      .catch((error) => {
        toastr.error(error.data.message);
      });
  };
  const handleFavorites = () => {
    setActive("Favourites");
    setMyProfile(false);
    props.history.push(`/menubar/favourites`);
    setOpen(false);
  };
  return (
    <Box className="z_footer">
      <Box component="div" className={classes.paper}>
        <Container>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <Box component="div">
                <a href="#">
                  <img src="/images/footer_logo.png" alt="Logo" width="60%" />
                </a>
              </Box>
              <Box component="div" pt={4}>
                <Box component="h4" color="white">
                  Get the App
                </Box>
                <Box component="span" mr={2}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.fiveelementhomes.app&hl=en_IN&gl=US"
                    target="_blank"
                  >
                    <img
                      src="/images/google_play_store.svg"
                      alt="Google Play Store"
                      width="32%"
                    />
                  </a>
                </Box>
                <Box component="span">
                  <a
                    href="https://apps.apple.com/in/app/5elementhomes/id1608378733"
                    target="_blank"
                  >
                    <img
                      src="/images/app_store.svg"
                      alt="App Store"
                      width="32%"
                    />
                  </a>
                </Box>
              </Box>

              <Box component="div" className={classes.social_media}>
                <a href="#">
                  <img
                    src="/images/instagram_icon.svg"
                    alt="Google Play Store"
                  />
                </a>
                <a href="#">
                  <img
                    src="/images/dribbble_icon.svg"
                    alt="Google Play Store"
                  />
                </a>
                <a href="#">
                  <img src="/images/twitter_icon.svg" alt="Google Play Store" />
                </a>
                <a href="#">
                  <img src="/images/youtube_icon.svg" alt="Google Play Store" />
                </a>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box component="div" className={classes.footer_menu}>
                <h4>Quick links</h4>
                <nav>
                  <ul>
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    {!authUser ? (
                      <li>
                        <a href="#" onClick={() => setOpenLogin(true)}>
                          Favourites
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a href="#" onClick={handleFavorites}>
                          Favourites
                        </a>
                      </li>
                    )}
                    <li>
                      <a href="/menubar/contactUs">Contact Us</a>
                    </li>

                    {/* <li>
                      <a href="/feedback">Feedback </a>
                    </li> */}
                    {/* <li>
                      <a href="/user/help-desk">FAQ</a>
                    </li> */}
                  </ul>
                </nav>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box component="div" className={classes.news}>
                <h4>Subscribe to newsletter</h4>
                <p>
                  Get the latest news and interesting offers and real estate
                </p>
                <form>
                  {/* <TextField
                    error={emailError.length > 0}
                    id="email"
                    label="Your e-mail address"
                    name="email"
                    value={email}
                    helperText={emailError}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                  /> */}
                  <input
                 
                    type="text"
                    name="email"
                    value={email}
                    placeholder="Your e-mail address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button onClick={(e) => handleSubscribeEmail(e)}>
                    Subscribe
                  </button>
                  {/* <span
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {emailError}
                  </span> */}
                </form>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box component="p">© {currentYear} Zenrth. All rights reserved</Box>
            </Grid>
            <Grid item md={6}>
              <Box textAlign="right" className={classes.footer_link}>
                <Box component="a" mr={1} href="/termsOfUse">
                  Terms & Conditions &nbsp;|
                </Box>
                <Box component="a" mr={1} href="/Privacypolicy">
                  Privacy Policy &nbsp;|
                </Box>
                <Box component="a" href="/cookiepolicy">
                  Cookies
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
export default withRouter(Footer);
