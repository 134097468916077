const validateBuilder = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Please enter Builder name";
  }
  if (!values.description) {
    errors.description = "Please enter description";
  }
  return errors;
};
export default validateBuilder;
