import React, { useContext, useState, useEffect } from "react";
import { appContext } from "../../App";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { getAllProperties } from "../../utils/api";
import toastr from "toastr";
import {
  Paper,
  Typography,
  Box,
  Container,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
  InputBase,
} from "@material-ui/core";
import DetailedPropertyCard from "./DetailedPropertyCard";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import ListProperties from "../NewRequest/ListProperties";
import { convertFirstCharacterToUppercase } from "../../utils/utils";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { SearchRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
  },
}));

function Buy(props) {
  const { authUser, rupee } = useContext(appContext);
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [query, setQuery] = useState("");
  const [recordCount, setRecordCount] = useState("");
  /* let propOffset = 0 */
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);

  const nbtnRef = useRef(null);
  const pbtnRef = useRef(null);

  const loadProperties = (keyword) => {
    setIsLoading(true);
    getAllProperties(
      rowsPerPage,
      offset,
      "",
      "1",
      props.match.params.id ? props.match.params.id : "",
      authUser ? authUser.unique_id : "",
      authUser ? authUser.unique_id : "",
      query,
      keyword
    )
      .then((response) => {
        setIsLoading(false);
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          const newOffset = offset + response.data.length;
          if (newOffset < response.totalCountOfRecords) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
          setOffset(newOffset);
          setProperties((prev) => [...prev, ...response.data]);
          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(offset + rowsPerPage);
          // response.data.length < rowsPerPage
          //   ? setHasNextPage(false)
          //   : setHasNextPage(true);
        } else {
          setTotalCountOfRecords(response.totalCountOfRecords);
          // setOffset(0);
          setHasNextPage(false);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        setHasNextPage(false);
        toastr.error(
          "Problem in fetching properties requests:" + error.message
        );
      });

    // setOffset(offset + rowsPerPage)
    // propOffset += rowsPerPage;
  };

  const classes = useStyles();

  const handleScroll = (e) => {
    if (
      window.innerHeight + e.target.documentElement.scrollTop + 1 ===
      e.target.documentElement.scrollHeight
    ) {
      loadProperties(keyword);
    }
  };

  // useEffect(() => {
  //   loadProperties(keyword);
  //   window.addEventListener("scroll", handleScroll);
  //   // scrollToTop();
  // }, []);

  // useEffect(() => {
  //   loadProperties(keyword);
  //   // window.addEventListener("scroll", handleScroll)
  // }, [rowsPerPage, page, query, authUser]);

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: loadProperties,
    //  scrollContainer: "window",
  });

  //  console.log(infiniteRef);

  const removeFromList = (id) => {
    setProperties(properties.filter((property) => property._id !== id));
  };

  const makeIsContacted = (id) => {
    setProperties(
      properties.map((property) => {
        if (property._id === id) {
          return { ...property, isContacted: true };
        } else {
          return property;
        }
      })
    );
  };

  useEffect(() => {
    authUser?.unique_id && loadProperties();
  }, [authUser]);

  const sortProperties = (event) => {
    setOffset(0);
    setProperties([]);
    var queryStr = event.target.value;
    if (!queryStr) {
      setQuery("");
    } else {
      setHasNextPage(true);
      setQuery(queryStr);
    }
  };

  const changeHandler = (e) => {
    setPage(0);
    setProperties([]);
    setOffset(0);
  };
  const changeRowsPerPage = (e) => {
    setPage(0);
    setProperties([]);
    setOffset(0);
    setRowsPerPage(e.target.value);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handlePageNext = () => {
    setPage(page + 1);
    setOffset(offset + rowsPerPage);
    nbtnRef.current.blur();
  };
  const handlePagePrevious = (e) => {
    setPage(page - 1);
    setOffset(offset - rowsPerPage);
    pbtnRef.current.blur();
  };

  return (
    <Container style={{ marginTop: "25px" }}>
      <Paper>
        <Grid container className="sortcls">
          <Grid container className="sortcbr">
            <Grid items sm={8} xs={12}>
              <h4>
                {recordCount} Properties For Sale {""}
                {props.match.params.id ? "In " : ""}
                {""}
                {props.match.params.id
                  ? convertFirstCharacterToUppercase(props.match.params.id)
                  : ""}
              </h4>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className="sortslct">
              Sort by:{"   "}
              <select
                name="Relevance"
                id="Relevance"
                onChange={sortProperties}
                autoFocus
              >
                <option value="">Relevance</option>
                <option value="&sortBy=cost.quantity&sortOrder=1">
                  Price: Low to High
                </option>
                <option value="&sortBy=cost.quantity&sortOrder=-1">
                  Price: High to Low
                </option>
                <option value="&sortBy=createdAt&sortOrder=-1">
                  Posted Date: Latest First
                </option>
                <option value="&sortBy=createdAt&sortOrder=1">
                  Posted Date: Oldest First
                </option>
              </select>
            </Grid>
          </Grid>
        </Grid>
        <Box component="div">
          {props.match.params.id && (
            <Typography variant="h5" component="h5"></Typography>
          )}
          <div>
            {properties &&
              properties.length > 0 &&
              properties.map((property, index) => {
                return (
                  <DetailedPropertyCard
                    property={property}
                    key={property._id}
                    rupee={rupee}
                    removeFromList={() => removeFromList(property._id)}
                    makeIsContacted={() => makeIsContacted(property._id)}
                  />
                );
              })}
            {hasNextPage && (
              <p ref={infiniteRef} style={{ textAlign: "center",marginBottom:"20px",marginTop:"20px" }}>
                Loading...
              </p>
            )}
            {!isLoading && properties && properties.length === 0 && (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom:"20px"
                }}
              >
                No Properties are listed{" "}
                {props.match.params.id ? " from " + props.match.params.id : ""}
              </p>
            )}
          </div>
        </Box>
      </Paper>
    </Container>
  );
}
export default withRouter(Buy);
