import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { LocationOn } from "@material-ui/icons";
import Box from "@material-ui/core/Box";

import moment from "moment";
import {
  DATE_FORMAT,
  formatNumber,
  PROPERTY_STATUS_MAP,
} from "../../utils/utils";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { withRouter } from "react-router";
import DeleteDeactiveProperty from "./DeleteDeactivateProperty";
import toastr from "toastr";
import { deleteProperty } from "../../utils/api";
import { appContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { roundToTwoPlaces } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  actiontab: {},
  cardmodel: {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 10px 0 10px ",
  },
  details: {
    display: "flex",
    // width: "60%",
    flexDirection: "column",
    margin: "5px 20px",
  },
  content: {
    flex: "1 0 auto",
    padding: "0",
    "& Button": {
      marginLeft: "-10px",
      color: "#3D69B2",
    },
    "& h6": {
      marginLeft: "-5px",
    },
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  price: {
    lineHeight: "20px",
    marginBottom: "10px",
    "& small": {
      color: "#7E7E7E",
    },
  },
}));

function BriefPropertyCard(props) {
  const classes = useStyles();
  const { authUser } = useContext(appContext);
  const { property, rupee } = props;
  const [action, setAction] = useState();
  const [open, setOpen] = React.useState(false);
  const removeProperty = (uniqueId) => {
    if (window.confirm("Are you sure you want to delete property?")) {
      deleteProperty(uniqueId, authUser.unique_id)
        .then((response) => {
          props.removeFromList(uniqueId);
          toastr.success("Successfully deleted");
        })
        .catch((error) => {
          toastr.error(error && error.response && error.response.message);
        });
    }
  };
  const handleChange = (e) => {
    setAction(e.target.value);
    if (e.target.value === 1) {
      props.history.push(`/sell-property/${property._id}`);
    } else if (e.target.value === -5) {
      setOpen(true);
    } else if (e.target.value === -6) {
      removeProperty(property._id);
    }
  };
  const handleReactivate = () => {
    props.history.push(`/sell-property/${property._id}`);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const convertRuppes2Doller = (amount) =>
    formatNumber((amount / rupee).toFixed(2));
  return (
    <Box component="section" position="relative">
      <Box component="div" bgcolor="#f6f7fb" my={2} p={2} pt={0}>
        <Box component="div" className={classes.cardmodel}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={10} style={{ paddingLeft: "0px" }}>
              <Box
                component="div"
                fontWeight="bold"
                display="flex"
                gridGap="10px"
                flexWrap="wrap"
                pt={2}
              >
                <Box component="span">Property Id: {property._id} </Box>
                <Box component="span">{" | "}</Box>
                <span>
                  Created:{" "}
                  {property && moment(property.createdAt).format(DATE_FORMAT)}
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <FormControl
                variant="standard"
                fullWidth
                className={classes.actiontab}
              >
                <InputLabel id="action-label" style={{ color: "#3D69B2" }}>
                  Action
                </InputLabel>
                <Select
                  labelId="action-label"
                  id="action"
                  value={action}
                  onChange={handleChange}
                  style={{ color: "#3D69B2" }}
                >
                  {property.status !== 1 && (
                    <MenuItem value={1}>Edit Property</MenuItem>
                  )}
                  {property.status !== -5 && (
                    <MenuItem value={-5}>Deactivate Property</MenuItem>
                  )}
                  <MenuItem value={-6}>Delete Property</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <div className="box-model">
          <div
            className={
              property && PROPERTY_STATUS_MAP[property.status].ribbonBgColor
            }
          ></div>

          <div
            className={
              property && PROPERTY_STATUS_MAP[property.status].ribbonTextColor
            }
          >
            {property && PROPERTY_STATUS_MAP[property.status].shortLabel}
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <a href="">
              <CardMedia
                className={classes.cover}
                component="img"
                alt="Property"
                image={
                  property && property.defaultImageUrl
                    ? property.defaultImageUrl
                    : "/images/sell_home.jpg"
                }
                onClick={() => props.history.push(`/property/${property._id}`)}
                title="property"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <div className={classes.details}>
              <div className={classes.price}>
                <CardContent className={classes.content}>
                  <Button
                    onClick={() =>
                      props.history.push(`/property/${property._id}`)
                    }
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{ color: "#3D69B2", fontSize: "1.2rem" }}
                    >
                      {property.propertyName}
                    </Typography>
                  </Button>
                  <Typography variant="subtitle1" color="textSecondary">
                    {property && property.location && property.location.city && (
                      <span>
                        <LocationOn style={{ fontSize: 14, color: "blue" }} />
                        {
                          (property.location.landmark,
                          property.location.city,
                          property.location.state)
                        }
                      </span>
                    )}
                  </Typography>
                </CardContent>
              </div>
              {property.propertyConfiguration &&
              property.propertyConfiguration.length === 1 ? (
                property.propertyConfiguration.map((propertyConfig, index) => (
                  <>
                    <div className={classes.price}>
                      <Typography
                        component="p"
                        variant="p"
                        fontWeight="700"
                        fontSize="15px"
                      >
                      {propertyConfig.cost!==0 && <FontAwesomeIcon size="1x" icon={faRupeeSign} />}{" "}
                        {propertyConfig.cost!==0 &&
                          formatNumber(roundToTwoPlaces(propertyConfig.cost))}
                        {propertyConfig.cost!==0 && <>{"("}
                        <FontAwesomeIcon size="1x" icon={faDollarSign} />                        {propertyConfig.cost!==0 &&
                          formatNumber(
                            convertRuppes2Doller(
                              roundToTwoPlaces(propertyConfig.cost)
                            )
                          )}{")"}</>}
                        
                      </Typography>

                      <Typography
                        component="small"
                        variant="body1"
                        style={{ marginTop: "5px", display: "inline-block" }}
                      >
                       {propertyConfig.cost!==0 && <FontAwesomeIcon size="1x" icon={faRupeeSign} />}{" "}
                        {propertyConfig.propertyType !== "Residential Land"
                          ? propertyConfig.cost!==0 &&
                            propertyConfig.builtupArea &&
                            roundToTwoPlaces(
                              propertyConfig.cost /
                                propertyConfig.builtupArea.number
                            )
                          : propertyConfig.cost!==0 &&
                            propertyConfig.landArea &&
                            roundToTwoPlaces(
                              propertyConfig.cost /
                                propertyConfig.landArea.number
                            )}
                        {propertyConfig.cost!==0 && "/"}
                        {propertyConfig.cost!==0 && propertyConfig.propertyType !== "Residential Land"
                          ? propertyConfig.builtupArea &&
                            propertyConfig.builtupArea.units
                          : propertyConfig.landArea &&
                            propertyConfig.landArea.units}
                      </Typography>
                    </div>

                    <Grid container style={{ justifyContent: "space-between" }}>
                      <Grid md={10}>
                        <Box display="flex" gridGap="20px">
                          {propertyConfig.propertyType !==
                          "Residential Land" ? (
                            <>
                              <Typography variant="subtitle1" component="span">
                                Built Up Area:
                                {propertyConfig.builtupArea &&
                                  propertyConfig.builtupArea.number}{" "}
                                {propertyConfig.builtupArea &&
                                  propertyConfig.builtupArea.units}
                              </Typography>

                              <Typography variant="subtitle1" component="span">
                                Carpet Area:
                                {propertyConfig.carpetArea &&
                                  propertyConfig.carpetArea.number}{" "}
                                {propertyConfig.carpetArea &&
                                  propertyConfig.carpetArea.units}
                              </Typography>
                            </>
                          ) : (
                            <Typography variant="subtitle1" component="span">
                              Land Area:
                              {propertyConfig.landArea &&
                                propertyConfig.landArea.number}{" "}
                              {propertyConfig.landArea &&
                                propertyConfig.landArea.units}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid md={2}>
                        {property.status === -5 ? (
                          <Button
                            onClick={handleReactivate}
                            color="primary"
                            variant="contained"
                            autoFocus
                            fullWidth
                          >
                            Reactivate
                          </Button>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </>
                ))
              ) : (
                <>
                  {property.propertyConfiguration.length === 2 ? (
                    <>
                      <Typography variant="subtitle1" component="span">
                        {property.propertyConfiguration &&
                          property.propertyConfiguration.length === 2 &&
                          property.propertyConfiguration[0].name +
                            ", " +
                            property.propertyConfiguration[1].name}
                      </Typography>
                    </>
                  ) : property.propertyConfiguration.length === 3 ? (
                    <>
                      {" "}
                      <Typography variant="subtitle1" component="span">
                        {property.propertyConfiguration &&
                          property.propertyConfiguration.length === 3 &&
                          property.propertyConfiguration[0].name +
                            ", " +
                            property.propertyConfiguration[1].name +
                            ", " +
                            property.propertyConfiguration[2].name}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle1" component="span">
                        {property.propertyConfiguration &&
                          property.propertyConfiguration.length > 3 &&
                          property.propertyConfiguration[0].name +
                            ", " +
                            property.propertyConfiguration[1].name +
                            ", " +
                            property.propertyConfiguration[2].name +
                            " and more…"}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>

        <DeleteDeactiveProperty
          open={open}
          handleClose={handleClose}
          action={action}
          property={property}
        />
      </Box>
    </Box>
  );
}
export default withRouter(BriefPropertyCard);
