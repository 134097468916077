import React, { useEffect, useContext, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import ListItemText from "@material-ui/core/ListItemText";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Container from "@material-ui/core/Container";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PodcastVideoPlayer from "../common/PodcastVideoPlayer";
import BuilderDetailsSideBar from "./BuildersDetailsSidebar";
import AddPhotos from "../common/AddPhotos";
import firebase from "../../firebase/firebase";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import StickyBox from "react-sticky-box";
import {
  getBuilderdetails,
  deleteBuilder,
  inviteMember,
  removeUser,
  changeUserRole,
  updateBuilderStatus,
  getBuilderProperties,
} from "../../utils/api";
import { appContext } from "../../App";
import toastr from "toastr";
import validator from "validator";
import "./BuildersDetails.scss";
import { ROLE_ADMIN, ROLE_OWNER, TALRADIO_SITE } from "../../utils/utils";
import AddIcon from "@material-ui/icons/Add";
import DetailedPropertyCard from "../buy/DetailedPropertyCard";
import Builder from "../Builder/BuilderForm";
import BriefPropertyCard from "../buy/BriefPropertyCard";
import ShareOnSocialMedia from "../Share/ShareOnSocialMedia";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    overflowX: "auto",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  inviteBtn: {
    background: "#3D69B2 ",
    margin: "10px 0",
    fontSize: 14,
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      background: "#3D69B2",
    },
  },
  editButton: {
    margin: 8,
    background: "#ec1c24 !important",
    color:"#ffff",
    "&:hover": {
      backgroundColor: "#ec1c24 !important",
      color:"#fff",
    },
  },
  delButton: {
    margin: 8,
  },
}));

const BuildersDetails = (props) => {
  const { authUser, rupee, builderList, setBuilderList } =
    useContext(appContext);
  const classes = useStyles();
  const [properties, setProperties] = useState([]);
  const [showCoverPhoto, setShowCoverPhoto] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [showProfilePhoto, setShowProfilePhoto] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [userData] = useState(null);
  const [editMode] = useState(false);
  const [members, setMembers] = useState(true);
  const [selectedOption, setSelectedOption] = useState("timeline");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [noUser] = useState(false);

  const [openBuilderModal, setOpenBuilderModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [type, setType] = useState("Select role");
  const [refresh, setRefresh] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [builderDetails, setBuilderDetails] = useState([]);
  const [shareIcons, setShareIcons] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      handleBuilderDetails();
      loadProperties(props.match.params.id);
    }
  }, [props.match.params.id, refresh,authUser]);

  const handleBuilderDetails = () => {
    getBuilderdetails(
      props && props.match && props.match.params && props.match.params.id
    )
      .then((resp) => {
        setBuilderDetails(resp);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const removeFromList = (id) => {
    setProperties(properties.filter((property) => property._id !== id));
  };

  const makeIsContacted = (id) => {
    setProperties(
      properties.map((property) => {
        if (property._id === id) {
          return { ...property, isContacted: true };
        } else {
          return property;
        }
      })
    );
  };
  const loadProperties = (builderId) => {
    getBuilderProperties(builderId,"1",
    authUser ? authUser.unique_id : "",
    authUser ? authUser.unique_id : "")
      .then((response) => {
        setProperties(response.data);
      })
      .catch((error) => {
        toastr.error("Error getting properties:" + error.message);
      });
  };

  const handleuserDetails = (e, user) => {
    user === "email" && setEmail(e.target.value);
    user === "type" && setType(e.target.value);
  };

  const handleInvite = () => {
    const values = {};
    values.userId = authUser.unique_id;
    values.role = type;
    values.email = email;
    values.isDefault = true;
    if (email === "" || type === "Select role") {
      if (email === "") {
        setEmailError("Please enter email");
      } else if (!validator.isEmail(email)) {
        setEmailError("Please enter valid  email");
      } else {
        setEmailError("");
      }
      if (type === "Select role") {
        setTypeError("Please select role");
      } else {
        setTypeError("");
      }
    } else {
      inviteMember(builderDetails[0]._id, values).then((resp) => {
        if (resp.data.statusCode === 200) {
          toastr.success(resp.data.message);
          setType("Select role");
          setEmail("");
          setOpenModal(false);
        }
      });
    }
  };

  const handleDeleteBuilder = (builder) => {
    if (window.confirm("Are you sure you want to delete the builder?")) {
      const userId = builder && builder.userId && builder.userId._id;
      if (userId) {
        deleteBuilder(props.match.params.id)
          .then((resp) => {
            if (resp && resp.data && resp.data.statusCode === 200) {
              toastr.success(resp.data.message);
              setBuilderList(
                builderList.filter(
                  (builder) => builder._id !== props.match.params.id
                )
              );
              props.history.push(`/menubar/profile`);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
      }
    }
  };

  const onSavingBuilder = (builderId) => {
    setOpenBuilderModal(false);
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (
      authUser &&
      builderDetails &&
      builderDetails.users &&
      builderDetails.users.length > 0
    ) {
      builderDetails.users.forEach((user) => {
        if (user.userId._id === authUser.unique_id) {
          if (user.role === ROLE_OWNER || user.role === ROLE_ADMIN) {
            setIsAdmin(true);
          }
        }
      });
    }
    loadImages(builderDetails && builderDetails[0] && builderDetails[0]._id);
    loadVideos(builderDetails && builderDetails[0] && builderDetails[0]._id);
    setCoverImageUrl(
      builderDetails && builderDetails[0] && builderDetails[0].coverImageUrl
    );
    setProfileImageUrl(
      builderDetails && builderDetails[0] && builderDetails[0].defaultImageUrl
    );
  }, [builderDetails, authUser]);

  const loadImages = (uid) => {
    setImages([]);
    const path = `Builder/${uid}/images`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          item.getMetadata().then((metadata) => {
            setImages((prevImages) => [
              ...prevImages,
              { url: url, name: metadata.name },
            ]);
          });
        });
      });
    });
  };

  const loadVideos = (uid) => {
    setVideos([]);
    const path = `Builder/${uid}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              setVideos((prevVideos) => [
                ...prevVideos,
                {
                  url: url,
                  name: metadata.name,
                  userGivenName: metadata.customMetadata.userGivenName,
                  note: metadata.customMetadata.note,
                },
              ]);
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        });
      });
    });
  };

  useEffect(() => {
    if (userData && userData.unique_id) {
      if (selectedOption === "timeline") {
      } else if (selectedOption === "photos") {
      } else if (selectedOption === "videos") {
      }
    }
  }, [selectedOption]);

  const updateDefaultImageUrl = (url) => {
    setProfileImageUrl(url);
    const finalValues = {
      defaultImageUrl: url,
    };
    updateBuilderStatus(
      builderDetails && builderDetails[0] && builderDetails[0]._id,
      finalValues
    )
      .then((response) => {})
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const updateCoverImageUrl = (url) => {
    setCoverImageUrl(url);
    const finalValues = {
      coverImageUrl: url,
    };
    updateBuilderStatus(
      builderDetails && builderDetails[0] && builderDetails[0]._id,
      finalValues
    )
      .then((response) => {})
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const onPlayVideo = (document) => {
    setIsPlayingVideo(true);
  };

  const onClosePlayVideo = () => {
    setIsPlayingVideo(false);
  };

  const propertyClickHandler = (id) => {
    props.history.push(`/property/${id}`);
  };

  const handleAddMember = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleBuilderUser = (type, builder) => {
    if (type === "remove") {
      builderDetails &&
        builderDetails[0] &&
        removeUser(builderDetails[0]._id, builder.userId._id)
          .then((resp) => {
            if (resp.data.statusCode === 200) {
              toastr.success(resp.data.message);
              setRefresh(!refresh);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
    }
    if (type === "role") {
      const finalValues = {};
      finalValues.userId = builder.userId._id;
      if (builder.role === "member") {
        finalValues.role = "admin";
      } else {
        finalValues.role = "member";
      }
      finalValues.builderEmailAddress = builder.email;
      builderDetails &&
        builderDetails[0] &&
        changeUserRole(builderDetails[0]._id, builder.userId._id, finalValues)
          .then((resp) => {
            if (resp.data.statusCode === 200) {
              toastr.success(resp.data.message);
              setRefresh(!refresh);
              if (builder && builder.userId._id === authUser.unique_id) {
                setSelectedOption("timeline");
                setMembers(false);
              }
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
    }
  };

  const viewAllPhotosHandler = () => {
    setSelectedOption("photos");
  };

  const handleBuilder = () => {
    setOpenBuilderModal(true);
    setEdit(true);
  };

  const viewAllVideosHandler = () => {
    setSelectedOption("videos");
  };
  let builder = builderDetails && builderDetails[0];
  return (
    <div className="public-profile-container">
      {/* <div class="white"></div> */}
      <div class="public-main">
        {!noUser && (
          <Fragment>
            {/* <div className="public-bg-image"></div> */}
            <Box component="div" className="drop-box">
              <Container maxWidth="lg">
                <div className="public-cover-page">
                  {isAdmin && (
                    <div className="updateBannerBlk">
                      <CameraAltIcon
                        onClick={() => setShowCoverPhoto(true)}
                        className="updateIcon"
                      />
                    </div>
                  )}
                  <div className="head-img">
                    <img
                      src={
                        coverImageUrl
                          ? coverImageUrl
                          : "/images/celeb-profile-bg.jpeg"
                      }
                      className="cover-pic"
                      alt="cover-pic"
                      width="100%"
                    />
                  </div>

                  <CardHeader
                    className="public-card-header"
                    avatar={
                      <div className="profile-pic-container">
                        <div className="profile-main">
                          {isAdmin && (
                            <div className="profileCamIcon">
                              <CameraAltIcon
                                onClick={() => setShowProfilePhoto(true)}
                                className="updateIcon"
                              />
                            </div>
                          )}
                          <div className="pulic-propic">
                            <img
                              src={
                                profileImageUrl
                                  ? profileImageUrl
                                  : "/images/orgLogo.png"
                              }
                              alt="Profile Pic"
                              className="profile-pic"
                            />
                          </div>
                        </div>
                        <h2>
                          {builder && builder.builderName}{" "}
                          <VerifiedUser
                            style={{ color: "green", fontSize: 18 }}
                          />
                        </h2>
                        {/* <Button
                      className="public-cause-btn"
                      onClick={(e) => setIsPaymentsPopUpOpen(true)}
                    >
                      Donate To My Causes
                    </Button> */}
                      </div>
                    }
                    title={
                      <div className="borderBottom-nav">
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={`${classes.root} public-page-navbar`}
                        >
                          <ListItem button className="options">
                            <ListItemText
                              primary="Timeline"
                              style={{
                                color:
                                  selectedOption === "timeline"
                                    ? "#ec1c24"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "timeline"
                                    ? "2px solid #ec1c24"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 16,
                                margin: 0,
                                // paddingLeft: "10px",
                                // paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("timeline")}
                            />
                          </ListItem>
                          <ListItem button className="options">
                            <ListItemText
                              primary="About"
                              style={{
                                color:
                                  selectedOption === "about"
                                    ? "#ec1c24"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "about"
                                    ? "2px solid #ec1c24"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 15,
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("about")}
                            />
                          </ListItem>
                          <ListItem button className="options">
                            <ListItemText
                              primary="Photos"
                              style={{
                                textAlign: "center",
                                color:
                                  selectedOption === "photos"
                                    ? "#ec1c24"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "photos"
                                    ? "2px solid #ec1c24"
                                    : "none",
                                paddingBottom: 15,
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("photos")}
                            />
                          </ListItem>
                          <ListItem button className="options">
                            <ListItemText
                              primary="Videos"
                              style={{
                                color:
                                  selectedOption === "videos"
                                    ? "#ec1c24"
                                    : "#000",
                                borderBottom:
                                  selectedOption === "videos"
                                    ? "2px solid #ec1c24"
                                    : "none",
                                textAlign: "center",
                                paddingBottom: 15,
                                margin: 0,
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              onClick={(e) => setSelectedOption("videos")}
                            />
                          </ListItem>
                          {isAdmin && members && (
                            <ListItem button className="options">
                              <ListItemText
                                primary="Members"
                                style={{
                                  color:
                                    selectedOption === "members"
                                      ? "#ec1c24"
                                      : "#000",
                                  borderBottom:
                                    selectedOption === "members"
                                      ? "2px solid #ec1c24"
                                      : "none",
                                  textAlign: "center",
                                  paddingBottom: 15,
                                  margin: 0,
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                onClick={(e) => setSelectedOption("members")}
                              />
                            </ListItem>
                          )}
                        </List>

                        <div button className="share-option">
                          <div>
                            <div className="share-cont">
                              {isAdmin && (
                                <div style={{ fontSize: 14 }} button>
                                  <div
                                    style={{
                                      textAlign: "end",
                                      display: "flex",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      // color="primary"                                      
                                      className={classes.editButton}
                                      startIcon={<EditIcon />}
                                      onClick={handleBuilder}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ background: "rgb(220, 0, 78)" }}
                                      className={classes.delButton}
                                      onClick={() =>
                                        handleDeleteBuilder(builderDetails[0])
                                      }
                                      startIcon={<DeleteIcon />}
                                    >
                                      Delete
                                    </Button>
                                    <ListItem
                                      button
                                      className="share-option"
                                      style={{ padding: "0", height: "50px" }}
                                    >
                                      <ListItemText>
                                        <div
                                          className="share-cont"
                                          onClick={() => setShareIcons(true)}
                                          onMouseLeave={() =>
                                            setShareIcons(false)
                                          }
                                        >
                                          <Tooltip title="Share Public Profile">
                                            <IconButton
                                              aria-label="share"
                                              style={{
                                                padding: 0,
                                                background: "#ccc",
                                                borderRadius: 4,
                                              }}
                                              onClick={handleClickOpen}
                                            >
                                              <ListItem
                                                style={{ fontSize: 14 }}
                                                button
                                              >
                                                <FontAwesomeIcon
                                                  size="1x"
                                                  icon={faShare}
                                                  style={{ marginRight: 8 }}
                                                />{" "}
                                                Share
                                              </ListItem>
                                            </IconButton>
                                          </Tooltip>

                                          {shareIcons && (
                                            <span>
                                              <ShareOnSocialMedia
                                                open={open}
                                                handleClose={() =>
                                                  setOpen(false)
                                                }
                                                quote={
                                                  builderDetails &&
                                                  builderDetails[0]._id
                                                }
                                                url={`${
                                                  process.env.REACT_APP_BASE_URL
                                                }/${
                                                  builderDetails &&
                                                  builderDetails[0]._id
                                                }`}
                                                subject={`[Zenrth ] Have you seen this profile "${
                                                  builderDetails &&
                                                  builderDetails[0]._id
                                                }"?`}
                                                content={`Hello,<br /><br />I thought you might be interested in this profile of ${
                                                  builderDetails[0]._id
                                                } profile:<br /> ${
                                                  process.env.REACT_APP_BASE_URL
                                                }/${
                                                  builderDetails &&
                                                  builderDetails[0]._id
                                                }
                                    <br /><br /> Thanks, <br />Zenrth`}
                                                redirectPage={`/${
                                                  builderDetails &&
                                                  builderDetails[0]._id
                                                }`}
                                                shareURL={`${
                                                  process.env.REACT_APP_BASE_URL
                                                }/${
                                                  builderDetails &&
                                                  builderDetails[0]._id
                                                }`}
                                              />
                                            </span>
                                          )}
                                        </div>
                                      </ListItemText>
                                    </ListItem>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>
              </Container>
            </Box>
          </Fragment>
        )}
        {selectedOption === "timeline" && (
          <div className="public-page-body">
            <Container maxWidth="lg">
              {noUser ? (
                <h3 className="no-user-text">
                  No user found with the username...
                </h3>
              ) : (
                <Grid container spacing={2} className="public-body-grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className="public-grid-left"
                  >
                    <StickyBox
                      offsetTop={20}
                      offsetBottom={20}
                      className="drop-box"
                      style={{ padding: "15px" }}
                    >
                      <BuilderDetailsSideBar
                        editMode={editMode}
                        builderData={builderDetails}
                        viewAllPhotos={viewAllPhotosHandler}
                        viewAllVideos={viewAllVideosHandler}
                        images={images}
                        videos={videos}
                        isAdmin={isAdmin}
                        onSavingImage={(e) => loadImages(builderDetails[0]._id)}
                        onSavingVideo={(e) => loadVideos(builderDetails[0]._id)}
                      />
                    </StickyBox>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    className="public-grid-right"
                  >
                    <Fragment>
                      {properties && properties.length > 0 ? (
                        properties.map((property, index) => {
                          return (
                            <DetailedPropertyCard
                              property={property}
                              key={property._id}
                              rupee={rupee}
                              removeFromList={() =>
                                removeFromList(property._id)
                              }
                              makeIsContacted={() =>
                                makeIsContacted(property._id)
                              }
                            />
                          );
                        })
                      ) : (
                        <p className="no-req-text">No Properties created</p>
                      )}
                    </Fragment>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        )}

        {selectedOption === "about" && (
          <Container>
            <div className="public-page-body about-option">
              <div className="about-section">
                <h2>About</h2>
                <Box component="p" p={2}>
                  {builder && builder.description}
                </Box>
              </div>
            </div>
          </Container>
        )}

        {selectedOption === "photos" && (
          <Container>
            <div className="public-page-body photos-option">
              <div className="photos-section">
                <h2>Photos</h2>
                <Box component="div" p={2}>
                  {images.length > 0 ? (
                    images.map((image) => (
                      <div className="images-container">
                        <img src={image.url} alt={image.name} width="100%" />
                      </div>
                    ))
                  ) : (
                    <p className="no-req-text">No photos uploaded</p>
                  )}
                </Box>
              </div>
            </div>
          </Container>
        )}

        {selectedOption === "videos" && (
          <Container>
            <div className="public-page-body videos-option">
              <div className="videos-section">
                <Grid container>
                  <Grid  xs={12}>
                    {" "}
                    <h2 style={{ textAlign: "center",color:"#ec1c24" }}>Videos</h2>
                  </Grid>
                  {edit && (
                    <Grid md={6} sm={6}>
                      <Button variant="contained" className="red_bg">
                        Upload Videos
                      </Button>
                    </Grid>
                  )}

                  {videos.length > 0 ? (
                    videos.map((video, index) => (
                      <Grid md={3} sm={6} style={{ padding: "10px" }}>
                        <div className="images-container">
                          <PodcastVideoPlayer
                            key={index}
                            isOpen={isPlayingVideo}
                            onClose={onClosePlayVideo}
                            onPlayVideo={(e) => onPlayVideo(video)}
                            url={video.url}
                            userGivenName={video.userGivenName}
                            note={video.note}
                          />
                        </div>
                      </Grid>
                    ))
                  ) : (
                    <Grid md={12}>
                      {" "}
                      <p className="no-req-text">No videos uploaded</p>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </Container>
        )}

        {selectedOption === "members" && (
          <Container>
            <div className="public-page-body videos-option">
              <div className="videos-section">
                <div style={{ marginTop: 15 }}>
                  {isAdmin && (
                    <Button onClick={handleAddMember} className="add_newmem red_bg">
                      + Add New Member To Builder
                    </Button>
                  )}
                  <TableContainer
                    component="div"
                    style={{ marginBottom: "30px" }}
                  >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Profile</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {builderDetails.users &&
                          builderDetails.users.map((builderUser, ind) => (
                            <TableRow key={ind}>
                              <TableCell component="th" scope="row">
                                <img
                                  src={
                                    builderUser.userId &&
                                    builderUser.userId.image_url
                                      ? builderUser.userId.image_url
                                      : "/images/default-profile-photo.png"
                                  }
                                  alt="user"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </TableCell>
                              <TableCell>{builderUser.userId.email}</TableCell>
                              <TableCell
                                style={{ textTransform: "capitalize" }}
                              >
                                {builderUser.role}
                              </TableCell>
                              <TableCell>
                                {builderUser.role === "owner" ? (
                                  "-"
                                ) : (
                                  <p style={{ margin: 0 }}>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        borderBottom: "1px solid #283e4a",
                                      }}
                                      onClick={() =>
                                        handleBuilderUser("role", builderUser)
                                      }
                                    >
                                      {builderUser.role === "admin"
                                        ? "Revoke Admin"
                                        : "Make admin"}
                                    </span>
                                    {" / "}
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        borderBottom: "1px solid #283e4a",
                                      }}
                                      onClick={() =>
                                        handleBuilderUser("remove", builderUser)
                                      }
                                    >
                                      Remove
                                    </span>
                                  </p>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </Container>
        )}

        <AddPhotos
          title="Manage Cover Photo"
          isOpen={showCoverPhoto}
          onClose={() => setShowCoverPhoto(false)}
          uid={builderDetails && builderDetails[0] && builderDetails[0]._id}
          collection="Builder"
          folder="coverPictures"
          profilephotoURL={coverImageUrl}
          onProfilephotoURLChange={updateCoverImageUrl}
          canEdit={true}
        />
        <AddPhotos
          title="Manage Profile Photo"
          isOpen={showProfilePhoto}
          onClose={() => setShowProfilePhoto(false)}
          uid={builderDetails && builderDetails[0] && builderDetails[0]._id}
          collection="Builder"
          folder="logos"
          profilephotoURL={profileImageUrl}
          onProfilephotoURLChange={updateDefaultImageUrl}
          canEdit={true}
        />
      </div>
      <Builder
        openBuilderModal={openBuilderModal}
        onCloseModal={(e) => setOpenBuilderModal(false)}
        onSavingBuilder={onSavingBuilder}
        builderDetails={builderDetails[0]}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper} style={{ width: 620 }}>
            <TextField
              type="email"
              className={`input-field request-title`}
              variant="outlined"
              label="Enter Email Address of the new member"
              name="email"
              value={email}
              onChange={(e) => handleuserDetails(e, "email")}
            />
            {emailError !== "" && (
              <div className="custom-error">{emailError}</div>
            )}
            <FormControl className={classes.orgType} style={{ width: "100%" }}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                style={{
                  top: 0,
                  left: 25,
                  background: "#fff",
                  paddingRight: 15,
                  zIndex: 9,
                }}
              >
                Select A Role For The New Member
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={type}
                onChange={(e) => handleuserDetails(e, "type")}
                variant="outlined"
                className={`input-field request-amount `}
                name="type"
                label="Builder Type"
                style={{ width: "100%" }}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"member"}>Member</MenuItem>
              </Select>
            </FormControl>
            {typeError !== "" && (
              <div className="custom-error">{typeError}</div>
            )}
            <Button onClick={handleInvite} className="add_newmem red_bg">
              Invite User To Join The Builder
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default BuildersDetails;
