import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { changeUsername } from "./../utils/api";
import { appContext } from "./../App";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    textAlign: "center",
    width: "25%",
    margin: "0 auto",
    height: 520,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding:"25px 50px",
    boxShadow: theme.shadows[5],
  },
  header_bg: {
    backgroundImage: `url(${"/images/hero_img.jpg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "20px 0",
    height:"560px",
  },
}));

export default function EditUsername(props) {
  const { authUser, setAuthUser, orgUnRegisteredUser, orgUserPayload } =
    useContext(appContext);
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const [editUsername, setUsername] = React.useState("");

  useEffect(() => {
    setUsername(authUser && authUser.username);
  }, [authUser]);

  const handleEditNext = () => {
    if (authUser && orgUserPayload && orgUnRegisteredUser === true) {
      const obj = { ...orgUserPayload };
      obj.user = authUser.unique_id;
      obj.userId = authUser.unique_id;
    } else {
      props.history.push("/");
    }
  };

  const changeHandler = (e) => {
    let username = e.target.value;
    setUsername(username);
  };

  const changeToLowerCase = (e) => {
    let username = e.target.value;
    username = username.toLowerCase();
    setUsername(username);
  };

  const handleUpdateUsername = () => {
    if (!editUsername) {
      toastr.error("Username can not be empty");
      return;
    }
    let finalValues = {};
    finalValues.username = editUsername;
    finalValues.account = authUser.email;
    finalValues.access_token = authUser.token_detail.token;
    if (authUser && finalValues.username !== authUser.username) {
      changeUsername(finalValues)
        .then((response) => {
          setAuthUser(response.data.data);
          finalValues.username = finalValues.username.toLowerCase();
          toastr.success("User Name Updated Successfully");
          props.history.push("/");
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toastr.error("That username is taken. Try another");
          } else {
            toastr.error(error.response.data.message);
          }
        });
    } else {
      props.history.push("/");
    }
  };
  const changeUserName = () => {
    setFlag(true);
  };

  return (
    <Box components="div" className={classes.header_bg + " editBox"}>
      <Box components="div" className="herobg-black"> </Box>
    <div className="editUser">
      {flag && flag === true ? (
        <div
          className={classes.paper}
        >
          <h2 style={{ font: "600 16px Roboto", textAlign: "center" }}>
            Change User Name
          </h2>
          <TextField
            variant="filled"
            placeholder="edit username"
            name="editUsername"
            className="input-field edit-username"
            style={{ background: "#fff !important" }}
            value={editUsername}
            onChange={changeHandler}
            onKeyUp={changeToLowerCase}
          />
          <br />
          <br />
          <Button
           color="primary"
           variant="contained"
            onClick={handleUpdateUsername}
          >
            Change User Name
          </Button>
        </div>
      ) : (
        <div
          className={classes.paper}
        >
          <h2 style={{ font: "600 16px Roboto", textAlign: "center" }}>
            Welcome to Zenrth!
          </h2>
          <br />
          <h4 style={{ font: "600 16px Roboto", textAlign: "center" }}>
            {editUsername}
          </h4>
          <br />
          <Button
              color="primary"
              variant="contained"
            onClick={handleEditNext}
          >
            Next
          </Button>
          <br />
          <Button
            style={{
              background: "transparent",
              color: "#1744D6",
              font: "500 14px Roboto",
              margin: "10px 0 0 0",
              border: "none",
              textTransform: "capitalize",
            }}
            onClick={changeUserName}
          >
            Change Username
          </Button>
        </div>
      )}
    </div>
    </Box>
  );
}
