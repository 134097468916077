import axios from "axios";

export const login = (values) => {
  const finalValues = values;
  finalValues.dontSendToken = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/login", finalValues)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signup = (values) => {
  const finalValues = values;
  finalValues.dontSendToken = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/register", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendOTP = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/resendotp", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProfile = (userId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeUsername = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/change/username`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const authorize = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/oautherize", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "Authentication failed." + error.message });
      });
  });
};

export const forgotPassword = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/forgotpassword", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const changePassword = (value) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/changepassword", value)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "/changepassword Failed. " + error.message });
      });
  });
};

export const validateOtp = (otpDetails) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/verifyotp", otpDetails)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserLocaleInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_USER_LOCALE_INFO_URL)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getUserLocaleInfo Failed. " + error.message });
      });
  });
};

// User Or Builder
export const getUserOrBuilderName = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/userOrBuilderName/${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message:
            "User or Builder data could not be obtained. " + error.message,
        });
      });
  });
};

export const getAllProperties = (
  limit = 10,
  offset = 0,
  userId,
  status = "0,1,3,-1,-2,-3,-4,-5",
  city,
  fav,
  contacted,
  queryStr = ""
) => {
  return new Promise((resolve, reject) => {
    let url = `/property?limit=${limit}&offset=${offset}`;
    if (userId) {
      url = url + `&userId=${userId}`;
    }
    if (status) {
      url = url + `&status=${status}`;
    }
    if (city) {
      url = url + `&city=${city}`;
    }
    if (fav) {
      url = url + `&isFavorite=${fav}`;
    }
    if (contacted) {
      url = url + `&isContacted=${contacted}`;
    }
    url += `&${queryStr}`;

    axios
      .get(url, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Get All Properties Failed. " + error.message });
      });
  });
};
export const getProperty = (propertyId, userId) => {
  return new Promise((resolve, reject) => {
    let url = `/property/${propertyId}`;
    if (userId) {
      url = url + `?userId=${userId}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Get Property Failed. " + error.message });
      });
  });
};
export const getBuyers = (id, userId) => {
  return new Promise((resolve, reject) => {
    let url = `/property/${id}?userId=${userId}`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Get Buyers Failed. " + error.message });
      });
  });
};
export const getAmenities = () => {
  return new Promise((resolve, reject) => {
    let url = `/property/amenities`;
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Get Amenities Failed. " + error.message });
      });
  });
};
export const getFavoriteProperties = (limit = 10, offset = 0, userId) => {
  return new Promise((resolve, reject) => {
    let url = `/property?limit=${limit}&offset=${offset}`;
    if (userId) {
      url =
        url +
        `&myFavorites=${userId}` +
        `&isContacted=${userId}` +
        `&isFavorite=${userId}`;
    }

    axios
      .get(url, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Get Favorite Properties Failed. " + error.message });
      });
  });
};
export const getContactedProperties = (limit = 10, offset = 0, userId) => {
  return new Promise((resolve, reject) => {
    let url = `/property?limit=${limit}&offset=${offset}`;
    if (userId) {
      url = url + `&myContacted=${userId}` + `&isFavorite=${userId}`;
    }

    axios
      .get(url, {
        dontShowLoader: true,
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "Get Favorite Properties Failed. " + error.message });
      });
  });
};

export const getUserProperties = (
  limit = 10,
  offset = 0,
  userId,
  status,
  keyword
) => {
  return new Promise((resolve, reject) => {
    let url;
    url = `/property?limit=${limit}&offset=${offset}`;

    if (userId) {
      url = url + `&userId=${userId}`;
    }

    if (status) {
      url = url + `&status=${status}`;
    }
    if (keyword) {
      url = url + `&keyword=${keyword}`;
    }

    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "getUserProperties Failed. " + error.message });
      });
  });
};

export const getDonationRequestComments = (donationRequestId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/donation/request/comment?requestId=${donationRequestId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Could not fetch comments. " + error.message });
      });
  });
};

export const createDonationRequestComment = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/donation/request/comment", values)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "Comment creation failed. " + error.message });
      });
  });
};

export const createProperty = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/property", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateProperty = (values, propertyId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/property/${propertyId}`, values)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteProperty = (propertyId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/property/${propertyId}?user_id=${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "delete property Failed. " + error.message });
      });
  });
};
export const subscribeNewsLetter = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/newsletter/subscribe?email=${email}`, {
        params: { dontShowLoader: true },
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const unsubscribeNewsLetter = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/newsletter/unsubscribe?email=${email}`, {
        params: { dontShowLoader: true },
      })
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const createNotification = (propertyId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/property/${propertyId}/contactSeller`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteComment = (requestId, userId, commentId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/delete/request/comment`, {
        data: { requestId: requestId, userId: userId, commentId: commentId },
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "delete comment Failed. " + error.message });
      });
  });
};

export const editComment = (comment, userId, commentId) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/edit/request/comment`, {
        comment: comment,
        userId: userId,
        commentId: commentId,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "edit comment Failed. " + error.message });
      });
  });
};
export const validateUser = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/searchuser", email)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "/searchuser Failed. " + error.message });
      });
  });
};

export const getUserData = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}?timestamp=${new Date().getTime()}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "User data could not be obtained. " + error.message,
        });
      });
  });
};

export const getUserDataWithUsername = (username) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/profile/${username}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "User data could not be obtained. " + error.message,
        });
      });
  });
};

export const favourite = (obj) => {
  obj.dontShowLoader = true;
  return new Promise((resolve, reject) => {
    axios
      .post("/property/request/favourite", obj)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getVolunteers = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/volunteerAdmin?user_id=${userId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({
          message: "TALScout list could not be obtained. " + error.message,
        });
      });
  });
};

export const getUserSummary = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/summary/${userId}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject({
          message: "Failed to get user summary data. " + error.message,
        });
      });
  });
};

export const changeNumber = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/change/number", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const changeCurrentPassword = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/change/user/password", values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const accountInformation = (values, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${userId}/payment/account`, values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject({
          message: "Failed. " + error.message,
        });
      });
  });
};

export const getAccountInfo = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/payment/account`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const updateAccountInformation = (accountId, userId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}/payment/account/${accountId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getRegions = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/region")
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error.message,
        });
      });
  });
};

export const getCategories = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/category")
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getAllCountries = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/country`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getAllStates = (countryId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/state?countryId=${countryId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const getAllCities = (stateId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/city?stateId=${stateId}`)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const postFeedback = (content) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/notifications/email", content)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const checkImage = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/obscene/image", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({
          message: error,
        });
      });
  });
};

export const manageAccountInfo = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/disband/user", values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getUserNotifications = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/notifications?userId=${userId}`, {
        params: { dontShowLoader: true },
      })
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/currentTime`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject({ message: "get currentTime Failed. " + error.message });
      });
  });
};

export const updateNotification = (userid, notificationid) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/notifications/${notificationid}`, {
        status: 1,
        userId: userid,
        dontShowLoader: true,
        // notificationIds: [notificationid]
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const deleteUserNotification = (id, userid) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/notifications/${id}`, {
        data: { userId: userid },
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const removeUser = (orgId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/builder/${orgId}/user/${userId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "Delete user Failed. " + error.message });
      });
  });
};

export const changeUserRole = (orgId, userId, values) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/builder/${orgId}/user/${userId}`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeMobileNumber = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/change/number`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addBuilder = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/builder", values)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllBuilders = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/builder")
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBuilderStatus = (builderId, obj) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/builder/${builderId}`, obj)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBuilderList = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/builder/user/${userId}`, {
        params: { dontShowLoader: false },
      })
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBuilderdetails = (builderId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/builder/${builderId}`)
      .then((result) => {
        if (result) {
          resolve(result.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteBuilder = (builderId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/builder/${builderId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject({ message: "Delete Builder Failed. " + error.message });
      });
  });
};

export const VerifyBuilderEmailMessage = (builderId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/builder/verify/${builderId}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addBuilderUserWithToken = (bodyParameters, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/builder/${bodyParameters.setBuilderId}/user`,
        bodyParameters,
        config
      )
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateUserUsingToken = (userId, payload, config) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/user/${userId}`, payload, config)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const inviteMember = (builderId, obj) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/builder/${builderId}/inviteUser`, obj)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBuilderProperties = (
  builderId,
  status,
  fav,
  contacted,
  limit = 10,
  offset = 0
) => {
  return new Promise((resolve, reject) => {
    let url;
    if (status) {
      url = `/property?builderId=${builderId}&status=${status}`;
    } else {
      url = `/property?builderId=${builderId}`;
    }
    if (fav) {
      url = url + `&isFavorite=${fav}`;
    }
    if (contacted) {
      url = url + `&isContacted=${contacted}`;
    }
    axios
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result.data);
        }
      })
      .catch((error) => {
        reject({ message: "get  Builder Properties failed. " + error.message });
      });
  });
};
