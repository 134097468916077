import React from "react";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  star: {
    position: "relative",

    "& img": {
      position: "absolute",
      top: "-100px",
    },
  },

  cardmain: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      "& h5": {
        [theme.breakpoints.down("sm")]: {
          margin: "5px 0",
        },
      },
      "& img": {
        width: "30px",
        margin: "5px 0",
      },
    },
  },
  sub_count: {
    "& p": {
      color: "#585858",
      fontWeight: "normal",
      fontSize: "18px",
    },

    fontWeight: "700",
    display: "flex",
    flexDirection: "column",
    width: "65%",
    minHeight: "160px",
    margin: "0 auto",

    [theme.breakpoints.down("sm")]: {
      width: "80%",
      minHeight: "auto",
    },

    [theme.breakpoints.down("xs")]: {
      width: "80%",
      minHeight: "auto",
    },
  },
}));

export default function Healthya() {
  const classes = useStyles();
  return (
    <Box component="div" bgcolor="#F3F3FA" minHeight="700px">
      <Container>
        <Box
          component="h1"
          textAlign="center"
          fontSize="calc(18px + 1.5vw)"
          textTransform="uppercase"
          // px={{ xs: 0, sm: 0, md: 40 }}
          pt={6}
          pb={4}
        >
          Creating real value in property and places
        </Box>
        <Box component="div" className={classes.Healthya}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                component="span"
                className={classes.sub_count}
                px={{ xs: 0, sm: 0, md: 28 }}
              >
                <Box component="div" className={classes.cardmain}>
                  <img src="/images/healthy_icon.svg" alt="Chat" />
                  <Box component="h5" ml={1} alignSelf="center">
                    Nature
                  </Box>
                </Box>
                <Box component="p" textAlign="center">
                  Beautiful and peaceful like none!{" "}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Box component="span" className={classes.sub_count}>
                <Box component="div" className={classes.cardmain}>
                  <img src="/images/ref.svg" alt="Chat" />
                  <Box component="h5" ml={1} alignSelf="center">
                    Rising Energy of Fire
                  </Box>
                </Box>
                <Box component="p" textAlign="center">
                  Unleash the power of efficiency{" "}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Hidden smDown>
                <Box component="div" className={classes.star}>
                  <img
                    src="/images/star_img.png"
                    alt="star"
                    width="100%"
                    className={classes.star}
                  />
                </Box>
              </Hidden>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Box component="span" className={classes.sub_count}>
                <Box component="div" className={classes.cardmain}>
                  <img src="/images/nature.svg" alt="Chat" />
                  <Box component="h5" ml={1} alignSelf="center">
                    Fluidity of Water
                  </Box>
                </Box>
                <Box component="p" textAlign="center">
                  The Altar of Style{" "}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Box component="span" className={classes.sub_count}>
                <Box component="div" className={classes.cardmain}>
                  <img src="/images/beauty&earth.svg" alt="Chat" />
                  <Box component="h5" ml={1} alignSelf="center">
                    Beauty & Earth
                  </Box>
                </Box>
                <Box component="p" textAlign="center">
                  Inspired by Innovation{" "}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Box component="span" className={classes.sub_count}>
                <Box component="div" className={classes.cardmain}>
                  <img src="/images/wind.svg" alt="Chat" />
                  <Box component="h5" ml={1} alignSelf="center">
                    Wind
                  </Box>
                </Box>
                <Box component="p" textAlign="center">
                  Affordably as 100% free{" "}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
