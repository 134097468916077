const ValidateConfiguration = (values) => {
  let errors = {};
  if (values.propertyType === "Property Type")
    errors.propertyType = "Please select Property Type";
  if (values.name.length === 0)
    errors.name = "Please enter a heading for this configuration";
  if (
    values.propertyType !== "Residential Land" &&
    values.furnishedStatus === "Furnished Status"
  )
    errors.furnishedStatus = "Please select furnished status";
  if (
    values.propertyType !== "Residential Land" &&
    values.builtupAreaNumber.length === 0
  )
    errors.builtupAreaNumber = "Please enter a value";
  if (
    values.propertyType !== "Residential Land" &&
    values.builtupAreaUnit === "Units"
  )
    errors.builtupAreaUnit = "Please select one";
  if (
    values.propertyType !== "Residential Land" &&
    values.carpetAreaNumber.length === 0
  )
    errors.carpetAreaNumber = "Please enter a value";
  if (
    values.propertyType !== "Residential Land" &&
    values.carpetAreaUnit === "Units"
  )
    errors.carpetAreaUnit = "Please select one";
  if (
    values.propertyType === "Residential Land" &&
    values.landAreaNumber.length === 0
  )
    errors.landAreaNumber = "Please enter a value";
  if (
    values.propertyType === "Residential Land" &&
    values.landAreaUnit === "Units"
  )
    errors.landAreaUnit = "Please select one";
  if (values.selectedMonth === "Month")
    errors.selectedMonth = "Please select a month";
  if (parseInt(values.registrationFees) < 0) {
    errors.registrationFees = "Please enter registration fees greater than 0";
  }
  if (parseInt(values.legalDocumentationFees) < 0) {
    errors.legalDocumentationFees =
      "Please enter legalDocumentation Fees greater than 0";
  }
  if (parseInt(values.stampDutyCharges) < 0) {
    errors.stampDutyCharges = "Please enter stampDutyCharges greater than 0";
  }
  if (parseInt(values.gstCharges) < 0) {
    errors.gstCharges = "Please enter gstCharges greater than 0";
  }
  if (parseInt(values.processingFees) < 0) {
    errors.processingFees = "Please enter processingFees greater than 0";
  }
  if (parseInt(values.corpusFund) < 0) {
    errors.corpusFund = "Please enter corpusFund greater than 0";
  }
  if (parseInt(values.monthlyMaintenanceCharges) < 0) {
    errors.monthlyMaintenanceCharges =
      "Please enter monthlyMaintenanceCharges greater than 0";
  }
  if (values.notes.length === 0) errors.notes = "Please enter Notes";
  return errors;
};

export default ValidateConfiguration;
