import React, { useContext } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { appContext } from "./App";

function ProtectedRoute({ component: Component, ...rest }) {
  const authUser = localStorage.getItem("authUser");
  const { setOpenLogin } = useContext(appContext);
  if (authUser && authUser.unique_id !== null) {
    setOpenLogin(false);
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    //toastr.warning("Please signup or login to continue");
    setOpenLogin(true);
    return <></>;
  }
}
export default withRouter(ProtectedRoute);
