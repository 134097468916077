import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/core/styles";
import { Fade, Backdrop, Button, Modal } from "@material-ui/core";
import { validateOtp, resendOTP } from "../../utils/api";
import { appContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalTitle: {
    font: "600 20px Roboto",
    margin: "15px 0",
  },
  modalDesc: {
    font: "400 16px Roboto",
  },

  otpCont: {
    "& div": {
      justifyContent: "center",
      "& input": {
        width: "2.5em !important",
        height: "2.5em",
      },
    },
  },
  resendOtp: {
    float: "center",
    color: "#d32f2f",
    cursor: "pointer",
    margin:"12px"
  },
  saveBtn: {
    background: "var(--tal_primary) !important",
    color: "#fff!important",
    width: "40% !important",
    padding: "15px 20px",
    margin: "30px 0",
    display: "inline-block",
  },
}));

const ValidatePhone = (props) => {
  const classes = useStyles();
  const { setAuthUser } = useContext(appContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [otpValues, setOtpValues] = useState("");

  const handleOtpCode = (e) => {
    setOtpValues(e);
    if (e.length === 6) {
      postVerificationDetails(e);
    }
  };
  const postVerificationDetails = (otp) => {
    const otpDetails = {};
    otpDetails.otp = otp;
    otpDetails.email = props.userId;
    otpDetails.phone = props.phoneNumber;
    otpDetails.type = "change number";
    validateOtp(otpDetails)
      .then((response) => {
        if (response && response.statusCode === 200) {
          toastr.success(response.message);
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              phone_verified: true,
            };
          });
          setShowSuccess(true);
          setOtpValues("");
          props.setPhoneVerified(true);
          props.onCloseModal();
        }
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  };

  const resendOtp = () => {
    const obj = {};
    obj.email = props.phoneNumber;
    obj.type = "change number";
    resendOTP(obj)
      .then((response) => {
        toastr.success(response.data.message);
      })
      .catch((error) => {
        toastr.error(error.response.message);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openVerifyPhoneModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={props.openVerifyPhoneModal}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className={classes.modalTitle}>
              Verify Phone Number
            </h2>
            {props.fromUserProfile !== true && showSuccess ? (
              <div>
                <p
                  id="transition-modal-description"
                  className={classes.modalDesc}
                >
                  Mobile number verification has been successful!
                </p>
                <div>
                  <Button className={classes.saveBtn}>Continue</Button>
                </div>
              </div>
            ) : (
              <div>
                <p
                  id="transition-modal-description"
                  className={classes.modalDesc}
                >
                  Please enter the OTP that has been sent to your mobile number
                </p>
                <div className={classes.otpCont}>
                  <OtpInput
                    onChange={(e) => handleOtpCode(e)}
                    numInputs={6}
                    value={otpValues}
                    separator={<span> &nbsp; &nbsp; </span>}
                    shouldAutoFocus={true}
                  />
                </div>
                <p className={classes.resendOtp} onClick={() => resendOtp()}>
                  Resend OTP
                </p>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default withRouter(ValidatePhone);
