import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Header from "../header/Header";
import About from "../about/About";
import Download from "../download/Download";
import Help from "../help/Help";
import Healthya from "../healthya/Healthya";
import PropertyCard from "../PropertyCard/index";
import { withRouter } from "react-router-dom";
import { appContext } from "../../App";

const Home2 = () => {
  const { rupee } = useContext(appContext);

  return (
    <Box>
      <Box>
        <Header />
      </Box>

      <Box>
        <About />
      </Box>

      <Box>
        <Healthya />
      </Box>

      <Box>
        <PropertyCard rupee={rupee} />
      </Box>

      <Box>
        <Download />
      </Box>

      <Box>
        <Help />
      </Box>
    </Box>
  );
};
export default withRouter(Home2);
