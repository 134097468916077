import { Button, Typography } from "@material-ui/core";
import { CheckCircleOutlineOutlined } from "@material-ui/icons";
import React from "react";
import { withRouter } from "react-router";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

function PropertyListingCard(props) {
  const handleListProperty = () => {
    props.history.push(`/sell-property`);
  };
  return (
    <Box component="div" mb={4}>
    <Paper>
      <Box p={2} >
        <img src="/images/building_icon.svg" alt="building icon" />
        <Box component="h5" py={2} fontWeight="bold">
          Add your property for Free!
        </Box>
        <Box component="p" pt={1}>
          <Box component="span" pr={1}>
            <CheckCircleOutlineOutlined />
          </Box>
          <Box component="span">Access to thousands of buyer</Box>
        </Box>
        <Box component="p" pt={1}>
          <Box component="span" pr={1}>
            <CheckCircleOutlineOutlined />
          </Box>
          <Box component="span"> Verified &amp; matching buyers</Box>
        </Box>
        <Box component="p" pt={1}>
          <Box component="span" pr={1}>
            <Box component="span">
              {" "}
              <CheckCircleOutlineOutlined />
            </Box>
          </Box>
          Zero brokerage fees
        </Box>
        <Button className="red_bg"
          variant="contained"
          color="primary"
          onClick={handleListProperty}
        >
          List your property
        </Button>
      </Box>
    </Paper>
    </Box>
  );
}
export default withRouter(PropertyListingCard);
