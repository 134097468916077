import React, { useEffect, useContext } from "react";
import Loader from "../common/Loader";
import { Grid } from "@material-ui/core";
import toastr from "toastr";
import { VerifyBuilderEmailMessage } from "../../utils/api";
import Container from "@material-ui/core/Container";

const VerifyBuilderEmail = (props) => {
  useEffect(() => {
    const builderId = props.match.params.builderId;
    const userId = props.match.params.userId;

    if (builderId && userId) {
      VerifyBuilderEmailMessage(builderId)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            props.history.push("/");
          }
        })
        .catch((error) => {
          toastr.error(
            error.response && error.response.data && error.response.data.message
          );
          props.history.push("/");
        });
    } else {
      toastr.warning("Error. Invalid verification link");
      props.history.push("/login");
    }
  }, []);

  return (
    <div className="myDonations-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={9}>
            <Loader isOpen={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default VerifyBuilderEmail;
