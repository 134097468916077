import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import BuilderForm from "./BuilderForm";
import { appContext } from "../../App";
import { withRouter } from "react-router-dom";

const Builders = (props) => {
  const [openBuilderModal, setOpenBuilderModal] = useState(false);
  const { authUser, builderList } = useContext(appContext);
  const onSavingBuilder = (builderId) => {
    setOpenBuilderModal(false);
    // props.history.push(`/builder/${builderId}`);
  };

  const handleBuilderDetails = (builderData) => {
    console.log(builderData.builderId._id);
    if (builderData.builderId) {
      window.open(`/builder/${builderData.builderId._id}`, "blank");
    }
  };
  const handleBuilder = () => {
    setOpenBuilderModal(true);
  };
  return (
    <div className="side-Organization admin-block drop-box">
      <Container>
        <Box bgcolor="white" my={2}>
          <Box component="section" pt={2} px={3}>
            <Typography variant="h5" component="h5">
              Builders
              <Button className="add">
                <Tooltip title="Add A Builder">
                  <AddIcon onClick={handleBuilder} />
                </Tooltip>
              </Button>
            </Typography>

            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}
            >
              {builderList && builderList.length === 0 && (
                <div
                  style={{
                    fontSize: "12px",
                    padding: "10px 0",
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                >
                  No Builders
                </div>
              )}
              {builderList &&
                builderList.map((list, index) => (
                  <Tooltip
                    title={list.builderId && list.builderId.builderName}
                    key={index}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        margin: "3px 3px 5px 2px",
                        // width: " 31%",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 0 7px #f5f7ff",
                      }}
                    >
                      <figure style={{ margin: "0 !important" }}>
                        <img
                          src={
                            list.builderId && list.builderId.defaultImageUrl
                              ? list.builderId.defaultImageUrl
                              : "/images/orgLogo.png"
                          }
                          onClick={() => handleBuilderDetails(list)}
                          alt="org-logo"
                          style={{
                            cursor: "pointer",
                            maxWidth: "100%",
                            maxHeight: "100px",
                            padding: "5px",
                          }}
                        />
                      </figure>
                    </div>
                  </Tooltip>
                ))}
              <BuilderForm
                openBuilderModal={openBuilderModal}
                onCloseModal={(e) => setOpenBuilderModal(false)}
                onSavingBuilder={onSavingBuilder}
              />
            </div>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default withRouter(Builders);
