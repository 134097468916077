import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  aboute: {
    textAlign: "center",
    backgroundColor: "white",
    "& h1": {
      color: "24px",
      color: "black",
      fontSize: "calc(18px + 1.5vw)",
    },
    "& a ": {
      // color:"#000",
      textDecoration:"none",
      color: "#ec1c24",
      
    },   

    "& h5": {
      color: "#ec1c24",
      fontWeight: "400",
    },

    " & p": {
      color: "#4F4F4F",
      fontWeight: "500",
      fontSize: "calc(16px + 0.2em)",
    },

    "& Button": {
      fontSize: "16px",
      borderColor: "#ec1c24",
    },
  },
}));

export default function About() {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.aboute}>
      <Container>
        <Box
          component="div"
          px={{ xs: 2, sm: 4, md: 24 }}
          py={{ xs: 2, sm: 4, md: 12 }}
        >
          <h5>ABOUT</h5>
          <Box component="h1" px={{ xs: 2, sm: 4, md: 8 }}>
            Your Search For Your Dream Home Ends Here
          </Box>
          <Box component="p" px={{ xs: 2, sm: 4, md: 8 }}>
            {" "}
            We are the fastest growing company in the online real estate space.
            Born out of the need to simplify the search for a home, free of fake
            listings and endless site visits, we created a unique property
            search platform that filled the gaps left by others in the market.
            Led by passionate problem-solvers, we are poised to become the most
            trusted place to find a home in India.
          </Box>
          <Button
            variant="outlined">           
            
               <a  href="/aboutus">  Learn More</a> 
          
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
