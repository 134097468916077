import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import toastr from "toastr";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Avatar from "@material-ui/core/Avatar";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { appContext } from "../../App";

import "./style.scss";
// import "./newUserProfile.scss";

import {
  updateProfile,
  getUserData,
  changeCurrentPassword,
  manageAccountInfo,
} from "../../utils/api";
import useFormValidation from "../../hooks/useFormValidation";
import ValidateProfile from "./ValidateProfile";
import ValidatePassword from "./ValidatePassword";
import NewAddress from "../common/NewAddress";
import { isValidPassword } from "../../utils/utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { HOME_PAGE } from "../../utils/utils";

import Radio from "@material-ui/core/Radio";
import PhoneNumber from "../Phone/PhoneNumber";
import { withRouter } from "react-router-dom";
import PropertyListingCard from "../NewRequest/PropertyListingCard";
import { FormHelperText } from "@material-ui/core";
import AddPhotos from "../common/AddPhotos";
const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "start",
    color: theme.palette.text.secondary,
  },

  AddPhoto: {
    right: "12px",
    bottom: "0",
    cursor: "pointer",
    position: "absolute",
    background: "white",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0 2px 5px #202020",
    fontSize: "28px",

    [theme.breakpoints.down("sm")]: {
      right: "45%",
    },
    [theme.breakpoints.down("xs")]: {
      right: "40%",
    },
  },
}));

const NewUserProfile = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [uniqueId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    "/images/default-profile-photo.png"
  );
  const initialState = {
    userName: "",
    firstName: "",
    lastName: "",
    summary: "",
  };

  const intialPasswordDetails = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const intialBankDetails = {
    label: "",
    accountNumber: null,
    routingNumber: null,
    isDefault: true,
  };

  const [showPassword, setShowPassword] = useState(true);
  const [userDetails, setUserDetails] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(moment());
  const [phone, setPhone] = useState("");
  const [mySummary, setMySummary] = useState([]);
  const [volunteerApplicationStatus, setVolunteerApplicationStatus] =
    useState(10);

  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);

  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [otherErrors, setOtherErrors] = useState({});
  const [changePasswordError, setChangePasswordError] = useState("");

  const [type, setType] = useState("");
  const [passwordForAccMan, setPasswordForAccMan] = useState("");
  const [errorPasswordMessage, setErrorPasswordMessage] = useState("");

  const handleAccMangementChange = (event) => {
    setType(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleDateofBirthChange = (date) => {
    setDob(date);
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const onConfirm = () => {
    updateUserProfile();
  };

  const onPasswordConfirm = () => {
    submitHandlerChangePassword();
  };

  const phoneRef = useRef();
  const updateUserProfile = () => {
    const finalValues = {};
    finalValues.email = values.email;
    //finalValues.username = values.userName;
    finalValues.name = {
      first_name: values.firstName,
      last_name: values.lastName,
    };
    //validate the phone input
    if (!phoneRef.current.isValid(phone)) {
      return;
    }
    finalValues.summary = values.summary;
    finalValues.title = values.title;
    finalValues.aboutMe = values.aboutMe;
    finalValues.facebookProfileUrl = values.facebookProfileUrl;
    finalValues.instagramProfileUrl = values.instagramProfileUrl;
    finalValues.twitterProfileUrl = values.twitterProfileUrl;
    finalValues.linkedInProfileUrl = values.linkedInProfileUrl;
    dob && (finalValues.dob = new Date(dob).getTime());
    if (gender === "m" || gender === "f" || gender === "o") {
      finalValues.gender = gender;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          gender: "",
        };
      });
    } else {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          gender: "Please Select gender.",
        };
      });
      return;
    }

    if (
      tempAddress &&
      (tempAddress.line1 ||
        tempAddress.line2 ||
        tempAddress.city ||
        tempAddress.state ||
        tempAddress.country ||
        tempAddress.zipcode)
    ) {
      const newAddress = {};
      newAddress.line1 = tempAddress.line1;
      newAddress.line2 = tempAddress.line2;
      newAddress.city = tempAddress.city;
      newAddress.state = tempAddress.state;
      newAddress.country = tempAddress.country;
      newAddress.zipcode = tempAddress.zipcode;
      finalValues.address = newAddress;
    }
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        setAuthUser(response.data.data);
        localStorage.setItem("authUser", JSON.stringify(response.data.data));
        toastr.success("User Profile Updated Successfully");
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            toastr.error(error.response.data.errors[0].msg);
          } else {
            toastr.error(error.response.message);
          }
        } else {
          toastr.error(error);
        }
      });
  };

  const updateDefaultImageUrl = (url) => {
    const finalValues = {
      image_url: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setProfileImageUrl(url);
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              profile_image_url: url,
            };
          });
          localStorage.setItem(
            "authUser",
            JSON.stringify({
              ...authUser,
              profile_image_url: url,
            })
          );

          return true;
        } else {
          toastr.error(response.data.message);
          return false;
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        return false;
      });
  };

  const changeProfileImageClickHandler = () => {
    setShowingAddPhotoModal(true);
  };

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setUserDetails(userData);
        setValues((currentValues) => {
          return {
            ...currentValues,
            userName: userData.username && userData.username,
            firstName: userData.name.first_name,
            lastName: userData.name.last_name,
            summary: userData.summary,
            email: userData.email,
            title: userData.title,
            aboutMe: userData.aboutMe,
            gender: userData.gender,
            facebookProfileUrl:
              userData.facebookProfileUrl === null
                ? ""
                : userData.facebookProfileUrl,
            instagramProfileUrl:
              userData.instagramProfileUrl === null
                ? ""
                : userData.instagramProfileUrl,
            twitterProfileUrl:
              userData.twitterProfileUrll === null
                ? ""
                : userData.twitterProfileUrl,
            linkedInProfileUrl:
              userData.linkedInProfileUrl === null
                ? ""
                : userData.linkedInProfileUrl,
            dob: userData.dob ? moment(userData.dob).format("DD-MM-YYYY") : "",
            experienceSummary: userData.volunteerInfo
              ? userData.volunteerInfo.experienceSummary
              : "",
            hoursAvailablePerWeek: userData.volunteerInfo
              ? userData.volunteerInfo.hoursAvailablePerWeek
              : 0,
            whyWorkWithUs: userData.volunteerInfo
              ? userData.volunteerInfo.whyWorkWithUs
              : "",
          };
        });
        if (userData.address) {
          setAddress({
            line1: userData.address.hasOwnProperty("line1")
              ? userData.address.line1
              : "",
            line2: userData.address.hasOwnProperty("line2")
              ? userData.address.line2
              : "",
            city: userData.address.hasOwnProperty("city")
              ? userData.address.city
              : "",
            state: userData.address.hasOwnProperty("state")
              ? userData.address.state
              : "",
            zipcode: userData.address.hasOwnProperty("zipcode")
              ? userData.address.zipcode
              : "",
            country: userData.address.hasOwnProperty("country")
              ? userData.address.country
              : "",
          });
        }
        setDob(moment(userData.dob));
        if (userData.profile_image_url) {
          setProfileImageUrl(userData.profile_image_url);
        }
        setGender(userData.gender);
        setVolunteerApplicationStatus(userData.volunteerApplicationStatus);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    if (authUser) {
      setEditMode(true);
      loadUserData(authUser.unique_id);
    }
  }, [authUser, profileImageUrl]);

  // useEffect(() => {
  //   handleUserSummary();
  // }, [authUser, userSummary]);

  // const handleUserSummary = () => {
  //   if (authUser) {
  //     getUserSummary(authUser.unique_id).then((response) => {
  //       setMySummary(response);
  //     });
  //   }
  // };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const submitHandlerChangePassword = () => {
    const obj = {};
    const validPasswordMessage = isValidPassword(passwordValues.newPassword);

    if (validPasswordMessage) {
      setPasswordErrors((currentValues) => {
        return {
          ...currentValues,
          newPassword: validPasswordMessage,
        };
      });
    } else if (authUser && authUser.unique_id) {
      obj.currentPassword = passwordValues.currentPassword;
      obj.newPassword = passwordValues.newPassword;
      obj.accessToken = authUser.token_detail.token;
      obj.userId = authUser.unique_id;
      changeCurrentPassword(obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode === 401) {
            toastr.error(error.response.data.message);
          }
        });
    } else {
      setChangePasswordError("Password updated failed. User auth error.");
    }
  };

  const submitAccountManagement = () => {
    const obj = {};
    let isValid = false;
    if (passwordForAccMan === "") {
      setErrorPasswordMessage("Please enter current Password");
    } else {
      obj.password = passwordForAccMan;
      isValid = true;
    }
    obj.type = type;
    obj.userId = authUser && authUser.unique_id;
    if (
      isValid &&
      window.confirm(
        `Are you sure you want to ${
          type === "deactivate"
            ? "Deactivate your account"
            : type === "delete"
            ? "Delete your account"
            : ""
        }`
      )
    ) {
      manageAccountInfo(obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            setAuthUser(null);
            localStorage.removeItem("authUser");
            window.location = HOME_PAGE;
          }
        })
        .catch((error) => {
          if (error) {
            if (error.data.statusCode === 401) {
              toastr.error(
                "Requested action could not be performed. You are being logged out because you entered a wrong password."
              );
            }
          }
        });
    }
  };
  let displayStyle = "";
  if (authUser && !authUser.password_verified) {
    displayStyle = "none";
  }
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, ValidateProfile, onConfirm);

  const {
    values: passwordValues,
    errors: passwordErrors,
    setErrors: setPasswordErrors,
    changeHandler: passwordChangeHandler,
    submitHandler: passwordSubmitHandler,
  } = useFormValidation(
    intialPasswordDetails,
    ValidatePassword,
    onPasswordConfirm
  );
  return (
    <Container maxWidth="lg" className="profileinfo">
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={9}>
            <Paper>
              <Grid container>
                <Grid item xs={12} sm={12} md={2} spacing={2}>
                  <Box elevation={0} component="div" py={2}>
                    <Box
                      component="div"
                      whiteSpace="nowrap"
                      fontWeight="bold"
                      fontSize="18px"
                      mb={2}
                      textAlign="center"
                    >
                      Profile Info
                    </Box>
                    <Box
                      component="div"
                      position="relative"
                      lineeHeight="55px"
                      display="flex"
                      justifyContent="center"
                    >
                      <Avatar
                        alt="Profile Photo"
                        src={profileImageUrl}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(`/user/${authUser.username}`, "blank")
                        }
                      />
                      {editMode &&
                        authUser &&
                        authUser.login_provider === "local" && (
                          <Fragment>
                            <CameraAltIcon
                              className={classes.AddPhoto}
                              onClick={changeProfileImageClickHandler}
                            />
                            <AddPhotos
                              title="Manage Profile Photo"
                              isOpen={showingAddPhotoModal}
                              onClose={onCloseAddPhoto}
                              uid={authUser && authUser.unique_id}
                              collection="ProfilePictures"
                              profilephotoURL={profileImageUrl}
                              onProfilephotoURLChange={updateDefaultImageUrl}
                              canEdit={true}
                            />
                          </Fragment>
                        )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={10}>
                  <Box
                    p={2}
                    className={`${classes.paper} userprofileFormBlk`}
                    elevation={0}
                  >
                    <Box component="h5" fontWeight="bold" my={1}>
                      {values.firstName + " " + values.lastName}
                    </Box>

                    <Box componentp="div">
                      <Box
                        className={`${classes.paper} prfDetails`}
                        elevation={0}
                        mb={2}
                      >
                        <Typography variant="subtitle1" component="subtitle1">
                          {address &&
                            (address.line1 && address.line1 + " ") +
                              (address.line2 && address.line2 + ", ") +
                              (address.city && address.city + " ") +
                              (address.state && address.state + " ") +
                              (address.zipcode && address.zipcode + " ") +
                              (address.country && address.country)}
                        </Typography>
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Box>
                            <Box component="h5" color="black" pb={1}>
                              About
                            </Box>
                            <Box>
                              <Divider />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel>
                            First Name <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            className="profile-fields fname"
                            variant="outlined"
                            fullWidth
                            name="firstName"
                            disabled={!editMode}
                            value={values.firstName}
                            onChange={changeHandler}
                          />
                          {errors && errors.firstName && (
                            <Typography className="custom-error">
                              {errors.firstName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel>
                            Last Name <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%" }}
                            name="lastName"
                            disabled={!editMode}
                            value={values.lastName}
                            onChange={changeHandler}
                          />
                          {errors && errors.lastName && (
                            <Typography>{errors.lastName}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <InputLabel>Title</InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields title"
                            style={{ width: "100%" }}
                            name="title"
                            disabled={!editMode}
                            value={values.title}
                            onChange={changeHandler}
                            placeholder="Give your title. (Ex: I am a Photographer)"
                          />
                          {errors && errors.title && (
                            <Typography>{errors.title}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <InputLabel>Short Summary</InputLabel>
                          <TextField
                            type="text"
                            disabled={!editMode}
                            style={{
                              width: "100%",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              borderRadius: "4px",
                              padding: "5px",
                            }}
                            name="summary"
                            value={values && values.summary && values.summary}
                            onChange={changeHandler}
                            inputProps={{ maxLength: 100 }}
                            placeholder="Brief in few words"
                          />
                          {errors && errors.summary && (
                            <Typography>{errors.summary}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <InputLabel>Detailed Summary</InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={6}
                            disabled={!editMode}
                            className="profile-fields volunteerdesc"
                            name="aboutMe"
                            value={values && values.aboutMe && values.aboutMe}
                            onChange={changeHandler}
                            inputProps={{ maxLength: 1000 }}
                            style={{
                              width: "100%",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                            }}
                          />
                          {errors && errors.aboutMe && (
                            <Typography>{errors.aboutMe}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel>Email</InputLabel>
                          <Typography
                            className="profile-email"
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              lineHeight: "35px",
                              borderRadius: "4px",
                              padding: "5px",
                              height: "52px",
                            }}
                          >
                            {values.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Username
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%" }}
                            name="userName"
                            disabled={!editMode}
                            value={values.userName}
                            onChange={changeHandler}
                            required
                          />
                          {errors && errors.userName && (
                            <Typography className="custom-error">
                              {errors.userName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className="signphone phheight"
                        
                        >
                          <InputLabel>Phone</InputLabel>

                          <PhoneNumber
                            phone={userDetails && userDetails.phone}
                            isPhoneVerified={
                              userDetails && userDetails.phone_verified
                            }
                            ref={phoneRef}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} className="address">
                          <InputLabel
                            style={{ margin: "10px 0 10px 0" }}
                            className="secFormLabel"
                          >
                            Address
                          </InputLabel>
                          <NewAddress
                            value={address}
                            onChange={handleChangeAddress}
                            disabled={!editMode}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <p className="secInlineHeader">Other Details</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Date of Birth
                          </InputLabel>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              showTodayButton
                              format="dd-MMM-yyyy"
                              style={{
                                width: "100%",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: "4px",
                                padding: " 11px 10px",
                                top: " -17px",
                                lineHeight: "55px",
                              }}
                              disabled={!editMode}
                              margin="normal"
                              className="profile-fields dob"
                              name="dob"
                              value={dob}
                              onChange={handleDateofBirthChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Gender
                          </InputLabel>
                          <Select
                            value={gender}
                            onChange={handleChangeGender}
                            variant="outlined"
                            disabled={!editMode}
                            className="profile-fields gender"
                            style={{ width: "100%" }}
                            required
                          >
                            <MenuItem value={" "}>Select Gender</MenuItem>
                            <MenuItem value={"m"}>Male</MenuItem>
                            <MenuItem value={"f"}>Female</MenuItem>
                            <MenuItem value={"o"}>Other</MenuItem>
                          </Select>
                          {otherErrors && otherErrors.gender && (
                            <Typography style={{ color: "red" }}>
                              {otherErrors.gender}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Facebook URL
                          </InputLabel>
                          <TextField
                            type="text"
                            className="profile-fields fname"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="facebookProfileUrl"
                            disabled={!editMode}
                            value={values.facebookProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.facebookProfileUrl && (
                            <Typography className="custom-error">
                              {errors.facebookProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Twitter URL
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%" }}
                            name="twitterProfileUrl"
                            disabled={!editMode}
                            value={values.twitterProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.twitterProfileUrl && (
                            <Typography className="custom-error">
                              {errors.twitterProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Instagram URL
                          </InputLabel>
                          <TextField
                            type="text"
                            className="profile-fields fname"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="instagramProfileUrl"
                            disabled={!editMode}
                            value={values.instagramProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.instagramProfileUrl && (
                            <Typography className="custom-error">
                              {errors.instagramProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <InputLabel className="secFormLabel">
                            Linkedin URL
                          </InputLabel>
                          <TextField
                            type="text"
                            variant="outlined"
                            className="profile-fields lname"
                            style={{ width: "100%" }}
                            name="linkedInProfileUrl"
                            disabled={!editMode}
                            value={values.linkedInProfileUrl}
                            onChange={changeHandler}
                          />
                          {errors && errors.linkedInProfileUrl && (
                            <Typography className="custom-error">
                              {errors.linkedInProfileUrl}
                            </Typography>
                          )}
                        </Grid>
                        {editMode && (
                          <Grid item xs={12} sm={12}>
                            <Button className="red_bg"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              onClick={submitHandler}
                            >
                              Save
                            </Button>
                          </Grid>
                        )}

                        {authUser && authUser.password_verified && (
                          <Grid container spacing={2}>
                            <Grid item xs={12} className="secHeader noEdit">
                              <p>Change Password</p>
                              <Divider className="secDivide" />
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                              <InputLabel className="secFormLabel">
                                Current Password
                              </InputLabel>
                              <FormControl
                                style={{ width: "100%" }}
                                error={
                                  passwordErrors &&
                                  passwordErrors.currentPassword
                                }
                              >
                                <TextField
                                  autoComplete="off"
                                  variant="outlined"
                                  className="profile-fields password"
                                  disabled={!editMode}
                                  type="password"
                                  name="currentPassword"
                                  onChange={passwordChangeHandler}
                                  value={passwordValues.currentPassword}
                                />
                                <FormHelperText>
                                  {passwordErrors.currentPassword}
                                </FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={6}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                New Password
                              </InputLabel>
                              <FormControl
                                style={{ width: "100%" }}
                                error={
                                  passwordErrors && passwordErrors.newPassword
                                }
                              >
                                <TextField
                                  autoComplete="off"
                                  variant="outlined"
                                  className="profile-fields password"
                                  disabled={!editMode}
                                  name="newPassword"
                                  onChange={passwordChangeHandler}
                                  value={passwordValues.newPassword}
                                  type="password"
                                />
                                <FormHelperText>
                                  {passwordErrors.newPassword}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              sm={12}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                Confirm New Password
                              </InputLabel>
                              <FormControl
                                error={
                                  passwordErrors &&
                                  passwordErrors.confirmPassword
                                }
                                className="full-width border-radius"
                                style={{
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <TextField
                                  autoComplete="off"
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                  name="confirmPassword"
                                  disabled={!editMode}
                                  onChange={passwordChangeHandler}
                                  className="profile-fields changepassword"
                                  type={showPassword ? "password" : "text"}
                                  value={passwordValues.confirmPassword}
                                />
                                <InputAdornment
                                  position="end"
                                  className="showpassword"
                                  style={{
                                    position: "absolute",
                                    right: 12,
                                    top: 22,
                                  }}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                                <FormHelperText>
                                  {passwordErrors.confirmPassword}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                        {editMode && authUser && authUser.password_verified && (
                          <Grid
                            item
                            xs={12}
                            className="noEdit"
                            style={{ display: displayStyle }}
                          >
                            <Button  className="red_bg"
                              type="submit"
                              variant="contained"
                              color="primary"
                              disableElevation
                              onClick={passwordSubmitHandler}
                            >
                              Save
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={12} className="secHeader noEdit">
                          <p>Manage Account</p>
                          <Divider className="secDivide" />
                        </Grid>
                        <Grid item xs={12} md={12} className="noEdit">
                          <InputLabel
                            className="secFormLabel"
                            style={{
                              lineHeight: "20px",
                              fontWeight: "normal",
                            }}
                          >
                            If you want to take a break from Zenrth, you
                            can deactivate account. If you want to permanently
                            delete your account, let us know.
                          </InputLabel>
                        </Grid>
                        <Grid item xs={12} md={6} className="noEdit">
                          <FormControl
                            component="fieldset"
                            className="feedback-type"
                          >
                            <RadioGroup
                              aria-label="deactivateAccount"
                              name="deactivateAccount"
                              value={type}
                              onChange={handleAccMangementChange}
                            >
                              <FormControlLabel
                                value="deactivate"
                                control={<Radio />}
                                label="Deactivate My Account"
                              />
                            </RadioGroup>
                          </FormControl>
                          <InputLabel
                            className="secFormLabel"
                            style={{
                              lineHeight: "20px",
                              fontWeight: "normal",
                            }}
                          >
                            Deactivating your account can be temporary. Your
                            profile will be disabled and your name, photos will
                            be removed from the things you have shared. You have
                            the option to reactivate by simply signing in again.
                          </InputLabel>
                          {type && type === "deactivate" ? (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className="noEdit"
                              style={{ display: displayStyle }}
                            >
                              <InputLabel className="secFormLabel">
                                Enter Current Password
                              </InputLabel>
                              <FormControl
                                style={{ width: "100%" }}
                                error={errorPasswordMessage}
                              >
                                <TextField
                                  autoComplete="off"
                                  variant="outlined"
                                  className="profile-fields password"
                                  style={{ width: "100%" }}
                                  onChange={(e) =>
                                    setPasswordForAccMan(e.target.value)
                                  }
                                  type="password"
                                />
                                <FormHelperText>
                                  {errorPasswordMessage}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} className="noEdit">
                          <FormControl
                            component="fieldset"
                            className="feedback-type"
                          >
                            <RadioGroup
                              aria-label="deleteAccount"
                              name="deleteAccount"
                              value={type}
                              onChange={handleAccMangementChange}
                            >
                              <FormControlLabel
                                value="delete"
                                control={<Radio />}
                                label="Delete My Account"
                              />
                            </RadioGroup>
                          </FormControl>
                          <InputLabel
                            className="secFormLabel"
                            style={{
                              lineHeight: "20px",
                              fontWeight: "normal",
                            }}
                          >
                            When you delete your Zenrth account, you
                            won't be able to retrieve the content or information
                            you have shared on Zenrth. You will have the
                            option to recover your data on signing up again
                            using the same email address, but you need to
                            reverify your email address.
                          </InputLabel>
                          {type && type === "delete" ? (
                            <Grid item xs={12} md={12} className="noEdit">
                              <InputLabel className="secFormLabel">
                                Enter Current Password
                              </InputLabel>
                              <FormControl
                                style={{ width: "100%" }}
                                error={errorPasswordMessage}
                              >
                                <TextField
                                  autoComplete="off"
                                  variant="outlined"
                                  className="profile-fields password"
                                  style={{ width: "100%" }}
                                  disabled={!editMode}
                                  onChange={(e) =>
                                    setPasswordForAccMan(e.target.value)
                                  }
                                  type="password"
                                />
                                <FormHelperText>
                                  {errorPasswordMessage}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {editMode && (
                          <Grid item xs={12} className="noEdit">
                            <Button  className="red_bg"
                              variant="contained"
                              color="primary"
                              disableElevation
                              style={{ width: "40%" }}
                              onClick={submitAccountManagement}
                            >
                              {type === "deactivate"
                                ? "Deactivate Account"
                                : type === "delete"
                                ? "Delete Account"
                                : "Save"}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <PropertyListingCard />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default withRouter(NewUserProfile);
