import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { appContext } from "../../../App";
import toastr from "toastr";
import { getCurrentTime } from "../../../utils/api";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  const { setAuthUser, setIsAdmin, setIsSuperAdmin, setOpenLogin } =
    useContext(appContext);
  props.history.listen(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));

    if (user && user.token_detail && user.token_detail.token) {
      const decodedJwt = parseJwt(user.token_detail.token);
      const currentTime = new Date().getTime();
      if (decodedJwt.exp * 1000 < currentTime) {
        setAuthUser(null);
        setIsAdmin(null);
        setIsSuperAdmin(null);
        localStorage.removeItem("authUser");
        setOpenLogin(true);
        toastr.warning("Your session expired, Please login again");
      }
    }
  });

  return <div></div>;
};

export default withRouter(AuthVerify);
