import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Tab from "./Tab";




const useStyles = makeStyles((theme) => ({
    

    header_bg:{
        backgroundImage: `url(${"images/aboutus.jpg"})`,
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        padding:"20px 0",
        // height:"530px",
    },

    headar_main_text:{
        color:"white",
        textAlign:"left",
        [theme.breakpoints.down('sm')]: {
           textAlign:"center",
        },

        [theme.breakpoints.down('xs')]: {
            textAlign:"center",
        },
    },
}));


export default function Header(){
   const classes = useStyles();
 
    return(
        <Box>
            <Box components="div" className={classes.header_bg}>
            <Container>
                <Box component="div" py={{ xs: 0, sm: 0, md: 16 }} className={classes.headar_main_text}>
                    <Box component="h1" fontSize="calc(18px + 1.5vw)"> About Zenrth</Box>
                </Box>
            </Container>
            </Box>

            <Box bgcolor="white" py={4}>
                <Container>
                    <Tab/>
                </Container>
            </Box>
        </Box>


  
    )};