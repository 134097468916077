import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import Divider from "@material-ui/core/Divider";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={1} px={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  social_icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "20px 0",

    "& a": {
      color: "#9e9e9e",
    },
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 224,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },

    tab_padding: {
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
  },
  MuiTabsFlexContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "165px",

    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      borderRight: "1px soild white",
    },

    [theme.breakpoints.down("sm")]: {
      "& div": {
        display: "block",
        borderBottom: "1px solid #0000001f",
      },
    },
    "& span": {
      "&:nth-child(2)": {
        backgroundImage: `url(${"images/aboutus_aroow_icon.svg"})`,
        backgroundRepeat: "no-repeat",
        width: "8px",
        backgroundPosition: "center",
        backgroundColor: "transparent",
        marginRight: "5px",
        [theme.breakpoints.down("sm")]: {
          backgroundImage: "none",
        },
      },
    },

    "& Button": {
      "&:focus": {
        color: "#3D69B2",
        [theme.breakpoints.down("sm")]: {
          borderBottom: "3px solid #3D69B2",
        },
      },

      "& span": {
        display: "flex",
        color:"#ec1c24",
        alignItems: "flex-start",
        fontSize: "12px",
        [theme.breakpoints.down("sm")]: {
          alignItems: "center",
        },
      },
      "&:nth-child(2)": {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
          border: "none",
        },
      },
    },
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="About Us" {...a11yProps(0)} />

        <Tab label="Management Team" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tab_padding}>
        <Box component="h4" mt={{ xs: 2, sm: 2, md: 0 }}>
          What we do
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          We are the fastest growing company in the online real estate space.
          Born out of the need to simplify the search for a home, free of fake
          listings and endless site visits, we created a unique property search
          platform that filled the gaps left by others in the market. Led by
          passionate problem-solvers, we are poised to become the most trusted
          place to find a home in India.
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          We are committed to leaving a legacy of progress by developing local
          skills for sustained economic opportunity and volunteering our time
          and talent to the communities where we work.
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          At Zenrth.com, we help you find joy.
        </Box>

        <Box component="h4" mt={4}>
          Our Vision
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          Delivering trustworthy experiences that you cherish for a lifetime.
        </Box>

        <Box component="h4" mt={4}>
          Our Mission
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          To be the first choice for our consumers and partners in their journey
          of discovering, buying, selling a home. We do that with data, design,
          technology, and above all the passion of our people, while delivering
          value to our shareholders.
        </Box>

        <Box component="h4" mt={4}>
          Innovation
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          We are always looking to improve, to change, to disrupt. Because with
          change comes unseen possibilities.
        </Box>

        <Box component="h4" mt={4}>
          Trust & Integrity
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          We stand by our words. We say what we believe. And we do what we say.
          So, let’s, talk the talk. And walk the walk.
        </Box>

        <Box component="h4" mt={4}>
          Excellence
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          Excellence is not just a word. It's that restless feeling in the gut.
          That feeling of wanting more. Wanting to be great.
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box component="h4" mt={{ xs: 2, sm: 2, md: 0 }}>
          Our Mission
        </Box>
        <Box component="p" lineHeight="2" color="#5E5E5E">
          To be the first choice for our consumers and partners in their journey
          of discovering, buying, selling a home. We do that with data, design,
          technology, and above all the passion of our people, while delivering
          value to our shareholders.
        </Box>

        <Box component="div" mt={5}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={3} className="teamimg" mt={3}>
              <img src="images/about_img_5.png" width="100%"  mt={3} 
                 />
              {/* <Box component="div" className={classes.social_icon}>
                <a href="#"><EmailIcon/></a>
                <a href="#"><LinkedInIcon/></a>
                <a href="#"><FacebookIcon/></a>
            </Box> */}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box component="h4">T M Rao</Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                {" "}
                CEO
              </Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                 Mr. T M Rao is the CEO of Zenrth. Mr. Rao is responsible for the company’s overall vision and strategic direction. <br/>

                 Mr. T M Rao has begun his 4 decades of gaining experience with Hyderabad Central University. He headed the Engineering department and fulfilled his responsibilities for the success of the university in various segments. Later he joined a private corporate electrical firm as a Senior Engineer, handled their tenders and marketing for the company’s tremendous growth. Mr. T M Rao has been associated with Solix Technologies for over 2 decades handling the operations, finances, and legal departments. <br/>

                Mr. T M Rao has a proven track record in recognizing and quickly responding to anything business. Such strengths have been so valuable to date.
              </Box>
            </Grid>

            <Grid item xs={12} sm={3}>
              <img src="images/about_img_3.png" width="100%" 
               />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box component="h4">B Satyam</Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                {" "}
                Director, Construction Engineering 
              </Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                {" "}
                Satyam Bathoju is a professional and innovative Engineer with 30
                plus years experience in handling medium to large construction
                projects. He is adept at providing best for architectural and
                waste solutions and has extensively worked on commercial
                buildings, residential buildings, multi storied apartments and
                ventures.His interests include fully integrated building and
                community designs with focus on energy conservation and use of
                natural materials.He has provided consultancy services to
                multiple projects in and around Hyderabad. Knowledge of relevant
                regulations for project design, good marketing and licensing
                works are few of his strengths.{" "}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} pt={4}>
              <img
                src="images/about_img_2.png"  
                width="100%"
                
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box component="h4"> P Prabhakar Rao </Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                {" "}
                Director, Government Affairs
              </Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                Mr. P. Prabhakara Rao who is a passionate civil engineer. He
                walks the talk by driving innovation, best-in class design,
                specialized construction, real estate and execution skills. May
                it be grand and aesthetic apartments or awe-inspiring commercial
                spaces, his vibrant outlook and professional way of doing things
                keeps customers enthralled and satisfied.He is also
                professionally associated with various companies for 15years.
                All this led him become a successful promoter in real estate.
                And the projects undertaken by the company reflects his motto of
                turning dreams into reality.
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <img src="images/about_img_4.png" width="100%"
                />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Box component="h4">Taddi Venkat Ram</Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                Director, Engineering
              </Box>
              <Box component="p" color="#5E5E5E" lineHeight="2">
                Venkat is a passionate software engineer. He has extensive
                experience in the whole lifecycle of software development. He
                played a key role in the development of various software
                projects at different companies like Xtime, HPE, Touch-A-Life
                foundation etc. Apart from software development, he keeps an
                active lifestyle with the sport of running. He ran several
                marathons to his credit.
              </Box>
            </Grid>
           
          </Grid>
        </Box>
      </TabPanel>
    </div>
  );
}
