import validator from "validator";

const validateSignup = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!validator.isEmail(values.email.trim())) {
    errors.email = "Please enter a valid email address.";
  }

  if (!values.first_name) {
    errors.first_name = "Please enter your first name.";
  }
  if (!values.last_name) {
    errors.last_name = "Please enter your last name.";
  }

  if (!values.customer) {
    errors.customer = "Please select customer type.";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (
    !values.password.match(/[a-z]/) ||
    !values.password.match(/[A-Z]+/) ||
    !values.password.match(/\d/) ||
    !values.password.match(/[^a-zA-Z0-9\-\/]/) ||
    values.password.length < 6
  ) {
    errors.password = "Password is not strong";
  }
  return errors;
};

export default validateSignup;
