import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
export const DATE_FORMAT = "DD-MMM-YYYY";
export const DATE_FORMAT_FULL = "dddd, Do MMMM YYYY";
export const DATE_TIME_FORMAT = "DD-MMM-YYYY hh:mm A";
export const DATE_TIME_FORMAT_FULL = "dddd, Do MMMM YYY, hh:mm A";
export const TIME_FORMAT = "hh:mm A";
export const TIME_FORMAT_SECONDS = "HH:mm:ss";
export const HOME_PAGE = "/";
export const DASHBOARD_PAGE = "/";
//export const LOGIN_PAGE = "/login";
export const TALRADIO_PAGE = "/talradio";
export const AVATAR_DONOR = "Donor";
export const AVATAR_DONEE = "Donee";
export const STATUS_NEW = "0";
export const STATUS_APPROVED = "1";
export const STATUS_COMPLETED = "2";
export const STATUS_SOLD = "3";
export const STATUS_ALL = "";
export const STATUS_REJECTED = "-1";
export const STATUS_INCOMPLETE = "-2";
export const STATUS_SPAM = "-3";
export const STATUS_EXPRIED = "-4";
export const STATUS_DEACTIVATED = "-5";
export const ROLE_OWNER = "owner";
export const ROLE_ADMIN = "admin";
export const ROLE_MEMBER = "member";
export const REQUEST_TYPE_CASH = "cash";
export const REQUEST_TYPE_PLASMA = "plasma";
export const REGION_ID_INDIA = "IN";
export const REGION_ID_USA = "US";
export const COUNTRY_CODE_ISO3_INDIA = "IND";
export const COUNTRY_CODE_ISO3_USA = "USA";
export const CURRENCY_USD = "USD";
export const CURRENCY_INR = "INR";

export const DONATION_REQUEST_STATUS_MAP = {};
export const PROPERTY_STATUS_MAP = {
  0: {
    shortLabel: "Pending",
    label: "APPROVAL IS PENDING FROM ADMIN",
    bgcolor: "info.main",
    color: "info.contrastText",
    ribbonBgColor: "PENDING",
    ribbonTextColor: "text_white",
  },
  1: {
    shortLabel: "Approved",
    label: "APPROVED",
    bgcolor: "success.main",
    color: "success.contrastText",
    ribbonBgColor: "APPROVED",
    ribbonTextColor: "text_white",
  },
  3: {
    shortLabel: "Sold",
    label: "SOLD",
    bgcolor: "success.main",
    color: "success.contrastText",
    ribbonBgColor: "SOLD",
    ribbonTextColor: "text_white",
  },
  "-1": {
    shortLabel: "Rejected",
    label: "REJECTED",
    bgcolor: "error.main",
    color: "error.contrastText",
    ribbonBgColor: "REJECTED",
    ribbonTextColor: "text_white",
  },
  "-2": {
    shortLabel: "Incomplete",
    label: "INCOMPLETE INFORMATION BY DONEE",
    bgcolor: "text.disabled",
    color: "background.paper",
    ribbonBgColor: "INCOMPLETE",
    ribbonTextColor: "text_white",
  },
  "-3": {
    shortLabel: "Spam",
    label: "SPAM",
    bgcolor: "text.secondary",
    color: "background.paper",
    ribbonBgColor: "SPAM",
    ribbonTextColor: "text_white",
  },
  "-4": {
    shortLabel: "Expired",
    label: "EXPIRED",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "EXPIRED",
    ribbonTextColor: "text_white",
  },
  "-5": {
    shortLabel: "Deactivated",
    label: "DEACTIVATED",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "DEACTIVATED",
    ribbonTextColor: "text_white",
  },
};

export const CONTENT_TYPE_EXTENSIONS_MAP = {
  "audio/mpeg": ".mp3",
  "video/mpeg": ".mpeg",
  "audio/ogg": ".oga",
  "video/ogg": ".ogv",
  "audio/opus": ".opus",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "video/webm": ".webm",
  "video/3gpp": ".3gp",
  "audio/3gpp": ".3gp",
  "video/3gpp2": ".3g2",
  "audio/3gpp2": ".3g2",
  "audio/aac": ".aac",
  "video/x-msvideo": ".avi",
  "audio/midi": ".mid",
  "audio/x-midi": ".midi",
};

export const GOOGLE_CLOUD_STORAGE_PROPERTY_SUFFIX = "_800x600";

export const getGoogleCloudStorageSuffixedUrl = (url) => {
  if (url) {
    if (url.indexOf(GOOGLE_CLOUD_STORAGE_PROPERTY_SUFFIX) < 0) {
      return url.replace("?", GOOGLE_CLOUD_STORAGE_PROPERTY_SUFFIX + "?");
    } else {
      return url;
    }
  } else {
    return url;
  }
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const formatScore = (value) => {
  if (value >= 1000000) {
    value = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(1) + "K";
  } else if (value >= 1000000000000) {
    value = (value / 1000000000000).toFixed(1) + "B";
  }
  return value;
};

export const formatNumber = (number) => {
  if (isNaN(number)) {
    return number;
  }
  if (number) {
    return Number(number).toLocaleString();
  }
  return number;
};

export const isImage = (contentType) => {
  return (
    contentType === "image/jpeg" ||
    contentType === "image/gif" ||
    contentType === "image/png" ||
    contentType === "image/bmp" ||
    contentType === "image/tiff" ||
    contentType === "image/svg+xml" ||
    contentType === "image/webp"
  );
};

export const isAudio = (contentType) => {
  if (contentType) {
    return (
      contentType.indexOf("audio") >= 0 ||
      contentType.indexOf("application/octet-stream") >= 0
    );
  }
  return false;
};

export const isValidPassword = (password) => {
  let msg = "";
  if (!password) {
    msg += "Password is required. ";
  }

  if (!password.match(/[a-z]/)) {
    msg += "Contains at least one letter. ";
  }

  if (!password.match(/[A-Z]+/)) {
    msg += "Contains at least one capital letter. ";
  }

  if (!password.match(/\d/)) {
    msg += "Contains at least one digit. ";
  }

  if (!password.match(/[^a-zA-Z0-9\-\/]/)) {
    msg += "Contains at least one special character. ";
  }

  if (password.length < 6) {
    msg += "Contains at least 6 characters. ";
  }

  return msg;
};

export const areUrlsSame = (url1, url2) => {
  if (url1) {
    url1 = url1.substring(0, url1.indexOf("?"));
  }
  if (url2) {
    url2 = url2.substring(0, url2.indexOf("?"));
  }
  return url1 === url2;
};

export const mergeAudioFiles = async (selectedFiles) => {
  const ffmpeg = createFFmpeg({
    log: false,
  });

  //load ffmpeg library
  await ffmpeg.load();

  let concatCmd = "concat:";
  return Promise.all(
    [...selectedFiles].map(async (eachFile, index) => {
      //create concat command by adding selected files.
      concatCmd = concatCmd + (index === 0 ? "" : "|") + `f${index + 1}.mp3`;

      //write each file in separate file
      ffmpeg.FS(
        "writeFile",
        `f${index + 1}.mp3`,
        await fetchFile(URL.createObjectURL(eachFile))
      );
    })
  ).then(async (data) => {
    return new Promise(async (resolve, reject) => {
      await ffmpeg.run("-i", concatCmd, "-acodec", "copy", "finaloutput.mp3");
      const finaloutput = ffmpeg.FS("readFile", "finaloutput.mp3");
      resolve(finaloutput);
    });
  });
};
export const getObjectValuesInStringFormat = (obj, concat = ", ") => {
  if (!obj) {
    return "";
  }
  for (var propName in obj) {
    if (!obj[propName] || obj[propName] === true) {
      delete obj[propName];
    }
  }
  return Object.values(obj).join(concat);
};

export const getLocalityInStringFormat = (locality, concat = ",") => {
  if (!locality) {
    return "";
  }
  const tempLocality = {
    line1: locality.line1,
    line2: locality.line2,
    city: locality.city,
    state: locality.state,
    country: locality.country,
    zipcode: locality.zipcode,
  };
  for (var propName in tempLocality) {
    if (!tempLocality[propName] || tempLocality[propName] === true) {
      delete tempLocality[propName];
    }
  }
  return Object.values(tempLocality).join(concat);
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}
export function rupeeValue() {
  return new Promise((resolve, reject) => {
    var api = `${process.env.REACT_APP_CURRENCY_CONVERSION_URL}`;
    fetch(api)
      .then((currency) => {
        return currency.json();
      })
      .then((result) => {
        let fromRate = result.rates["INR"];
        resolve(fromRate);
      });
  });
}

export function convertFirstCharacterToUppercase(stringToConvert) {
  var firstCharacter = stringToConvert.substring(0, 1);
  var restString = stringToConvert.substring(1);

  return firstCharacter.toUpperCase() + restString;
}

export function roundToTwoPlaces(number) {
  if (isNaN(number)) {
    return number;
  }
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function containsWhitespace(str) {
  return /\s/.test(str);
}

export const onlyNumbers = (e) => {
  var key = e.keyCode ? e.keyCode : e.which;

  if (
    !(
      [8, 9, 13, 27, 46, 110, 190].indexOf(key) !== -1 ||
      (key == 65 && (e.ctrlKey || e.metaKey)) ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
      (key >= 96 && key <= 105)
    )
  )
    e.preventDefault();
};
