import React, { useContext, useState } from "react";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import validator from "validator";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useFormValidation from "../../../hooks/useFormValidation";
import {
  forgotPassword,
  validateOtp,
  changePassword,
  resendOTP,
} from "../../../utils/api";
import "./ForgotPassword.scss";
import { Dialog } from "@material-ui/core";
import { appContext } from "../../../App";

import Box from "@material-ui/core/Box";

const ForgotPassword = (props) => {
  const { fpOpen, setfpOpen, setOpenLogin } = useContext(appContext);
  const [responseStatus, setStatus] = useState("");
  const [otpValues, setOtp] = useState("");
  const [error, setError] = useState("");
  const [showResendButton, setShowResendButton] = useState(true);
  const [responseDate, setResponseData] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordView, setViewPassword] = useState(false);
  const [smallLetter, setSmallLetterCondition] = useState(false);
  const [capitalLetter, setCapitalLetterCondition] = useState(false);
  const [length, setPasswordLength] = useState(false);
  const [numberCondition, setNumberCondition] = useState(false);
  const [specialLetter, setSpecialLetter] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [reset, setReset] = useState(true);

  const postOtpValues = (otp) => {
    const otpDetails = {};
    otpDetails.otp = otp;
    otpDetails.email = values.email;
    otpDetails.type = "forgot password";
    validateOtp(otpDetails)
      .then((response) => {
        if (response && response.statusCode === 200) {
          setStatus("");
          setResponseData(response);
          toastr.success(response.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const handleOtpCode = (e) => {
    setOtp(e);
    if (e.length === 6) {
      postOtpValues(e);
    }
  };

  const initialState = {
    email: "",
  };

  const validateEmail = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Please enter a valid email address";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Please enter a valid email address";
    }
    return errors;
  };

  const resendOtp = () => {
    const obj = {};
    obj.email = values.email;
    obj.type = "forgot password";
    resendOTP(obj)
      .then((response) => {
        toastr.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const sendResetEmail = () => {
    forgotPassword(values)
      .then((response) => {
        if (response && response.statusCode === 200) {
          setReset(false);
          setStatus(response);
        }
      })
      .catch((error) => {
        if (error.data.statusCode === 401) {
          toastr.error(
            "No account found with the given email address. Create a new account"
          );
          setReset(false);
          setfpOpen(false);
        } else if (error.data.statusCode === 404) {
          toastr.error(error.data.message);
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const changeHandlerPassword = (event) => {
    showPasswordRules();
    setError("");
    if (event.target.value.match(/[a-z]/)) {
      setSmallLetterCondition(true);
    } else {
      setSmallLetterCondition(false);
    }
    if (event.target.value.match(/[A-Z]+/)) {
      setCapitalLetterCondition(true);
    } else {
      setCapitalLetterCondition(false);
    }
    if (event.target.value.match(/\d/)) {
      setNumberCondition(true);
    } else {
      setNumberCondition(false);
    }
    if (event.target.value.match(/[^a-zA-Z0-9\-\/]/)) {
      setSpecialLetter(true);
    } else {
      setSpecialLetter(false);
    }
    if (event.target.value.length >= 6) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    referPasswordRules(event.target.value);
  };
  const referPasswordRules = (pswd) => {
    setPassword(pswd);
    setPasswordValidation(true);
  };

  const changePasswordHandler = () => {
    if (password !== "") {
      if (
        smallLetter !== false &&
        capitalLetter !== false &&
        numberCondition !== false &&
        specialLetter !== false &&
        length !== false
      ) {
        const obj = {};
        obj.email = values.email;
        obj.otp = otpValues;
        obj.password = password;
        changePassword(obj)
          .then((response) => {
            if (response && response.statusCode === 200) {
              toastr.success(response.message);
              if (
                response.status === "success" &&
                response.message === "Successfully changed the user password"
              ) {
                setResponseData("");
                setOpenLogin(true);
              }
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
      } else {
        setViewPassword(true);
      }
    } else {
      setError("Password is Required");
    }
  };

  const showPasswordRules = () => {
    setViewPassword(true);
  };

  const viewPasswordRules = () => {
    if (
      smallLetter === true &&
      capitalLetter === true &&
      length === true &&
      specialLetter === true &&
      numberCondition === true
    ) {
      setViewPassword(false);
    }
    return (
      <Box className="password-strength" pt={1}>
        <h4>Password must be requirements</h4>
        <ul>
          <li
            className={smallLetter === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one letter</strong>
          </li>
          <li
            className={capitalLetter === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one capital letter</strong>
          </li>
          <li
            className={numberCondition === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one digit</strong>
          </li>
          <li
            className={specialLetter === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>one special character</strong>
          </li>
          <li
            className={length === true ? "valid" : "invalid"}
            style={{ "text-align": "start" }}
          >
            Contains at least <strong>6 characters</strong>
          </li>
        </ul>
      </Box>
    );
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  const handleEnter = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      changePasswordHandler(event);
    }
  };
  const handlefpClose = () => {
    setfpOpen(false);
    setResponseData({});
    setReset(true);
    setOtp();
  };

  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    validateEmail,
    sendResetEmail
  );

  return (
    <Dialog
      open={fpOpen}
      onClose={handlefpClose}
      aria-labelledby="forgot-password-dialog"
    >
      <div className="password-reset-container">
        {responseDate && responseDate.statusCode === 200 ? (
          <Box component="div" p={2}>
            <Typography component="div">
              <Typography component="div" className="forgot-reset-password">
                <Box
                  component="h6"
                  className="headername"
                  fontSize="26px"
                  color="primary.main"
                >
                  Change Password
                </Box>
                <FormControl
                  className="full-width border-radius"
                  style={{ width: "100%" }}
                >
                  <TextField
                    className={`${error && "error-text"}`}
                    id="outlined-adornment-confirm-password"
                    name="confirmPassword"
                    style={{ width: "100%", marginTop: 15 }}
                    variant="outlined"
                    label="Confirm Password"
                    type={showPassword ? "password" : "text"}
                    value={password.newPassword}
                    onChange={changeHandlerPassword}
                    onKeyPress={(e) => handleEnter(e)}
                  />
                  <InputAdornment position="end" className="showpassword">
                    <IconButton
                      aria-label="toggle password visibility"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "20px",
                      }}
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  {passwordView === true ? viewPasswordRules() : ""}
                </FormControl>
                {error && (
                  <spam style={{ color: "red", "font-size": "12px" }}>
                    {error}
                  </spam>
                )}
                <br />
                <Button
                  style={{
                    width: "100%",
                    margin: "15px 0",
                  }}
                  className="submit-button"
                  variant="contained"
                  color="primary"
                 className="red_bg"
                  type="submit"
                  onClick={changePasswordHandler}
                  disable={!passwordView}
                >
                  confirm
                </Button>
                <br />
              </Typography>
            </Typography>
          </Box>
        ) : (
          <div>
            {responseStatus && responseStatus.status === "success" ? (
              <div className="otp-container">
                <div className="reset-password password-reset-container">
                  <figure>
                    <img src="/images/otp.svg" alt="google" width="70px" />
                  </figure>

                  <p style={{ fontSize: "26px", fontWeight: "bold" }}>
                    Verify Account
                  </p>

                  <div style={{ color: "red" }}>{error && error}</div>

                  <span>Enter a code we just sent to your registered mail</span>

                  <div>
                    <div className="otp-boxes">
                      <OtpInput
                        onChange={(e) => handleOtpCode(e)}
                        numInputs={6}
                        value={otpValues}
                        separator={<span> &nbsp; </span>}
                        shouldAutoFocus={true}
                      />
                    </div>
                    <div className="timerOTP">
                      <div>
                        {showResendButton && showResendButton === true ? (
                          <p
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#cd2627",
                            }}
                            onClick={resendOtp}
                          >
                            Resend OTP
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              reset && (
                <Box className="password-reset">
                  <Box
                    className="headername"
                    fontSize="26px"
                    component="h6"
                    color="primary.main"
                  >
                    Reset Password
                  </Box>
                  <div style={{ width: "100%" }}>
                    <TextField
                      className={`${error.email && "error-text"}`}
                      name="email"
                      style={{ width: "100%" }}
                      placeholder="Enter registered email address"
                      value={values.email}
                      onChange={changeHandler}
                      label="Email Address"
                      variant="outlined"
                      onKeyPress={(e) => handleEnterClick(e)}
                      id="email"
                    />
                    {errors && (
                      <p
                        style={{
                          color: "red",
                          "text-align": "center",
                          margin: "5px 0",
                          "font-size": "12px",
                        }}
                      >
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <Button
                    style={{
                      width: "100%",
                      margin: "10px 0",
                      lineHeight: "2.5em",
                    }}
                    className="otp-button"
                    variant="contained"
                    color="primary"
                    className="red_bg"
                    type="submit"
                    onClick={submitHandler}
                  >
                    Get OTP
                  </Button>
                </Box>
              )
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ForgotPassword;
