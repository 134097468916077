import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import VerifyPhone from "../common/VerifyPhone";
import CancelIcon from "@material-ui/icons/Cancel";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import { appContext } from "../../App";
import { changeMobileNumber } from "../../utils/api";
import InputAdornment from "@material-ui/core/InputAdornment";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import phoneNumber from "./PhoneNumber.css";
const PhoneNumber = forwardRef((props, ref) => {
  const { authUser, setAuthUser, userLocaleInfo } = useContext(appContext);
  const [phoneError, setPhoneError] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [phoneEnableEdit, setPhoneEnableEdit] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");

  const handleEditNumber = () => {
    setPhoneEnableEdit(true);
  };

  useImperativeHandle(ref, () => ({
    isValid() {
      if (!isValidPhoneNumber(newPhoneNumber) || !newPhoneNumber) {
        setPhoneError("Enter valid phone number");
        return false;
      } else if (!phoneVerified || isDirty()) {
        setPhoneError("Please verify the mobile number");
        return false;
      }
      setPhoneError("");
      return true;
    },
    phoneNumber() {
      return newPhoneNumber;
    },
  }));

  const handleVerifyPhone = (phone) => {
    const obj = {};
    obj.userId = authUser && authUser.unique_id;
    obj.phone = phone;
    if (phoneVerified && !isDirty()) {
      toastr.success("Phone Number " + phone + " is already verified ");
      setPhoneEnableEdit(false);
      return;
    }
    if (!isValidPhoneNumber(phone) || !phone) {
      setPhoneError("Enter valid phone number");
    } else {
      setPhoneError("");
      setPhoneVerified(false);
      changeMobileNumber(obj)
        .then((response) => {
          if (response && response.data.statusCode === 200) {
            toastr.success(response.data.message);
            setShowVerifyPhone(true);
            setPhoneEnableEdit(false);
            setAuthUser((currentValues) => {
              return {
                ...currentValues,
                phone: phone,
              };
            });
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message.includes(
              "Your number is already verified"
            )
          ) {
            setPhoneVerified(true);
            setPhoneEnableEdit(false);
          }
        });
    }
  };

  const onCloseVerifyPhoneModal = () => {
    setShowVerifyPhone(false);
  };

  useEffect(() => {
    setNewPhoneNumber(props.phone);
  }, [props.phone]);

  useEffect(() => {
    if (props) {
      setPhoneVerified(props.isPhoneVerified);
      if (props.isPhoneVerified) {
        setPhoneEnableEdit(false);
      } else {
        setPhoneEnableEdit(true);
      }
    }
  }, [props.isPhoneVerified]);

  const isDirty = () => newPhoneNumber !== props.phone;

  return (
    <div>
      <div className="userprofileFormBlk borderhide">
        <FormControl
          className="full-width border-radius"
          style={{ width: "100%" }}
        >
          <PhoneInput
            className={phoneNumber}
            placeholder="Enter phone number"
            disabled={!phoneEnableEdit}
            defaultCountry={userLocaleInfo && userLocaleInfo.country_code}
            value={newPhoneNumber}
            onChange={setNewPhoneNumber}
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              paddingLeft: "10px",
              borderLeft: "3px solid #f44336",
              height: "62px",
              lineHeight: "55px",
            }}
          />
          {phoneError && (
            <Typography
              className="custom-error"
              style={{ border: "none", marginTop: "10px" }}
            >
              {phoneError}
            </Typography>
          )}
          <InputAdornment>
            <span
              aria-label="toggle password visibility"
              style={{
                top: "1px",
                position: "absolute",
                padding: "0",
                right: "0",
              }}
              edge="end"
            >
              {!phoneEnableEdit ? (
                <>
                  <Tooltip title="Verified">
                    <VerifiedUser
                      style={{
                        color: "green",
                        size: "large",
                        backgroundColor: "white",
                        paddingRight: " 2px",
                        fontSize: "20px",
                      }}
                    />
                  </Tooltip>
                  <Button className="red_bg"
                    style={{
                      // background: "#3D69B2",
                      height: "62px",
                      minWidth: "45px",
                      border: "none",
                      color: "white",
                      fontSize: "12px",
                      display: "inline-block",
                      padding: "0",
                      margin: "0",
                      borderRadius: "4px",
                      position: "relative",
                      top: "-1px",
                      lineHeight: "30px",
                    }}
                    onClick={handleEditNumber}
                  >
                    <EditIcon />
                    <p style={{ color: "white" }}>Edit</p>
                  </Button>
                </>
              ) : (
                <>
                  {!phoneVerified && (
                    <Tooltip title="Not Verified">
                      <CancelIcon style={{ color: "red", size: "large" }} />
                    </Tooltip>
                  )}
                  <Button  className="red_bg"
                    style={{
                      // background: " #3f51b5",
                      height: "62px",
                      width: "60px",
                      border: "none",
                      color: "white",
                      fontSize: "14px",
                      padding: "0",
                      margin: "0",
                      borderRadius: "4px",
                      position: "relative",
                      top: "-1px",
                    }}
                    onClick={() => handleVerifyPhone(newPhoneNumber)}
                  >
                    VERIFY
                  </Button>
                </>
              )}
            </span>
          </InputAdornment>
        </FormControl>
      </div>
      <VerifyPhone
        openVerifyPhoneModal={showVerifyPhone}
        onCloseModal={onCloseVerifyPhoneModal}
        phoneNumber={newPhoneNumber}
        userId={authUser && authUser.unique_id}
        setPhoneVerified={setPhoneVerified}
        fromUserProfile={true}
      />
    </div>
  );
});

export default PhoneNumber;
