import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Geocode from "react-geocode";
import toastr from "toastr";

const style = {
  maxWidth: "100%",
  height: "350px",
  overflowX: "hidden",
  overflowY: "hidden",
  position: "relative",
};
const containerStyle = {
  maxWidth: "100%",
  height: "350px",
  position: "relative",
};
class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlace: {},
    };
  }

  componentDidMount() {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("in");
    Geocode.setLocationType("ROOFTOP");
    Geocode.fromAddress(this.props.location).then(
      (response) => {
        this.setState({
          selectedPlace: response.results[0].geometry.location,
        });
      },
      (error) => {
        toastr.error("Map loading error " + error);
      }
    );
  }

  render() {
    const { selectedPlace } = this.state;

    return (
      <Map
        google={this.props.google}
        style={style}
        containerStyle={containerStyle}
        onClick={this.onMapClicked}
        zoom={14}
        center={selectedPlace}
      >
        <Marker position={selectedPlace} />
      </Map>
    );
  }
}

const LoadingContainer = (props) => <div>Fancy loading container!</div>;

const GoogleMap = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  LoadingContainer: LoadingContainer,
})(MapContainer);

export default GoogleMap;
