import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IndianAddress from "../common/IndianAddress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    Width: "100%",
  },

  header_bg: {
    backgroundImage: `url(${"/images/hero_img.jpg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "20px 0",
    // height:"530px",

    "& h1": {
      color: "white",
      fontSize:"2.5rem",
      marginBottom:"25px",
    },
    "& span.txt": {
      color: "white",
      fontSize:"1.5rem",
    },

    "& p": {
      color: "white",
    },

    "& Button": {
      height: "50px",
      minWidth: "100%",
    },
  },

  headar_main_img: {
    display: "grid",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "grid",
      justifyContent: "center",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "grid",
      justifyContent: "center",
    },
  },
  headar_main_text: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [city, setCity] = React.useState("");
  const cities = [
    "Hyderabad",
    "Bengaluru",
    "Mumbai",
    "Chennai",
    "Delhi",
    "Kolkata",
  ];
  const handleChange = (event) => {
    setCity(event.target.value);
  };

  return (
   
    <Box components="div" className={classes.header_bg}>
       <Box components="div" className="herobg-black"> </Box>
      <Container style={{position:"relative", zIndex:"2"}}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <Box
              component="div"
              py={{ xs: 0, sm: 0, md: 16 }}
              className={classes.headar_main_text}
            >
              <Box component="h1" fontSize="calc(14px + 1.3vw)">
                {" "}
              Let's find a home for <br></br>Healthy &amp; happiness living 
              
              </Box>
              <Box component="span" className="txt">
              We are here to help you find right the place for you and your family
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Box
              component="div"
              py={{ xs: 2, sm: 4, md: 8 }}
              className={classes.headar_main_img}
            >
              <img src="/images/chat_box.png" alt="Chat" />
            </Box>
          </Grid>
        </Grid>

        <Box component="section">
          <IndianAddress />
        </Box>
      </Container>
    </Box>
 
  );
}
