import validator from "validator";
const validateAddress = (values) => {
  let errors = {};
  if (values.zipcode && !validator.isPostalCode(values.zipcode)) {
    errors.zipcode = "Please enter valid zip";
  }
  return errors;
};

export default validateAddress;
