import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import toastr from "toastr";
import "./index.css";
import "toastr/build/toastr.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./common.scss";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.timeOut = 300000;
axios.defaults.extendedTimeOut = 600000;

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-bottom-full-width",
  preventDuplicates: false,
  onclick: null,
  showDuration: "10000",
  hideDuration: "10000",
  timeOut: "10000",
  extendedTimeOut: "12000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
