import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: " 20px 0",
    main: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    "& p": {
      padding: "5px 0",
    },

    "& h5": {
      padding: "10px 0",
    },

    "& ul": {
      padding: "5px 0 5px 20px",
    },

    "& li": {
      padding: "5px 0",
    },
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>
        <Paper elevation={2} style={{ padding: "10px 20px" }}>
          <Box
            component="h5"
            textAlign="center"
            fontSize="1.8rem"
            bgcolor="#f8f8f8"
            color="primary.main"
          >
            Privacy Policy
          </Box>
          <Box component="h5">1. Introduction</Box>
          <Box component="p">
            Your privacy is important to us. It is Zenrth policy to
            respect your privacy regarding any information we may collect from
            you across our website, and other sites and URLs we own and operate
            under the brand Zenrth, and its subsidiaries and related
            companies if any. This privacy policy explains how we process the
            personal data obtained from our users. By using our website, and
            reading this policy:
          </Box>
          <Box component="ul">
            <Box component="li">
              you declare your consent for us processing your personal data for
              contacting purposes;
            </Box>
            <Box component="li">
              you declare your consent for us processing your personal data for
              internal administrative reasons; and
            </Box>
            <Box component="li">you are deemed notified of such processing</Box>
          </Box>
          <Box component="p">
            If you do not agree with the processing of your personal data,
            please do not provide us with your personal data via the
            Zenrth website.
          </Box>
          <Box component="p">
            We may update our privacy policy to reflect current acceptable
            practices. We will take reasonable steps to let users know about
            changes via our website. If you are a registered user on our site,
            we will notify you using the contact details saved in your account.
            Your continued use of this site after any changes to this policy
            will be regarded as acceptance of our practices around privacy and
            personal information.
          </Box>
          <Box component="p">
            If you accept these terms on behalf of a third party (such as an
            agency client), you confirm that the third party you represent has
            read and understood the Terms of Service and Privacy Policy and that
            you’re authorized to accept them on their behalf.
          </Box>
          <Box component="h5">
            2. Personal data we collect and how do we use it
          </Box>
          <Box component="p">
            We collect information by fair and lawful means, with your knowledge
            and consent. We also let you know why we’re collecting it and how it
            will be used. You are free to refuse our request for this
            information, with the understanding that we may be unable to provide
            you with some of your desired services without it. Examples of
            personal data that we may collect from you are the following:
          </Box>
          <Box component="ul" className={classes.main}>
            <Box component="li">Name</Box>
            <Box component="li">Email</Box>
            <Box component="li">Social media profiles</Box>
            <Box component="li">Profession</Box>
            <Box component="li">Date of birth</Box>
            <Box component="li">Company</Box>
            <Box component="li">Phone/mobile number</Box>
            <Box component="li">Home/Mailing address</Box>
            <Box component="li">Work address</Box>
            <Box component="li">Record of communication</Box>
            <Box component="li">IP address</Box>
          </Box>

          <Box component="p">
            We also log “non-identifying information”. When you visit our
            website, our servers may automatically log the standard data
            provided by your web browser. This data is considered
            “non-identifying information”, as it does not personally identify
            you on its own. It may include your computer’s Internet Protocol
            (IP) address, your browser type and version, visited pages, the time
            and date of your visit, the time spent on each page, search terms,
            cookies, and other details. This data is kept for statistical
            purposes.
          </Box>

          <Box component="p">
            We may also collect data about the device you are using to access
            our website. This data may include the device type, operating
            system, unique device identifiers, device settings, and geo-location
            data. What we collect can depend on the individual settings of your
            device and software. We recommend checking the policies of your
            device manufacturer or software provider to learn what information
            they make available to us.
          </Box>

          <Box component="p">
            We use “cookies” to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your browser, and accesses each time you visit, so we can
            understand how visitors use our site. This helps us serve you
            content based on preferences you have specified. Please refer to our
            Cookie Policy for more information.
          </Box>

          <Box component="h5">3. Main purposes of processing</Box>
          <Box component="p">
            We may use a combination of identifying and non-identifying
            information to understand who our visitors are, detect errors, how
            they use our services, and how we may improve their experience of
            our website in future. It is used for organizational,
            administrative, and internal reasons, and we do not disclose the
            specifics of this information publicly, but may share aggregated and
            anonymised versions of this information, for example, in website and
            customer usage trend reports.
          </Box>
          <Box component="p">
            We use your personal data for the construction of your profile,
            which allows you to submit, collect, aggregate, or share (both
            privately or publicly) information. As you use our services, we will
            use, collect, store, record and structure this data to improve and
            personalize the experience and/or features of our website. We have a
            legitimate interest for presenting you the content that is relevant
            for you, and we show you the content based on the topics you follow
            on our website, in your personalised feed area. We may also send you
            email updates with new content that matches the topics that you
            follow. You can manage your email notifications from Zenrth
            in your account settings page. However, we cannot exclude other ways
            of data processing, if necessary for the above mentioned purposes.
            This data can be shared publicly, under your control and after your
            consent, and we give you control over it.
          </Box>
          <Box component="p">
            We will also offer as part of our site, services to connect you with
            third parties and we will only provide the personal data required
            for this communication with your consent. For any of these services
            like request form, file downloading, among others, we collect
            personal data from you, such as location and all data voluntarily
            entered by you on the user profile, in order to share this
            information with the corresponding third party.
          </Box>
          <Box component="p">
            We may use your personal details to contact you with updates about
            our website and services, along with promotional content that we
            believe may be of interest to you. If you wish to opt out of
            receiving promotional content, you can follow the “unsubscribe”
            instructions provided alongside any promotional correspondence from
            us and at your settings page. However we have a legitimate interest
            for contacting you with important updates regarding your account or
            privacy.
          </Box>
          <Box component="p">
            We may also collect and/or transfer your personal data from the
            European Economic Area (the “EEA”) outside the EEA (e.g., from our
            servers located in the United States to nodes or servers in the EEA,
            and to our offices in other countries). If we do so, we reasonably
            ensure that your rights and freedoms as a data subject are
            protected.
          </Box>
          <Box component="p">
            We may We may also collect and/or transfer your personal data from
            our servers located in India, and to our offices in other
            countries). If we do so, we reasonably ensure that your rights and
            freedoms as a data subject are protected.
          </Box>

          <Box component="h5">4. Security of your personal data</Box>
          <Box component="p">
          Zenrth uses a variety of security measures to keep your
            personal data protected from unauthorised access and disclosure.
            Zenrth processes your personal data based on the following
            principles:
          </Box>
          <Box component="ul">
            <Box component="li">
              Fairness and lawfulness: we process your personal data only after
              obtaining your consent for the processing, and for a determined
              specific purpose;
            </Box>
            <Box component="li">
              Transparency: we process your personal data in a transparent
              manner, and only with your knowledge;
            </Box>
            <Box component="li">
              Purpose limitation: your personal data is processed within the
              purposes outlined above;
            </Box>
            <Box component="li">
              Data minimization: we only process your personal data to the
              extent that it is actually needed to achieve the processing
              purposes;
            </Box>
            <Box component="li">
              <b>Accuracy:</b> we ensure that your personal data is accurate and
              kept up to date;
            </Box>
            <Box component="li">
              Storage limitation: your personal data will be processed for the
              limited period of 4 years, if no other contractual obligations
              with you allow a longer time period;
            </Box>
            <Box component="li">
              Integrity and confidentiality: we secure your personal data with
              suitable organizational and technical measures.
            </Box>
          </Box>

          <Box component="h5">5. Access to your personal data</Box>
          <Box component="p">
            Only a limited number of Zenrth employees and contractors
            have access to your data, having signed confidentiality clauses to
            protect it.
          </Box>
          <Box component="p">
            We use our best efforts not to disclose any confidential information
            to third parties. We maintains a secure site, but you should know
            that computer use may be monitored by third parties such as network
            administrators, employers, internet providers, and operation system
            & internet browser vendors, and, thus, we cannot guarantee the
            security of information you post online. You disclose all
            information online at your own risk. Please remember that this
            website may be hosted outside your home country, and your email
            communications to us may be directed to a server that is hosted
            outside your home country. Communications over the Internet may not
            be secure.
          </Box>
          <Box component="p">We use third-party services for:</Box>
          <Box component="ul">
            <Box component="li">Analytics tracking</Box>
            <Box component="li">User authentication</Box>
            <Box component="li">Comments</Box>
            <Box component="li">Forms processing</Box>
            <Box component="li">Advertising and promotion</Box>
            <Box component="li">Content marketing</Box>
            <Box component="li">Email marketing</Box>
            <Box component="li">Payment processing</Box>
          </Box>

          <Box component="p">
            These services may access our data solely for the purpose of
            performing specific tasks on our behalf. We do not share any
            personally identifying information with them without your explicit
            consent. We do not give them permission to disclose or use any of
            our data for any other purpose.
          </Box>

          <Box component="p">
            We may, from time to time, allow limited access to our data by
            external consultants and agencies for the purpose of analysis and
            service improvement. This access is only permitted for as long as
            necessary to perform a specific function. We only work with external
            agencies whose privacy policies align with ours.
          </Box>
          <Box component="p">
            We will refuse government and law enforcement requests for data if
            we believe a request is too broad or unrelated to its stated
            purpose. However, we may cooperate if we believe the requested
            information is necessary and appropriate to comply with legal
            process, to protect our own rights and property, to protect the
            safety of the public and any person, to prevent a crime, or to
            prevent what we reasonably believe to be illegal, legally
            actionable, or unethical activity.
          </Box>
          <Box component="p">
            We do not otherwise share or supply personal information to third
            parties, unless it is part of a specific service of our website and
            only with your consent.
          </Box>
          <Box component="h5">6. Storage of your data</Box>
          <Box component="p">
            The personal information we collect is stored and processed in
            United States or India, or where we or our partners, affiliates and
            third-party providers maintain facilities. We only transfer data
            within jurisdictions subject to data protection laws that reflect
            our commitment to protecting the privacy of our users.
          </Box>
          <Box component="p">
            We only retain personal information for as long as necessary to
            provide a service, or to improve our services in future. While we
            retain this data, we will protect it within commercially acceptable
            means to prevent loss and theft, as well as unauthorised access,
            disclosure, copying, use or modification. That said, we advise that
            no method of electronic transmission or storage is 100% secure, and
            cannot guarantee absolute data security.
          </Box>
          <Box component="p">
            If you request your personal information be deleted, or where your
            personal information becomes no longer relevant to our operations,
            we will erase it from our system within a reasonable timeframe.
          </Box>
          <Box component="h5">7. Third party provided content/links</Box>
          <Box component="p">
            This privacy policy only covers Zenrth's own collecting and
            handling of data. We only work with partners, affiliates and
            third-party providers whose privacy policies align with ours,
            however we cannot accept responsibility or liability for their
            respective privacy practices.
          </Box>
          <Box component="p">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
          </Box>
          <Box component="h5">8. Your privacy rights</Box>
          <Box component="p">
            As our user, you have the right to be informed about how your data
            is collected and used. You are entitled to know what data we collect
            about you, and how it is processed. You are entitled to correct and
            update any personal information about you, and to request this
            information be deleted. You may amend or remove your account
            information at any time, using the tools provided in your account
            control panel.
          </Box>
          <Box component="p">
            You are entitled to restrict or object to our use of your data,
            while retaining the right to use your personal information for your
            own purposes. You have the right to opt out of data about you being
            used in decisions based solely on automated processing.
          </Box>
          <Box component="p">
            Feel free to contact us if you have any concerns or questions about
            how we handle your data and personal information.
          </Box>
          <Box component="p">You can contact us at admin@zenrth.com</Box>
          <Box component="p">This policy is effective as of 18/01/2021.</Box>
        </Paper>
      </Box>
    </Container>
  );
}
