import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NewAddress from "../common/NewAddress";
import FormControl from "@material-ui/core/FormControl";
import { appContext } from "../../App";

import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
import validateBuilder from "./validateBuilder";
import noValidate from "./noValidate";
import useFormValidation from "../../hooks/useFormValidation";
import { addBuilder, updateBuilderStatus } from "../../utils/api";
import toastr from "toastr";
import "./BuilderForm.scss";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import { TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "#ec1c24",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  // saveBtn: {
  //   background: "#3D69B2",
  //   color: "#fff",

  //   "&:hover": {
  //     background: "#F44234",
  //   },
  // },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const BuilderForm = (props) => {
  const classes = useStyles();
  const { authUser, builderList, setBuilderList } = useContext(appContext);
  const initialState = {
    name: "",
    description:"",
    phone:"",
    status:"",
    email: "",
  };
  const [id, setId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [addressError, setAddressError] = useState("");

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const updateDefaultImageUrl = (newImageUrl) => {
    setDefaultImageUrl(newImageUrl);
    setBuilderList(
      builderList.map((builder) => {
        if (builder && builder.builderId && builder.builderId._id === id) {
          builder.builderId.defaultImageUrl = newImageUrl;
        }
        return builder;
      })
    );
  };

  useEffect(() => {
    if (defaultImageUrl) {
      updateDefaultImageUrl(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const { builderDetails } = props;

  useEffect(() => {
    if (builderDetails && builderDetails.builderAddress) {
      setAddress({
        line1: builderDetails.builderAddress.hasOwnProperty("line1")
          ? builderDetails.builderAddress.line1
          : "",
        line2: builderDetails.builderAddress.hasOwnProperty("line2")
          ? builderDetails.builderAddress.line2
          : "",
        city: builderDetails.builderAddress.hasOwnProperty("city")
          ? builderDetails.builderAddress.city
          : "",
        state: builderDetails.builderAddress.hasOwnProperty("state")
          ? builderDetails.builderAddress.state
          : "",
        zipcode: builderDetails.builderAddress.hasOwnProperty("zipcode")
          ? builderDetails.builderAddress.zipcode
          : "",
        country: builderDetails.builderAddress.hasOwnProperty("country")
          ? builderDetails.builderAddress.country
          : "",
      });
    }
    if (builderDetails) {
      setId(builderDetails._id);
      setDefaultImageUrl(builderDetails.defaultImageUrl);
      setEditMode(true);
    }
  }, [builderDetails]);

  const handleAddbuilder = () => {
    const finalValues = {};
    finalValues.userId = authUser.unique_id;
    finalValues.builderName = values.name ? values.name : builderDetails.builderName;
    finalValues.description = values.description
      ? values.description
      : builderDetails.description;
    finalValues.builderEmail = values.email
      ? values.email
      : builderDetails && builderDetails.builderEmail;
    finalValues.websiteUrl = values.website
      ? values.website
      : builderDetails && builderDetails.websiteUrl;
    finalValues.defaultImageUrl = defaultImageUrl;
    if (
      tempAddress &&
      (tempAddress.line1 || tempAddress.line2) &&
      tempAddress.city &&
      tempAddress.state &&
      tempAddress.country &&
      tempAddress.zipcode
    ) {
      finalValues.builderAddress = {
        line1: tempAddress.line1,
        line2: tempAddress.line2,
        city: tempAddress.city,
        state: tempAddress.state,
        country: tempAddress.country,
        zipcode: tempAddress.zipcode,
      };
      setAddressError("");
    } else {
      setAddressError("Please provide the full address of the Builder.");
      return;
    }
    if (editMode && builderDetails && builderDetails._id) {
      updateBuilderStatus(builderDetails._id, finalValues).then((resp) => {
        if (resp.status === 200) {
          toastr.success(resp.data.message);
          props.onSavingBuilder(builderDetails._id);
          setId(builderDetails._id);
          setBuilderList((currentBuilders) => {
            return [...currentBuilders, { builderId: resp.data }];
          });
        }
      });
    } else if (step === 1 && !editMode) {
      addBuilder(finalValues)
        .then((resp) => {
          if (resp.statusCode === 200) {
            toastr.success(resp.message);
            const builder = resp.data;
            setStep(2);
            setId(resp.data.builderId);
            setBuilderList((currentBuilders) => {
              return [
                ...currentBuilders,
                { ...builder, builderId: { _id: resp.data.builderId, ...finalValues } },
              ];
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toastr.error(
              "That Builder name is already taken. Please use a different name"
            );
          } else {
            toastr.error(error.response.data.message);
          }
        });
    } else if (step === 2 && !editMode) {
      const valuesToSave = {};
      valuesToSave.defaultImageUrl = defaultImageUrl;
      updateBuilderStatus(id, valuesToSave).then((resp) => {
        if (resp.status === 200) {
          setId(resp.data.data._id);
          setBuilderList(
            builderList.map((builder) => {
              if (builder && builder.builderId && builder.builderId._id === id) {
                builder.builderId.defaultImageUrl = defaultImageUrl;
              }
              return builder;
            })
          );
          toastr.success(resp.data.message);
          props.onSavingBuilder(id);
        }
      });
    }
  };
  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    !builderDetails ? validateBuilder : noValidate,
    handleAddbuilder
  );

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openBuilderModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openBuilderModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.orgTitle}>Builder Information</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeContent: "center",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onCloseModal} />
              </span>
            </div>
            <div className="plasma">
              {(editMode || (step === 1 && !editMode)) && (
                <form noValidate autoComplete="off">
                  <div style={{ position: "relative" }}>
                    <TextField
                      type="text"
                      required
                      className={`input-field request-title`}
                      variant="outlined"
                      placeholder="Builder name"
                      name="name"
                      value={
                        values.name
                          ? values.name
                          : builderDetails && builderDetails.builderName
                      }
                      onChange={changeHandler}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />

                    <span
                      className="title-length"
                      style={{ top: 10, padding: "0 4px" }}
                    >
                      {" "}
                      {values.name ? values.name.length : 0}/50
                    </span>
                  </div>
                  {errors && errors.name && !builderDetails && (
                    <div className="custom-error">{errors.name}</div>
                  )}
                  <div style={{ position: "relative" }}>
                    <TextareaAutosize
                      name="description"
                      variant="outlined"
                      multiline
                      rows="4"
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                      }}
                      className={`input-field request-title ${
                        errors && errors.description
                          ? "bg-red"
                          : values.description
                          ? "bg-green"
                          : "bg-normal"
                      }`}
                      placeholder="Enter Description of your builder *"
                      value={
                        values.description
                          ? values.description
                          : builderDetails && builderDetails.description
                      }
                      onChange={changeHandler}
                      maxLength={1000}
                    />
                    <span
                      className="title-length"
                      style={{ top: 10, padding: "0 4px" }}
                    >
                      {" "}
                      {values.description ? values.description.length : 0}/1000
                    </span>
                  </div>
                  {errors && errors.description && !builderDetails && (
                    <div className="custom-error">{errors.description}</div>
                  )}
                  <NewAddress value={address} onChange={handleChangeAddress} />
                  {addressError && (
                    <div className="custom-error">{addressError}</div>
                  )}
                  <TextField
                    type="text"
                    className={`input-field request-title`}
                    variant="outlined"
                    label="Builder website (Optional)"
                    name="website"
                    value={
                      values.website
                        ? values.website
                        : builderDetails && builderDetails.websiteUrl
                    }
                    onChange={changeHandler}
                  />
                  
                  <TextField
                    type="text"
                    className={`input-field request-title`}
                    variant="outlined"
                    label="Official Email Address (Optional)"
                    name="email"
                    value={
                      values.email
                        ? values.email
                        :  builderDetails &&  builderDetails. builderEmail
                    }
                    onChange={changeHandler}
                  />
                </form>
              )}
              {(editMode || (step === 2 && !editMode)) && (
                <div>
                  <p style={{ fontWeight: "600" }}>Builder Logo</p>
                  <img
                    src={
                      defaultImageUrl
                        ? defaultImageUrl
                        :  builderDetails &&  builderDetails.defaultImageUrl
                        ?  builderDetails.defaultImageUrl
                        : "/images/default-req-img.gif"
                    }
                    alt="default"
                    onClick={() => setShowingAddPhotoModal(true)}
                    style={{
                      width: "250px",
                      cursor: "pointer",
                      background: "white",
                      padding: "5px",
                      boxShadow: "0 0 3px #a5a5a5",
                      display: "block",
                    }}
                  />

                  <Button
                    className="document-btn"
                    onClick={() => setShowingAddDocumentModal(true)}
                    style={{
                      font: "500 12px Roboto",
                      padding: "20px 30px",
                      margin: "10px 0",
                      background: "transparent",
                      color: "#283E4A",
                      border: "1px solid #283E4A",
                    }}
                  >
                    <DescriptionIcon style={{ fontSize: 14, marginRight: 6 }} />{" "}
                    Upload supporting document(s)
                  </Button>
                </div>
              )}
              <Button className="red_bg txt_white" onClick={submitHandler}>
                {step === 1 && !editMode ? "Save and Continue ..." : "Done"}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="Builder"
        folder="logos"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
      />
      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="Builder"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
    </div>
  );
};

export default withRouter(BuilderForm);
