import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { appContext } from "../../App";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { LocationOn, Share } from "@material-ui/icons";
import { roundToTwoPlaces } from "../../utils/utils";
import moment from "moment";
import {
  DATE_FORMAT,
  formatNumber,
  getObjectValuesInStringFormat,
  PROPERTY_STATUS_MAP,
  STATUS_APPROVED,
} from "../../utils/utils";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withRouter } from "react-router";
import toastr from "toastr";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import "../../components/common/styles.scss";
import { favourite } from "../../utils/api";
import ShareProperty from "../Share/ShareProperty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import ContactDeveloper from "../Property/ContactDeveloper";
import ShareOnSocialMedia from "../Share/ShareOnSocialMedia";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
// import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import { Fragment } from "react";
import Img from "react-cool-img";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
const useStyles = makeStyles(() => ({
  root: {
    margin: "20px 0",
    boxShadow: "none",
  },

  buttonimg: {
    display: "grid",
    placeContent: "center",
    background: "#c5c5c5",
    width: "100%",
    minHeight: "100%",
    borderRadius: "0",
    cursor: "pointer",
  },

  cover: {
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: "150px",
    minHeight: "270px",
    cursor: "pointer",
  },

  price: {
    backgroundColor: "white",
    padding: "0 10px",
    marginTop: "10px",
  },
}));

function DetailedPropertyCard(props) {
  const classes = useStyles();
  const { property, rupee } = props;
  const { authUser, setOpenLogin } = useContext(appContext);
  const [isFavourite, setIsFavourite] = useState(
    property && property.isFavourite
  );
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const [shareIcons, setShareIcons] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (property && property.isFavourite) {
      setIsFavourite(property.isFavourite);
    }
  }, [property]);
  const convertRuppes2Doller = (amount) =>
    formatNumber((amount / rupee).toFixed(2));

  const makeFavourite = () => {
    if (authUser && authUser.unique_id) {
      const obj = {};
      obj.propertyId = property["_id"];
      obj.userId = authUser.unique_id;
      if (isFavourite === false) {
        obj.type = "favourite"; // favourite or unfavourite
      } else if (isFavourite === true) {
        obj.type = "unfavourite";
        if (props.myFavPage) {
          props.removeFromList(property?._id);
        }
      }
      favourite(obj);
      setIsFavourite((currentValue) => {
        return !currentValue;
      });
    } else {
      toastr.warning("Please signup or login to complete this action");
      setOpenLogin(true);
      setIsFavourite(property && property.isFavourite);
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const handleClickOpen = () => {
    setOpened(true);
  };
  const handleClose = () => {
    setOpened(false);
  };
  const handleContactSeller = (id) => {
    if (authUser && authUser.unique_id) {
      setOpen(true);
    } else {
      localStorage.setItem("redirectUri", `/property/${id}`);
      setOpenLogin(true);
    }
  };

  const handleUser = (property) => {
    if (property.builderId && property.builderId !== "self") {
      window.open(`/builder/${property.builderId._id}`, "blank");
    } else {
      window.open(`/user/${property.createdUserId.username}`, "blank");
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        component="section"
        className=" postCardPaper"
        elevation={0}
        style={{ position: "relative", backgroundColor: "#f8f8f8" }}
      >
        <Box className="postCard">
          <Box className={classes.root}>
            <div className="box-model">
              <div
                className={
                  property && PROPERTY_STATUS_MAP[property.status].ribbonBgColor
                }
              ></div>
              <div
                className={
                  property &&
                  PROPERTY_STATUS_MAP[property.status].ribbonTextColor
                }
              >
                {property && PROPERTY_STATUS_MAP[property.status].shortLabel}
              </div>
            </div>

            <Grid container>
              <Grid item xs={12} sm={12} md={3}>
                <Box
                  component="div"
                  onClick={() =>
                    window.open(`/property/${property._id}`, "_blank")
                  }
                >
                  <CardMedia
                    className={classes.cover}
                    image={
                      property && property.defaultImageUrl
                        ? property.defaultImageUrl
                        : "/images/sell_home.jpg"
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={9}>
                <Box p={2} mt={2}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Box>
                        <Button
                          onClick={() =>
                            window.open(`/property/${property._id}`, "_blank")
                          }
                        >
                          <Box
                            component="p"
                            variant="p"
                            fontSize="18px"
                            fontWeight="700"
                            margin="0"
                          >
                            {property.propertyName}
                          </Box>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box component="div" textAlign="right">
                        <span>
                          {isFavourite === false ? (
                            <Tooltip title="Mark as Favourite">
                              <FavoriteBorderIcon
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => makeFavourite()}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Remove from Favourites">
                              <FavoriteIcon
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => makeFavourite()}
                              />
                            </Tooltip>
                          )}
                        </span>
                        <Tooltip title="Share Property">
                          <IconButton
                            aria-label="share"
                            className="share-req-icon"
                            onClick={handleClickOpen}
                          >
                            <Share />
                          </IconButton>
                        </Tooltip>
                        <Dialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={opened}
                        >
                          <DialogTitle
                            className="red_text"
                            id="customized-dialog-title"
                            onClose={handleClose}
                          >
                            Share property
                          </DialogTitle>
                          <DialogContent dividers>
                            <Typography gutterBottom>
                              <ShareProperty
                                property={property}
                                className="share-option"
                                style={{ padding: "0", height: "50px" }}
                              />
                            </Typography>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Box variant="subtitle1" color="#747373">
                      {property &&
                        property.location &&
                        property.location.city && (
                          <span style={{ fontSize: 14, color: "#ec1c24" ,margiLeft:"6px"}}>
                            <LocationOn />
                            {getObjectValuesInStringFormat(property.location)}
                          </span>
                        )}
                    </Box>
                  </Grid>
                  {property.propertyConfiguration &&
                  property.propertyConfiguration.length === 1 ? (
                    property.propertyConfiguration.map(
                      (propertyConfig, index) => (
                        <Fragment key={index}>
                          <Box my={2}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={6}
                                sm={3}
                                style={{
                                  display:
                                    propertyConfig.cost === 0
                                      ? "none"
                                      : "inline",
                                      paddingLeft:"18px",
                                }}
                              >
                                <Box>
                                  <Box
                                    component="p"
                                    variant="p"
                                    color="#ec1c24"
                                    fontWeight="700"
                                    fontSize="18px"
                                    margin="0"
                                  >
                                    {propertyConfig.cost !== 0 && (
                                      <FontAwesomeIcon
                                        size="1x"
                                        icon={faRupeeSign}
                                      />
                                    )}{" "}
                                    {propertyConfig.cost !== 0 &&
                                      formatNumber(
                                        roundToTwoPlaces(propertyConfig.cost)
                                      )}
                                  </Box>
                                  <Box
                                    component="p"
                                    variant="body1"
                                    color="#ec1c24"
                                    fontSize="14px"
                                    fontWeight="400"
                                    marginLeft="4px"
                                  >
                                    {propertyConfig.cost !== 0 && (
                                      <>
                                        {"("}
                                        <FontAwesomeIcon
                                          size="1x"
                                          icon={faDollarSign}
                                        />
                                        {formatNumber(
                                          convertRuppes2Doller(
                                            roundToTwoPlaces(
                                              propertyConfig.cost
                                            )
                                          )
                                        )}
                                        {")"}
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                              {propertyConfig.type !== "Residential Land" && (
                                <>
                                  <Grid item xs={6} sm={3}>
                                    <Box>
                                      <Box
                                        component="p"
                                        variant="p"
                                        color="black"
                                        fontWeight="700"
                                        fontSize="18px"
                                        margin="0"
                                      >
                                        {propertyConfig.numberOfBedrooms}
                                      </Box>
                                      <Box
                                        component="p"
                                        variant="body1"
                                        color="#7E7E7E"
                                        fontSize="13px"
                                        fontWeight="400"
                                      >
                                        Bedrooms
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Box>
                                      <Box
                                        component="p"
                                        variant="p"
                                        color="black"
                                        fontWeight="700"
                                        fontSize="18px"
                                        margin="0"
                                      >
                                        {propertyConfig.numberOfBathrooms}
                                      </Box>
                                      <Box
                                        component="p"
                                        variant="body1"
                                        color="#7E7E7E"
                                        fontSize="13px"
                                        fontWeight="400"
                                      >
                                        Baths
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6} sm={3}>
                                    <Box>
                                      <Box
                                        component="p"
                                        variant="p"
                                        color="black"
                                        fontWeight="400"
                                        fontSize="18px"
                                        margin="0"
                                      >
                                        {propertyConfig.posessionStatus}
                                      </Box>
                                      <Box
                                        component="p"
                                        variant="body1"
                                        color="#2E2E2E"
                                        fontWeight="400"
                                        fontSize="13px"
                                      >
                                        Posession Status
                                      </Box>
                                    </Box>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Box>
                          {propertyConfig.type !== "Residential Land" ? (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={6}
                                sm={3}
                                style={{
                                  display:
                                    propertyConfig.cost === 0
                                      ? "none"
                                      : "inline",
                                      paddingLeft:"18px",
                                }}
                              >
                                {propertyConfig.cost !== 0 && (
                                  <FontAwesomeIcon
                                    size="1x"
                                    icon={faRupeeSign}
                                  />
                                )}

                                {propertyConfig.cost !== 0 &&
                                  propertyConfig.builtupArea &&
                                  formatNumber(
                                    roundToTwoPlaces(
                                      propertyConfig.cost /
                                        propertyConfig.builtupArea.number
                                    )
                                  )}
                                {propertyConfig.cost !== 0 && "/"}
                                {propertyConfig.cost !== 0 &&
                                  propertyConfig.builtupArea &&
                                  propertyConfig.builtupArea.units}
                              </Grid>
                              <Grid item xs={6} sm={3}>
                                <Box
                                  component="p"
                                  variant="body1"
                                  color="#2E2E2E"
                                  fontWeight="400"
                                  fontSize="13px"
                                >
                                  Built Up Area:
                                  {propertyConfig.builtupArea &&
                                    propertyConfig.builtupArea.number}{" "}
                                  {propertyConfig.builtupArea &&
                                    propertyConfig.builtupArea.units}
                                </Box>
                              </Grid>
                              <Grid item xs={6} sm={3}>
                                <Box
                                  component="p"
                                  variant="body1"
                                  color="#2E2E2E"
                                  fontWeight="400"
                                  fontSize="13px"
                                >
                                  Carpet Area:
                                  {propertyConfig.carpetArea &&
                                    propertyConfig.carpetArea.number}{" "}
                                  {propertyConfig.carpetArea &&
                                    propertyConfig.carpetArea.units}
                                </Box>
                              </Grid>
                              <Grid item xs={6} sm={3}></Grid>
                            </Grid>
                          ) : (
                            <Grid container spacing={2}>
                              {propertyConfig.cost === 0 ? (
                                <Grid
                                  item
                                  xs={6}
                                  sm={3}
                                  style={{ display: "none" }}
                                ></Grid>
                              ) : (
                                <>
                                  <Grid item xs={6} sm={3}>
                                    <FontAwesomeIcon
                                      size="1x"
                                      icon={faRupeeSign}
                                    />
                                    {propertyConfig.cost !== 0 &&
                                      propertyConfig.landArea &&
                                      formatNumber(
                                        roundToTwoPlaces(
                                          propertyConfig.cost /
                                            propertyConfig.landArea.number
                                        )
                                      )}
                                    {propertyConfig.cost !== 0 && "/"}
                                    {propertyConfig.cost !== 0 &&
                                      propertyConfig.landArea &&
                                      propertyConfig.landArea.units}
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={6} sm={3}>
                                <Box
                                  component="p"
                                  variant="body1"
                                  color="#2E2E2E"
                                  fontWeight="400"
                                  fontSize="13px"
                                >
                                  Land Area:
                                  {propertyConfig.landArea &&
                                    propertyConfig.landArea.number}{" "}
                                  {propertyConfig.landArea &&
                                    propertyConfig.landArea.units}
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </Fragment>
                      )
                    )
                  ) : (
                    <Box style={{ margin: "10px 5px" }}>
                      {property.propertyConfiguration.length === 2 ? (
                        <>
                          <Typography variant="subtitle1" component="span">
                            {property.propertyConfiguration &&
                              property.propertyConfiguration.length === 2 &&
                              property.propertyConfiguration[0].name +
                                ", " +
                                property.propertyConfiguration[1].name}
                          </Typography>
                        </>
                      ) : property.propertyConfiguration.length === 3 ? (
                        <>
                          <Typography variant="subtitle1" component="span">
                            {property.propertyConfiguration &&
                              property.propertyConfiguration.length === 3 &&
                              property.propertyConfiguration[0].name +
                                ", " +
                                property.propertyConfiguration[1].name +
                                ", " +
                                property.propertyConfiguration[2].name}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="subtitle1" component="span">
                            {property.propertyConfiguration &&
                              property.propertyConfiguration.length > 3 &&
                              property.propertyConfiguration[0].name +
                                ", " +
                                property.propertyConfiguration[1].name +
                                ", " +
                                property.propertyConfiguration[2].name +
                                " and more…"}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                  <Box style={{ padding: "20px", background: "#FFF" }}>
                    <Grid container spacing={3}>
                      {property && property.createdAt && (
                        <Grid item xs>
                          <Typography
                            variant="subtitle1"
                            component="span"
                            style={{ fontWeight: "bold" }}
                          >
                            Posted on :
                          </Typography>
                          <Typography variant="subtitle1" component="span">
                            {property &&
                              moment(property.createdAt).format(DATE_FORMAT)}
                          </Typography>
                        </Grid>
                      )}

                      {property && property.createdUserId && (
                        <Grid item xs>
                          <Typography
                            variant="subtitle1"
                            component="span"
                            style={{ fontWeight: "bold" }}
                          >
                            Posted By :
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              src={
                                property &&
                                property.builderId &&
                                property.builderId._id
                                  ? property.builderId.defaultImageUrl
                                    ? property.builderId.defaultImageUrl
                                    : "/images/orgLogo.png"
                                  : property &&
                                    property.createdUserId &&
                                    property.createdUserId.image_url
                                  ? property.createdUserId.image_url
                                  : "/images/default-profile-photo1.png"
                              }
                              aria-label="recipe"
                              className={classes.avatar}
                              style={{ cursor: "pointer" }}
                            >
                              <Img
                                src={
                                  property &&
                                  property.builderId &&
                                  property.builderId._id
                                    ? property.builderId.defaultImageUrl
                                      ? property.builderId.defaultImageUrl
                                      : "/images/orgLogo.png"
                                    : property &&
                                      property.createdUserId &&
                                      property.createdUserId.image_url
                                    ? property.createdUserId.image_url
                                    : "/images/default-profile-photo1.png"
                                }
                                alt="user-profile"
                                className="user-profile-img"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  props.history.push(
                                    `/${property.createdUserId.username}`
                                  )
                                }
                              />
                              alt="user-profile" width="100%"
                            </Avatar>
                            <Typography
                              variant="subtitle1"
                              component="span"
                              onClick={() => {
                                handleUser(property);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {property &&
                              property.builderId &&
                              property.builderId.builderName
                                ? property.builderId.builderName
                                : property.createdUserId.name &&
                                  property.createdUserId.name.first_name +
                                    " " +
                                    property.createdUserId.name.last_name}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      <Grid item sm={4} xs={12}>
                        {property &&
                        property.createdUserId &&
                        authUser &&
                        property.createdUserId._id === authUser.unique_id &&
                        property.status != STATUS_APPROVED ? (
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            className="btn-margin"
                          >
                            <Button
                              marginTop="20px"
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                props.history.push(
                                  `/sell-property/${property._id}`
                                )
                              }
                            >
                              <EditIcon color="white" /> &nbsp; Edit
                            </Button>
                          </Box>
                        ) : authUser &&
                          property.isContacted &&
                          property.createdUserId ? (
                          <Grid container>
                            <Box
                              component="p"
                              variant="p"
                              color="black"
                              fontWeight="700"
                              fontSize="13px"
                              margin="0"
                            >
                              Seller Contact Details:
                            </Box>
                            <Box
                              component="p"
                              variant="body1"
                              color="#2E2E2E"
                              fontWeight="400"
                              fontSize="13px"
                              marginLeft="5px"
                            >
                              {property.createdUserId.name.first_name +
                                " " +
                                property.createdUserId.name.last_name +
                                ",  " +
                                property.createdUserId.email +
                                ",  " +
                                property.createdUserId.phone}
                            </Box>
                          </Grid>
                        ) : property &&
                          authUser &&
                          property.createdUserId &&
                          property.createdUserId._id !== authUser.unique_id ? (
                          <Box display="flex" justifyContent="flex-end">
                            <Button
                              color="primary"
                              className="red_bg"
                              variant="contained"
                              onClick={() => handleContactSeller(property._id)}
                            >
                              Contact Seller
                            </Button>
                          </Box>
                        ) : !authUser ? (
                          <Box display="flex" justifyContent="flex-end">
                            <Button
                              color="primary"
                              className="red_bg"
                              variant="contained"
                              onClick={() => handleContactSeller(property._id)}
                            >
                              Contact Seller
                            </Button>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <ContactDeveloper
          builder={property.createdUserId}
          propertyName={property.propertyName}
          open={open}
          makeIsContacted={props.makeIsContacted}
          id={property._id}
          handleClose={() => setOpen(false)}
        />
      </Box>
    </Container>
  );
}
export default withRouter(DetailedPropertyCard);
