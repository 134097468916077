import {
  ButtonGroup,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Container,
  Grid,
  Box,
  Backdrop,
  Fade,
  Modal,
  Switch,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { appContext } from "../../App";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import CloseIcon from "@material-ui/icons/Close";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToWords } from "to-words";
import { roundToTwoPlaces, onlyNumbers } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { containsWhitespace } from "../../utils/utils";
import useFormValidation from "../../hooks/useFormValidation";
import ValidateConfiguration from "./ValidateConfiguration";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { DescriptionOutlined } from "@material-ui/icons";
import AddPhotos from "../common/AddPhotos";
import AddVideo from "../common/AddVideo";
import AddDocument from "../common/AddDocument";
import "./NewRequest.scss";
function AddPropertyConfiguration(props) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      // sdisplay: "flex",
      backgroundColor: theme.palette.background.paper,
      // border: "2px solid #000",
      width: "50%",
      overflowX: "auto",
      height: "80vh",
      boxShadow: theme.shadows[5],
      paddingBottom: "20px",
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: "0 10px",
      },
      "&:focus": {
        border: "none",
        outline: "none",
      },
    },
    plasma: {
      width: "60%",
      paddingTop: 20,
      display: "inline-block",
      padding: "25px",
    },

    configurationTitle: {
      color: "#ec1c24",
      fontSize: "18px",
      fontWeight: "500",
      textTransform: "uppercase",
      textAlign: "start",
    },
    orgType: {
      width: "100%",
    },
    saveBtn: {
      background: "#3D69B2",
      color: "#fff",

      "&:hover": {
        background: "#3D69B2",
      },
    },

    dob: {
      maxWidth: "50%",
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      border: "1px solid #ccc",
      "& div": {
        paddingLeft: 15,
        "&:before": {
          borderBottom: "none",
        },
      },
      "& label": {
        paddingLeft: 10,
        position: "absolute",
        top: "-9",
        background: "#fff",
        left: 4,
      },

      "& button": {
        outline: "none",
      },
      "& input": {
        border: "none",
      },
    },
  }));
  const classes = useStyles();

  const initialState = {
    propertyType: "Property Type",
    name: "",
    serventRoom: "",
    furnishedStatus: "Furnished Status",
    typeOfFacing: "Facing",
    builtupAreaNumber: "",
    builtupAreaUnit: "Units",
    carpetAreaNumber: "",
    carpetAreaUnit: "Units",
    landAreaNumber: "",
    landAreaUnit: "Units",
    selectedMonth: "Month",
    selectedYear: new Date().getFullYear(),
    registrationFees: "0",
    legalDocumentationFees: "0",
    stampDutyCharges: "0",
    gstCharges: "0",
    processingFees: "0",
    corpusFund: "0",
    monthlyMaintenanceCharges: "0",
    notes: "",
  };
  const types = [
    "Property Type",
    "Independent House",
    "Villa",
    "Residential Apartment",
    "Studio Apartment",
    "Commercial Floor",
    "Commercial Building",
    "Residential Land",
    "Farm House",
  ];
  const possessionData = [
    {
      id: "Underconstruction",
      value: "Under Construction",
    },
    { id: "Readytomove ", value: "Ready to Move" },
  ];
  const furnished = [
    "Furnished Status",
    "Furnished",
    "Unfurnished",
    "Semi-furnished",
  ];
  const facing = ["Facing", "East", "West", "North", "South"];
  const areaUnits = ["Units", "Sq.ft", "Sq.Yards", "Acres"];
  const months = [
    "January",
    "Febrauary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };
  const year = range(
    new Date().getFullYear() - 100,
    new Date().getFullYear() + 100
  );
  const toWords = new ToWords();
  const collection = "Property/" + props.propertyId + "/configuration";
  const [posessionStatus, setPosessionStatus] = useState("");
  const [selectionPossession, setSelectionPossession] = React.useState({
    value: "1",
  });
  const [serventRoom, setServentRoom] = useState(false);
  const [numberOfFloors, setNumberOfFloors] = useState(0);
  const [floorNumber, setFloorNumber] = useState(0);
  const [numberOfBalconies, setNumberOfBalconies] = useState(0);
  const [numberOfBathrooms, setNumberOfBathrooms] = useState(0);
  const [numberOfOpenParkingSlots, setNumberOfOpenParkingSlots] = useState(0);
  const [numberOfCoveredParkingSlots, setNumberOfCoveredParkingSlots] =
    useState(0);
  const [numberOfBedrooms, setNumberOfBedrooms] = useState(0);
  const [description, setDescription] = useState("");
  const [costPerSqUnit, setCostPerSqUnit] = useState("");
  const [state, setState] = useState({
    quantity: 0,
  });
  const [numberToWordValue, setNumberToWordValue] = useState("");
  const [property, setProperty] = useState({});
  const [cost, setCost] = useState({});
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [propertyConfiguration, setPropertyConfiguration] = useState([]);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [showingAddVideoModal, setShowingAddVideoModal] = useState(false);
  const [slugName, setSlugName] = useState("");
  const [finalValues, setFinalValues] = useState({});
  const [names, setNames] = useState([]);
  const updateSelectionOfPossession = (event, value) => {
    event.persist();
    const name = event.target.name;
    setSelectionPossession({ ...selectionPossession, [name]: value });
    setPosessionStatus(value);
  };
  function handleChangeServentRoom(event, value) {
    setServentRoom(!serventRoom);
  }

  const handleInFloors = () => {
    if (numberOfFloors === "e") setNumberOfFloors(0);
    else setNumberOfFloors(parseInt(numberOfFloors + 1));
  };
  const handleDeFloors = () => {
    if (numberOfFloors === "e") setNumberOfFloors(0);
    if (numberOfFloors <= -1) setNumberOfFloors(-1);
    else setNumberOfFloors(parseInt(parseInt(numberOfFloors) - 1));
  };
  function handleChangeFloors(event) {
    const check = event.target.value;
    if (check === "e") setNumberOfFloors(0);
    setNumberOfFloors(parseInt(event.target.value));
  }

  const handleInFloorNumber = () => {
    if (floorNumber === "e") setFloorNumber(0);
    else setFloorNumber(parseInt(parseInt(floorNumber) + 1));
  };
  const handleDeFloorNumber = () => {
    if (floorNumber === "e") setFloorNumber(0);
    if (floorNumber <= -1) setFloorNumber(-1);
    else setFloorNumber(parseInt(parseInt(floorNumber) - 1));
  };
  function handleChangeFloorNumber(event) {
    const check = event.target.value;
    if (check === "e") setFloorNumber(0);
    setFloorNumber(parseInt(event.target.value));
  }
  const handleInBalconies = () => {
    if (numberOfBalconies === "e") setNumberOfBalconies(0);
    else setNumberOfBalconies(parseInt(parseInt(numberOfBalconies) + 1));
  };
  const handleDeBalconies = () => {
    if (numberOfBalconies === "e") setNumberOfBalconies(0);
    if (numberOfBalconies <= 0) setNumberOfBalconies(0);
    else setNumberOfBalconies(parseInt(parseInt(numberOfBalconies) - 1));
  };
  function handleChangeBalconies(event) {
    const check = event.target.value;
    if (check === "e") setNumberOfBalconies(0);
    setNumberOfBalconies(parseInt(event.target.value));
  }
  const handleInBathRooms = () => {
    if (numberOfBathrooms === "e") setNumberOfBathrooms(0);
    else setNumberOfBathrooms(parseInt(parseInt(numberOfBathrooms) + 1));
  };
  const handleDeBathRooms = () => {
    if (numberOfBathrooms === "e") setNumberOfBathrooms(0);
    if (numberOfBathrooms <= 0) setNumberOfBathrooms(0);
    else setNumberOfBathrooms(parseInt(parseInt(numberOfBathrooms) - 1));
  };
  function handleChangeBathRooms(event) {
    const check = event.target.value;
    if (check === "e") setNumberOfBathrooms(0);
    setNumberOfBathrooms(parseInt(event.target.value));
  }
  function handleChangeOpenParking(event) {
    const check = event.target.value;
    if (check === "e") setNumberOfOpenParkingSlots(-1);
    setNumberOfOpenParkingSlots(parseInt(event.target.value));
  }
  const handleInOpenParking = () => {
    if (numberOfOpenParkingSlots === "e") setNumberOfOpenParkingSlots(0);
    else
      setNumberOfOpenParkingSlots(
        parseInt(parseInt(numberOfOpenParkingSlots) + 1)
      );
  };
  const handleDeOpenParking = () => {
    if (numberOfOpenParkingSlots === "e") setNumberOfOpenParkingSlots(0);
    if (numberOfOpenParkingSlots <= -1) setNumberOfOpenParkingSlots(-1);
    else
      setNumberOfOpenParkingSlots(
        parseInt(parseInt(numberOfOpenParkingSlots) - 1)
      );
  };
  const handleInCoveredParking = () => {
    if (numberOfCoveredParkingSlots === "e") setNumberOfCoveredParkingSlots(0);
    else
      setNumberOfCoveredParkingSlots(
        parseInt(parseInt(numberOfCoveredParkingSlots) + 1)
      );
  };
  const handleDeCoveredParking = () => {
    if (numberOfCoveredParkingSlots === "e") setNumberOfCoveredParkingSlots(0);
    if (numberOfCoveredParkingSlots <= 0) setNumberOfCoveredParkingSlots(0);
    else
      setNumberOfCoveredParkingSlots(
        parseInt(parseInt(numberOfCoveredParkingSlots) - 1)
      );
  };
  function handleChangeCoveredParking(event) {
    const check = event.target.value;
    if (check === "e") setNumberOfCoveredParkingSlots(0);
    setNumberOfCoveredParkingSlots(parseInt(event.target.value));
  }
  const handleInBedrooms = () => {
    if (numberOfBedrooms === "e") setNumberOfBedrooms(0);
    else setNumberOfBedrooms(numberOfBedrooms + 1);
  };
  const handleDeBedrooms = () => {
    if (numberOfBedrooms === "e") setNumberOfBedrooms(0);
    if (numberOfBedrooms <= 0) setNumberOfBedrooms(0);
    else setNumberOfBedrooms(parseInt(parseInt(numberOfBedrooms) - 1));
  };
  function handleChangeBedrooms(event) {
    const check = event.target.value;
    if (check === "e") setNumberOfBedrooms(0);
    setNumberOfBedrooms(parseInt(event.target.value));
  }

  const updateDefaultImageUrl = (defaultImageUrl) => {
    setDefaultImageUrl(defaultImageUrl);
    setErrors((currentValues) => {
      return {
        ...currentValues,
        image: "",
      };
    });
  };

  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onSavingVideo = (message) => {
    toastr.success(message);
  };

  const costChangeHandler = (e) => {
    const costValue = parseInt(e.target.value.replace(/[^0-9\.]/g, "0"))
      ? parseInt(e.target.value.replace(/[^0-9\.]/g, "0"))
      : "0";
    setState({ ...state, [e.target.name]: costValue });
    if (costValue) {
      setNumberToWordValue(toWords.convert(costValue));
    } else {
      setNumberToWordValue("");
    }

    if (costValue && values.builtupAreaNumber && values.builtupAreaUnit) {
      setCostPerSqUnit(
        roundToTwoPlaces(costValue / values.builtupAreaNumber) +
          "/" +
          values.builtupAreaUnit
      );
    } else if (costValue && values.landAreaNumber && values.landAreaUnit) {
      setCostPerSqUnit(
        roundToTwoPlaces(costValue / values.landAreaNumber) +
          "/" +
          values.landAreaUnit
      );
    } else {
      setCostPerSqUnit("");
    }
  };

  function handleConfig() {
    setActiveStep(0);
    setValues(initialState);
    setSelectionPossession({
      value: "1",
    });
    setPosessionStatus("");
    setServentRoom(false);
    setNumberOfFloors(0);
    setFloorNumber(0);
    setNumberOfBalconies(0);
    setNumberOfBathrooms(0);
    setNumberOfOpenParkingSlots(0);
    setNumberOfCoveredParkingSlots(0);
    setNumberOfBedrooms(0);
    setDescription("");
    setCostPerSqUnit("");
    setState({
      quantity: "0",
    });
    setNumberToWordValue("");
    setProperty({});
    setCost({});
    setErrors({});
    setDefaultImageUrl("");
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    loadProperty(props.propertyId);
    setPropertyConfiguration(props.propertyConfigurations);
    setNames(
      props.propertyConfigurations.map((propertyConfig) => propertyConfig.name)
    );
  }, [props.canEdit, props.index, props.propertyConfigurations]);

  const loadProperty = (id) => {
    setIsLoading(true);
    setIsLoading(false);
    if (props.canEdit) {
      setId(property._id);
      setProperty(property);
      if (propertyConfiguration && propertyConfiguration.length > 0) {
        values.propertyType =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].propertyType
            ? propertyConfiguration[props.index].propertyType
            : "Property Type";
        values.name =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].name
            ? propertyConfiguration[props.index].name
            : "";
        if (values.propertyType !== "Residential Land") {
          setSelectionPossession({
            value:
              !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].posessionStatus
                ? propertyConfiguration[props.index].posessionStatus
                : setSelectionPossession({
                    value: "1",
                  }),
          });
          setPosessionStatus(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].posessionStatus
              ? propertyConfiguration[props.index].posessionStatus
              : ""
          );

          setServentRoom(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].serventRoom
              ? propertyConfiguration[props.index].serventRoom
              : false
          );
          values.furnishedStatus =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].furnishedStatus
              ? propertyConfiguration[props.index].furnishedStatus
              : "Furnished Status";
          values.typeOfFacing =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].facing
              ? propertyConfiguration[props.index].facing
              : "Facing";
          values.builtupAreaNumber =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].builtupArea.number
              ? propertyConfiguration[props.index].builtupArea.number
              : "";
          values.builtupAreaUnit =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].builtupArea.units
              ? propertyConfiguration[props.index].builtupArea.units
              : "Units";
          values.carpetAreaNumber =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].carpetArea.number
              ? propertyConfiguration[props.index].carpetArea.number
              : "";
          values.carpetAreaUnit =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].carpetArea.units
              ? propertyConfiguration[props.index].carpetArea.units
              : "Units";
          setNumberOfFloors(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].numberOfFloors
              ? propertyConfiguration[props.index].numberOfFloors
              : 0
          );
          setFloorNumber(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].floorNumber
              ? propertyConfiguration[props.index].floorNumber
              : 0
          );
          setNumberOfBalconies(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].numberOfBalconies
              ? propertyConfiguration[props.index].numberOfBalconies
              : 0
          );
          setNumberOfBathrooms(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].numberOfBathrooms
              ? propertyConfiguration[props.index].numberOfBathrooms
              : 0
          );
          setNumberOfOpenParkingSlots(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].numberOfOpenParkingSlots
              ? propertyConfiguration[props.index].numberOfOpenParkingSlots
              : 0
          );
          setNumberOfCoveredParkingSlots(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].numberOfCoveredParkingSlots
              ? propertyConfiguration[props.index].numberOfCoveredParkingSlots
              : 0
          );
          setNumberOfBedrooms(
            !!propertyConfiguration[props.index] &&
              !!propertyConfiguration[props.index].numberOfBedrooms
              ? propertyConfiguration[props.index].numberOfBedrooms
              : 0
          );
        } else {
          values.landAreaNumber =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].landArea.number
              ? propertyConfiguration[props.index].landArea.number
              : "";
          values.landAreaUnit =
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].landArea.units
              ? propertyConfiguration[props.index].landArea.units
              : "Units";
        }
        values.selectedMonth =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].availableFrom
            ? months[
                new Date(
                  propertyConfiguration[props.index].availableFrom
                ).getMonth()
              ]
            : "Month";
        values.selectedYear =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].availableFrom
            ? new Date(
                propertyConfiguration[props.index].availableFrom
              ).getFullYear()
            : new Date().getFullYear();
        setDescription(
          !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].description
            ? propertyConfiguration[props.index].description
            : ""
        );
        setCostPerSqUnit("");
        setState({
          quantity:
            !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].cost
              ? roundToTwoPlaces(
                  parseInt(propertyConfiguration[props.index].cost)
                )
              : "0",
        });

        values.registrationFees =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].registrationFees
            ? roundToTwoPlaces(
                parseInt(propertyConfiguration[props.index].registrationFees)
              )
            : "0";
        values.legalDocumentationFees =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].legalDocumentationFees
            ? roundToTwoPlaces(
                parseInt(
                  propertyConfiguration[props.index].legalDocumentationFees
                )
              )
            : "0";
        values.stampDutyCharges =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].stampDutyCharges
            ? roundToTwoPlaces(
                parseInt(propertyConfiguration[props.index].stampDutyCharges)
              )
            : "0";
        values.gstCharges =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].gstCharges
            ? roundToTwoPlaces(
                parseInt(propertyConfiguration[props.index].gstCharges)
              )
            : "0";
        values.processingFees =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].processingFees
            ? roundToTwoPlaces(
                parseInt(propertyConfiguration[props.index].processingFees)
              )
            : "0";
        values.corpusFund =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].corpusFund
            ? roundToTwoPlaces(
                parseInt(propertyConfiguration[props.index].corpusFund)
              )
            : "0";
        values.monthlyMaintenanceCharges =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].monthlyMaintenanceCharges
            ? roundToTwoPlaces(
                parseInt(
                  propertyConfiguration[props.index].monthlyMaintenanceCharges
                )
              )
            : "0";
        values.notes =
          !!propertyConfiguration[props.index] &&
          !!propertyConfiguration[props.index].notes
            ? propertyConfiguration[props.index].notes
            : "";
        setValues({ ...values });
        setDefaultImageUrl(
          !!propertyConfiguration[props.index] &&
            !!propertyConfiguration[props.index].defaultImageUrl &&
            propertyConfiguration[props.index].defaultImageUrl
            ? propertyConfiguration[props.index].defaultImageUrl
            : defaultImageUrl
        );
      }
    } else {
      handleConfig();
    }
  };

  const handleNext = () => {
    const errors = {};
    if (activeStep === 0) {
      if (names.includes(values.name) && !props.canEdit) {
        errors.name = "This name is already taken please enter another name";
      }
      if (values.propertyType === "Property Type")
        errors.propertyType = "Please select Property Type";
      if (values.name.length === 0)
        errors.name = "Please enter a heading for this configuration";
      if (
        values.propertyType !== "Residential Land" &&
        values.furnishedStatus === "Furnished Status"
      )
        errors.furnishedStatus = "Please select furnished status";
      if (
        values.propertyType !== "Residential Land" &&
        values.builtupAreaNumber.length === 0
      )
        errors.builtupAreaNumber = "Please enter a value";
      if (
        values.propertyType !== "Residential Land" &&
        values.builtupAreaUnit === "Units"
      )
        errors.builtupAreaUnit = "Please select one";
      if (
        values.propertyType !== "Residential Land" &&
        values.carpetAreaNumber.length === 0
      )
        errors.carpetAreaNumber = "Please enter a value";
      if (
        values.propertyType !== "Residential Land" &&
        values.carpetAreaUnit === "Units"
      )
        errors.carpetAreaUnit = "Please select one";
      if (
        values.propertyType === "Residential Land" &&
        values.landAreaNumber.length === 0
      )
        errors.landAreaNumber = "Please enter a value";
      if (
        values.propertyType === "Residential Land" &&
        values.landAreaUnit === "Units"
      )
        errors.landAreaUnit = "Please select one";
      if (values.selectedMonth === "Month")
        errors.selectedMonth = "Please select a month";
      if (parseInt(values.registrationFees) < 0) {
        errors.registrationFees =
          "Please enter registration fees greater than 0";
      }
      if (parseInt(values.legalDocumentationFees) < 0) {
        errors.legalDocumentationFees =
          "Please enter legalDocumentation Fees greater than 0";
      }
      if (parseInt(values.stampDutyCharges) < 0) {
        errors.stampDutyCharges =
          "Please enter stampDutyCharges greater than 0";
      }
      if (parseInt(values.gstCharges) < 0) {
        errors.gstCharges = "Please enter gstCharges greater than 0";
      }
      if (parseInt(values.processingFees) < 0) {
        errors.processingFees = "Please enter processingFees greater than 0";
      }
      if (parseInt(values.corpusFund) < 0) {
        errors.corpusFund = "Please enter corpusFund greater than 0";
      }
      if (parseInt(values.monthlyMaintenanceCharges) < 0) {
        errors.monthlyMaintenanceCharges =
          "Please enter monthlyMaintenanceCharges greater than 0";
      }
      if (values.notes.length === 0) errors.notes = "Please enter Notes";

      if (
        values.propertyType !== "Residential Land" &&
        posessionStatus.length === 0
      )
        errors.possession = "Please select possession status";
      if (values.propertyType !== "Residential Land" && numberOfFloors === 0)
        errors.floors = "Select Number of Floors";
      if (values.propertyType !== "Residential Land" && numberOfBalconies === 0)
        errors.balconies = "Select Number of Balconies";
      if (values.propertyType !== "Residential Land" && numberOfBathrooms === 0)
        errors.bathRooms = "Select Number of BathRooms";
      if (values.propertyType !== "Residential Land" && numberOfBedrooms === 0)
        errors.bedrooms = "Select Number of BedRooms";
      if (description.length === 0)
        errors.description = "Please enter description";
      if (state.quantity == 0) errors.cost = "Please enter Selling Price";
    }
    if (activeStep === 1) {
      if (defaultImageUrl === "")
        errors.image = "Please upload atleast one image";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      // toastr.error("All fields marked with * are mandatory");
      return;
    } else {
      setErrors({});
      handleSubmit();
      setActiveStep(activeStep + 1);
    }
  };
  const handleSubmit = () => {
    if (activeStep === 0) {
      if (props.canEdit) {
        propertyConfiguration[props.index].propertyType = values.propertyType;
        propertyConfiguration[props.index].name = values.name;
        propertyConfiguration[props.index].slugName = containsWhitespace(
          values.name
        )
          ? values.name.split(" ").join("_")
          : values.name;
        setSlugName(propertyConfiguration[props.index].slugName);
        if (values.propertyType !== "Residential Land") {
          propertyConfiguration[props.index].posessionStatus = posessionStatus;
          propertyConfiguration[props.index].serventRoom = serventRoom;
          propertyConfiguration[props.index].furnishedStatus =
            values.furnishedStatus;
          propertyConfiguration[props.index].facing = values.typeOfFacing;
          propertyConfiguration[props.index].builtupArea = {
            number: values.builtupAreaNumber,
            units: values.builtupAreaUnit,
          };
          propertyConfiguration[props.index].carpetArea = {
            number: values.carpetAreaNumber,
            units: values.carpetAreaUnit,
          };
          propertyConfiguration[props.index].numberOfFloors = numberOfFloors;
          propertyConfiguration[props.index].floorNumber = floorNumber;
          propertyConfiguration[props.index].numberOfBalconies =
            numberOfBalconies;
          propertyConfiguration[props.index].numberOfBathrooms =
            numberOfBathrooms;
          propertyConfiguration[props.index].numberOfOpenParkingSlots =
            numberOfOpenParkingSlots;
          propertyConfiguration[props.index].numberOfCoveredParkingSlots =
            numberOfCoveredParkingSlots;
          propertyConfiguration[props.index].numberOfBedrooms =
            numberOfBedrooms;
        } else {
          propertyConfiguration[props.index].landArea = {
            number: values.landAreaNumber,
            units: values.landAreaUnit,
          };
        }
        propertyConfiguration[props.index].availableFrom = new Date(
          values.selectedYear,
          months.indexOf(values.selectedMonth)
        ).getTime();
        propertyConfiguration[props.index].description = description;
        propertyConfiguration[props.index].cost = roundToTwoPlaces(
          state.quantity
        );
        propertyConfiguration[props.index].registrationFees = roundToTwoPlaces(
          parseInt(values.registrationFees)
        );
        propertyConfiguration[props.index].legalDocumentationFees =
          roundToTwoPlaces(parseInt(values.legalDocumentationFees));
        propertyConfiguration[props.index].stampDutyCharges = roundToTwoPlaces(
          parseInt(values.stampDutyCharges)
        );
        propertyConfiguration[props.index].gstCharges = roundToTwoPlaces(
          parseInt(values.gstCharges)
        );
        propertyConfiguration[props.index].processingFees = roundToTwoPlaces(
          parseInt(values.processingFees)
        );
        propertyConfiguration[props.index].corpusFund = roundToTwoPlaces(
          parseInt(values.corpusFund)
        );
        propertyConfiguration[props.index].monthlyMaintenanceCharges =
          roundToTwoPlaces(parseInt(values.monthlyMaintenanceCharges));
        propertyConfiguration[props.index].notes = values.notes;
      } else {
        finalValues.propertyType = values.propertyType;
        finalValues.name = values.name;
        finalValues.slugName = containsWhitespace(values.name)
          ? values.name.split(" ").join("_")
          : values.name;
        setSlugName(finalValues.slugName);
        if (finalValues.propertyType !== "Residential Land") {
          finalValues.numberOfFloors = numberOfFloors;
          finalValues.floorNumber = floorNumber;
          finalValues.posessionStatus = posessionStatus;
          finalValues.serventRoom = serventRoom;
          finalValues.furnishedStatus = values.furnishedStatus;
          finalValues.facing = values.typeOfFacing;
          finalValues.builtupArea = {
            number: values.builtupAreaNumber,
            units: values.builtupAreaUnit,
          };
          finalValues.carpetArea = {
            number: values.carpetAreaNumber,
            units: values.carpetAreaUnit,
          };
          finalValues.numberOfBalconies = numberOfBalconies;
          finalValues.numberOfBathrooms = numberOfBathrooms;
          finalValues.numberOfOpenParkingSlots = numberOfOpenParkingSlots;
          finalValues.numberOfCoveredParkingSlots = numberOfCoveredParkingSlots;
          finalValues.numberOfBedrooms = numberOfBedrooms;
        } else {
          finalValues.landArea = {
            number: values.landAreaNumber,
            units: values.landAreaUnit,
          };
        }
        finalValues.availableFrom = new Date(
          values.selectedYear,
          months.indexOf(values.selectedMonth)
        ).getTime();
        finalValues.description = description;
        finalValues.cost = state.quantity;
        finalValues.registrationFees = roundToTwoPlaces(
          parseInt(values.registrationFees)
        );
        finalValues.legalDocumentationFees = roundToTwoPlaces(
          parseInt(values.legalDocumentationFees)
        );
        finalValues.stampDutyCharges = roundToTwoPlaces(
          parseInt(values.stampDutyCharges)
        );
        finalValues.gstCharges = roundToTwoPlaces(parseInt(values.gstCharges));
        finalValues.processingFees = roundToTwoPlaces(
          parseInt(values.processingFees)
        );
        finalValues.corpusFund = roundToTwoPlaces(parseInt(values.corpusFund));
        finalValues.monthlyMaintenanceCharges = roundToTwoPlaces(
          parseInt(values.monthlyMaintenanceCharges)
        );
        finalValues.notes = values.notes;
        setFinalValues(finalValues);
      }
    }
    if (activeStep === 1) {
      if (props.canEdit) {
        propertyConfiguration[props.index].defaultImageUrl = defaultImageUrl;
        setActiveStep(0);
      } else {
        finalValues.defaultImageUrl = defaultImageUrl;
        setFinalValues({ ...finalValues, finalValues });
        props.setPropertyConfigurations([
          ...props.propertyConfigurations,
          finalValues,
        ]);
        handleConfig();
        setFinalValues({});
        setActiveStep(0);
      }
      props.onClose();
      alert(
        "Property Configuration" +
          (props.canEdit ? " updated" : " added ") +
          " successfully but will be saved to the database only after completing all the steps."
      );
    }
  };
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, ValidateConfiguration, handleSubmit);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.isOpen}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isOpen}>
        <div className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // borderBottom: "1px solid #ccc",
              position: "sticky",
              top: "0",
              background: "white",
              zIndex: "2",
              padding: "15px 20px 10px",
              boxShadow: "0 0 9px #e8eaf5",
            }}
          >
            <h1 className={classes.configurationTitle}>
              Property Configuration
            </h1>

            <span
              style={{
                textAlign: "end",
                cursor: "pointer",
                color: "#000",
                borderRadius: "50px",
                padding: "4px",
                width: "30px",
                height: "30px",
                display: "grid",
                placeContent: "center",
              }}
              className="drop-box close-organizatin "
            >
              <CloseIcon onClick={props.onClose} />
            </span>
          </div>
          {activeStep == 0 && (
            <>
              {" "}
              <Grid container style={{ padding: "10px" }}>
                <Grid container spacing={2} style={{ padding: "10px" }}>
                  <Grid item xs={6} style={{ padding: "5px" }}>
                    <Box gutterBottom component="h6" my={2}>
                      Property Type <span style={{ color: "red" }}>*</span>
                    </Box>
                    <FormControl
                      error={errors && errors.propertyType}
                      style={{ width: "100%" }}
                    >
                      <Select
                        variant="outlined"
                        value={values.propertyType}
                        name="propertyType"
                        onChange={changeHandler}
                      >
                        {types.map((value) => {
                          return <MenuItem value={value}>{value}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText>
                        {errors && errors.propertyType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ padding: "5px" }}>
                    <Box gutterBottom component="h6" my={2}>
                      Give a heading to this configuration{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Box>
                    <FormControl
                      error={errors && errors.name}
                      style={{ width: "100%" }}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Heading of this configuration"
                        variant="outlined"
                        name="name"
                        value={values.name}
                        onChange={changeHandler}
                        disabled={props.canEdit}
                      />
                      <FormHelperText>{errors && errors.name}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                {values.propertyType !== "Residential Land" ? (
                  <>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item sm={6} xs={12} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          my={2}
                          fontWeight="bold"
                        >
                          {" "}
                          Possession Status{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl error={errors && errors.possession}>
                          <RadioGroup
                            row
                            name="value"
                            value={selectionPossession.value}
                            onChange={updateSelectionOfPossession}
                          >
                            {possessionData.map((datum) => (
                              <FormControlLabel
                                label={datum.value}
                                key={datum.id}
                                value={datum.value}
                                control={<Radio color="primary" />}
                              />
                            ))}
                          </RadioGroup>
                          <FormHelperText>
                            {errors && errors.possession}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} xs={12} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          py={2}
                          fontWeight="bold"
                        >
                          Servant Room
                        </Box>
                        <Grid item>
                          No
                          <Switch
                            checked={serventRoom}
                            name="serventRoom"
                            onChange={handleChangeServentRoom}
                            inputProps={{ "aria-label": "Servant Room" }}
                            className="am-checked"
                          />
                          Yes
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item xs={6} style={{ padding: "5px" }}>
                        <Box gutterBottom component="h6" my={2}>
                          Furnished Status{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl
                          fullWidth
                          error={errors && errors.furnishedStatus}
                        >
                          <Select
                            variant="outlined"
                            name="furnishedStatus"
                            value={values.furnishedStatus}
                            onChange={changeHandler}
                            fullWidth
                          >
                            {furnished.map((value) => {
                              return <MenuItem value={value}>{value}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText>
                            {errors && errors.furnishedStatus}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} style={{ padding: "5px" }}>
                        <Box gutterBottom component="h6" my={2}>
                          Facing
                        </Box>
                        <FormControl
                          fullWidth
                          error={errors && errors.typeOfFacing}
                        >
                          <Select
                            variant="outlined"
                            value={values.typeOfFacing}
                            onChange={changeHandler}
                            name="typeOfFacing"
                            fullWidth
                          >
                            {facing.map((value) => {
                              return <MenuItem value={value}>{value}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText>
                            {errors && errors.typeOfFacing}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item xs={6} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          py={1}
                          fontWeight="bold"
                        >
                          Builtup Area <span style={{ color: "red" }}>*</span>
                        </Box>
                        <Box style={{ marginTop: "10px" }}>
                          <FormControl
                            error={errors && errors.builtupAreaNumber}
                            style={{ marginRight: "10px" }}
                          >
                            <TextField
                              variant="outlined"
                              id="Built Up Area"
                              label="Built Up Area"
                              type="number"
                              name="builtupAreaNumber"
                              value={values.builtupAreaNumber}
                              onChange={changeHandler}
                            >
                              {values.builtupAreaNumber}
                            </TextField>
                            <FormHelperText>
                              {errors && errors.builtupAreaNumber}
                            </FormHelperText>
                          </FormControl>
                          <FormControl error={errors && errors.builtupAreaUnit}>
                            <Select
                              variant="outlined"
                              value={values.builtupAreaUnit}
                              name="builtupAreaUnit"
                              onChange={changeHandler}
                            >
                              {areaUnits.map((value) => {
                                return (
                                  <MenuItem value={value}>{value}</MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText>
                              {errors && errors.builtupAreaUnit}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          py={1}
                          fontWeight="bold"
                        >
                          Carpet Area <span style={{ color: "red" }}>*</span>
                        </Box>
                        <Box style={{ marginTop: "10px" }}>
                          <FormControl
                            error={errors && errors.carpetAreaNumber}
                            style={{ marginRight: "10px" }}
                          >
                            <TextField
                              variant="outlined"
                              id="Carpet Area"
                              //className={classes.number}
                              label="Carpet Area"
                              type="number"
                              name="carpetAreaNumber"
                              value={values.carpetAreaNumber}
                              onChange={changeHandler}
                            >
                              {values.carpetAreaNumber}
                            </TextField>
                            <FormHelperText>
                              {errors && errors.carpetAreaNumber}
                            </FormHelperText>
                          </FormControl>

                          <FormControl error={errors && errors.carpetAreaUnit}>
                            <Select
                              variant="outlined"
                              value={values.carpetAreaUnit}
                              name="carpetAreaUnit"
                              onChange={changeHandler}
                            >
                              {areaUnits.map((value) => {
                                return (
                                  <MenuItem value={value}>{value}</MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText>
                              {errors && errors.carpetAreaUnit}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item xs={12} sm={6} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          py={2}
                          fontWeight="bold"
                        >
                          Total Number Of Floors{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl error={errors && errors.floors}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeFloors}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              // className={classes.number}
                              inputProps={{ style: { textAlign: "center" } }}
                              type="number"
                              name="numberOfFloors"
                              onChange={handleChangeFloors}
                              value={numberOfFloors}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {numberOfFloors}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInFloors}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.floors}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          py={2}
                          fontWeight="bold"
                        >
                          Floor Number <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl error={errors && errors.floornumber}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeFloorNumber}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              // className={classes.number}
                              inputProps={{ style: { textAlign: "center" } }}
                              type="number"
                              name="floorNumber"
                              onChange={handleChangeFloorNumber}
                              value={floorNumber}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {floorNumber}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInFloorNumber}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.floornumber}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item xs={6} style={{ padding: "5px" }}>
                        <Box
                          component="div"
                          gutterBottom
                          my={2}
                          fontWeight="bold"
                        >
                          Bed Rooms <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl error={errors && errors.bedrooms}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeBedrooms}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              style={{ width: "100%" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              type="number"
                              name="numberOfBedrooms"
                              onChange={handleChangeBedrooms}
                              value={numberOfBedrooms}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {numberOfBedrooms}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInBedrooms}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.bedrooms}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          component="div"
                          gutterBottom
                          py={2}
                          fontWeight="bold"
                        >
                          {" "}
                          Bathrooms <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl error={errors && errors.bathRooms}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeBathRooms}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              type="number"
                              onChange={handleChangeBathRooms}
                              inputProps={{ style: { textAlign: "center" } }}
                              value={numberOfBathrooms}
                              name="numberOfBathrooms"
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {numberOfBathrooms}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInBathRooms}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.bathRooms}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item xs={6}>
                        <Box
                          component="div"
                          gutterBottom
                          my={2}
                          fontWeight="bold"
                        >
                          Balconies <span style={{ color: "red" }}>*</span>
                        </Box>
                        <FormControl error={errors && errors.balconies}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeBalconies}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              style={{ width: "100%" }}
                              //className={classes.number}
                              inputProps={{ style: { textAlign: "center" } }}
                              type="number"
                              name="numberOfBalconies"
                              onChange={handleChangeBalconies}
                              value={numberOfBalconies}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {numberOfBalconies}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInBalconies}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.balconies}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ padding: "10px" }}>
                      <Grid item xs={6}>
                        <Box
                          component="div"
                          gutterBottom
                          py={2}
                          fontWeight="bold"
                        >
                          Number of Open Parking Slots
                        </Box>
                        <FormControl error={errors && errors.OpenParking}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeOpenParking}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              inputProps={{ style: { textAlign: "center" } }}
                              type="number"
                              name="numberOfOpenParkingSlots"
                              onChange={handleChangeOpenParking}
                              value={numberOfOpenParkingSlots}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {numberOfOpenParkingSlots}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInOpenParking}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.OpenParking}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          component="div"
                          gutterBottom
                          my={2}
                          fontWeight="bold"
                        >
                          Number of Covered Parking Slots
                        </Box>

                        <FormControl error={errors && errors.CoveredParking}>
                          <ButtonGroup>
                            <Button
                              variant="outlined"
                              onClick={handleDeCoveredParking}
                              className="btn_vist"
                            >
                              -
                            </Button>
                            <TextField
                              style={{ width: "100%", textAlign: "center" }}
                              // className={classes.number}
                              inputProps={{ style: { textAlign: "center" } }}
                              type="number"
                              name="numberOfCoveredParkingSlots"
                              onChange={handleChangeCoveredParking}
                              value={numberOfCoveredParkingSlots}
                              onKeyPress={(event) => {
                                if (
                                  event?.key === "-" ||
                                  event?.key === "+" ||
                                  event?.key === "e"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            >
                              {numberOfCoveredParkingSlots}
                            </TextField>
                            <Button
                              variant="outlined"
                              onClick={handleInCoveredParking}
                              className="btn_vist"
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <FormHelperText>
                            {errors && errors.CoveredParking}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} style={{ padding: "10px" }}>
                      <Box
                        component="div"
                        gutterBottom
                        py={1}
                        fontWeight="bold"
                      >
                        Land Area <span style={{ color: "red" }}>*</span>
                      </Box>
                      <Box style={{ marginTop: "10px" }}>
                        <FormControl
                          error={errors && errors.landAreaNumber}
                          style={{ marginRight: "10px" }}
                        >
                          <TextField
                            variant="outlined"
                            id="Land Area"
                            label="Land Area"
                            type="number"
                            name="landAreaNumber"
                            value={values.landAreaNumber}
                            onChange={changeHandler}
                          >
                            {values.landAreaNumber}
                          </TextField>
                          <FormHelperText>
                            {errors && errors.landAreaNumber}
                          </FormHelperText>
                        </FormControl>

                        <FormControl error={errors && errors.landAreaUnit}>
                          <Select
                            variant="outlined"
                            value={values.landAreaUnit}
                            name="landAreaUnit"
                            onChange={changeHandler}
                          >
                            {areaUnits.map((value) => {
                              return <MenuItem value={value}>{value}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText>
                            {errors && errors.landAreaUnit}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid container style={{ padding: "10px" }}>
                  <Grid item>
                    <Box component="div" gutterBottom fontWeight="bold" pt={2}>
                      {" "}
                      Available From <span style={{ color: "red" }}>*</span>
                    </Box>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={6} className="pad-left0">
                      <FormControl
                        fullWidth
                        error={errors && errors.selectedMonth}
                      >
                        <Select
                          variant="outlined"
                          value={values.selectedMonth}
                          name="selectedMonth"
                          onChange={changeHandler}
                          fullWidth
                        >
                          <MenuItem value={"Month"}>Month</MenuItem>;
                          {months.map((value) => {
                            return <MenuItem value={value}>{value}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText>
                          {errors && errors.selectedMonth}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        error={errors && errors.year}
                        style={{ width: "100%" }}
                      >
                        <Select
                          variant="outlined"
                          value={values.selectedYear}
                          name="selectedYear"
                          onChange={changeHandler}
                        >
                          {year.map((value) => {
                            return <MenuItem value={value}>{value}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText>{errors && errors.year}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="max99"
                  style={{ padding: "10px", maxWidth: "99%" }}
                >
                  <Box gutterBottom component="div" py={2} fontWeight="bold">
                    Description About Property{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  <Box component="div" className="ck-modify">
                    <CKEditor
                      name="description"
                      data={description}
                      editor={ClassicEditor}
                      placeholder="Property Description"
                      onChange={changeDescriptionHandler}
                      config={{
                        toolbar: [
                          "Heading",
                          "bold",
                          "italic",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                          "Link",
                        ],
                      }}
                    />
                  </Box>
                  {errors.description && (
                    <div
                      style={{
                        font: "500 12px $font-regular !important",
                        color: "red",
                      }}
                    >
                      {errors && errors.description}
                    </div>
                  )}
                </Grid>
                <Box
                  fontWeight="bold"
                  gutterBottom
                  my={2}
                  style={{ padding: "0 10px" }}
                >
                  <Typography variant="outlined" component="h6">
                    Price Details(In INR){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item xs={12} style={{ padding: "5px" }}>
                    <FormControl error={errors && errors.cost}>
                      <TextField
                        fullWidth
                        id="outlined-error"
                        label="Selling Price"
                        variant="outlined"
                        name="quantity"
                        type="text"
                        value={state.quantity}
                        onChange={costChangeHandler}
                      />
                      <FormHelperText>
                        {costPerSqUnit && (
                          <>
                            {`Cost Per Unit (INR): `}
                            <FontAwesomeIcon
                              size="1x"
                              icon={faRupeeSign}
                            />{" "}
                            {costPerSqUnit}
                          </>
                        )}
                      </FormHelperText>
                      <FormHelperText>{errors && errors.cost}</FormHelperText>
                      <FormHelperText>
                        {`Selling Price (INR): `}
                        {numberToWordValue}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Box gutterBottom my={2} component="h6" fontWeight="bold">
                    Additional Charges
                  </Box>
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={6} style={{ padding: "5px" }}>
                      <FormControl
                        error={errors && errors.registrationFees}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          label="Registration Fee"
                          variant="outlined"
                          name="registrationFees"
                          value={values.registrationFees}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        />
                        <FormHelperText>
                          {errors && errors.registrationFees}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={6} style={{ padding: "5px" }}>
                      <FormControl
                        error={errors && errors.legalDocumentationFees}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          label="Legal Documentation Fee"
                          variant="outlined"
                          name="legalDocumentationFees"
                          value={values.legalDocumentationFees}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        />
                        <FormHelperText>
                          {errors && errors.legalDocumentationFees}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={6} style={{ padding: "5px" }}>
                      <FormControl
                        error={errors && errors.stampDutyCharges}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          label="Stamp Duty Charges"
                          variant="outlined"
                          name="stampDutyCharges"
                          value={values.stampDutyCharges}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        />
                        <FormHelperText>
                          {errors && errors.stampDutyCharges}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={6} style={{ padding: "5px" }}>
                      <FormControl
                        error={errors && errors.gstCharges}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          label="GST Charges"
                          variant="outlined"
                          name="gstCharges"
                          value={values.gstCharges}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        />
                        <FormHelperText>
                          {errors && errors.gstCharges}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={6} style={{ padding: "5px" }}>
                      <FormControl
                        error={errors && errors.processingFees}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          label="Processing Fee"
                          variant="outlined"
                          name="processingFees"
                          value={values.processingFees}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        />
                        <FormHelperText>
                          {errors && errors.processingFees}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={6} style={{ padding: "5px" }}>
                      <FormControl
                        error={errors && errors.corpusFund}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          label="Corpus Fund"
                          variant="outlined"
                          name="corpusFund"
                          value={values.corpusFund}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        />
                        <FormHelperText>
                          {errors && errors.corpusFund}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: "10px" }}>
                    <Grid item xs={6}>
                      <FormControl
                        error={errors && errors.monthlyMaintenanceCharges}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          variant="outlined"
                          type="text"
                          label="Maintenance Charges"
                          name="monthlyMaintenanceCharges"
                          value={values.monthlyMaintenanceCharges}
                          onChange={changeHandler}
                          onKeyDown={onlyNumbers}
                        >
                          {values.monthlyMaintenanceCharges}
                        </TextField>
                        <FormHelperText>
                          {errors && errors.monthlyMaintenanceCharges}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item xs={12} style={{ padding: "5px" }}>
                    <Box gutterBottom my={2} fontWeight="bold">
                      Notes <span style={{ color: "red" }}>*</span>
                    </Box>
                    <FormControl
                      error={errors && errors.notes}
                      style={{ width: "100%" }}
                    >
                      <TextField
                        fullWidth
                        multiline
                        id="outlined-basic"
                        label="Notes"
                        variant="outlined"
                        name="notes"
                        value={values.notes}
                        onChange={changeHandler}
                      />
                      <FormHelperText>{errors && errors.notes}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {activeStep == 1 && (
            <div style={{ margin: "40px" }}>
              <Typography variant="outlined" component="h6">
                Photos / Videos / Documents
              </Typography>
              <div className="request-step-two">
                <div>
                  <FormControl error={errors && errors.image}>
                    <Tooltip classes="req-tooltip" open={true}>
                      <img
                        src={
                          defaultImageUrl
                            ? defaultImageUrl
                            : "/images/default-req-img.gif"
                        }
                        alt="default"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          margin: "10px 0",
                        }}
                      />
                    </Tooltip>
                    <FormHelperText>{errors.image}</FormHelperText>
                  </FormControl>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Button
                          style={{
                            width: "100%",
                            font: "500 12px Roboto",
                            margin: "10px 5px",
                            padding: "20px 5px",
                            background: "transparent",
                            color: "#283E4A",
                            border: "1px solid #283E4A",
                          }}
                          onClick={() => setShowingAddPhotoModal(true)}
                        >
                          <FontAwesomeIcon
                            color="#283E4A"
                            size="1x"
                            style={{ marginRight: 6 }}
                            icon={faFileUpload}
                          />{" "}
                          Add Images
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setShowingAddVideoModal(true)}
                          style={{
                            background: "transparent",
                            color: "#283E4A",
                            border: "1px solid #283E4A",
                            font: "500 12px Roboto",
                            padding: "20px 5px",
                            margin: "10px 5px",
                            width: "100%",
                          }}
                          component="span"
                        >
                          <FontAwesomeIcon
                            color="#283E4A"
                            size="1x"
                            style={{ marginRight: 6 }}
                            icon={faFileUpload}
                          />{" "}
                          Add Videos
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          className="document-btn"
                          onClick={() => setShowingAddDocumentModal(true)}
                          style={{
                            width: "100%",
                            font: "500 12px Roboto",
                            padding: "20px 5px",
                            margin: "10px 5px",
                            background: "transparent",
                            color: "#283E4A",
                            border: "1px solid #283E4A",
                          }}
                          component="span"
                        >
                          <DescriptionOutlined
                            style={{ fontSize: 14, marginRight: 6 }}
                          />{" "}
                          Upload supporting document(s)
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <AddPhotos
                  title="Add Images"
                  isOpen={showingAddPhotoModal}
                  onClose={() => setShowingAddPhotoModal(false)}
                  uid={slugName}
                  collection={collection}
                  configurationCollection={true}
                  profilephotoURL={defaultImageUrl}
                  onProfilephotoURLChange={updateDefaultImageUrl}
                  canEdit={true}
                  multiple={true}
                />
                <AddDocument
                  title="Add Supporting Documents"
                  isOpen={showingAddDocumentModal}
                  onClose={() => setShowingAddDocumentModal(false)}
                  uid={slugName}
                  collection={collection}
                  onSavingDocument={onSavingDocument}
                  canEdit={true}
                />
                <AddVideo
                  title="Add Videos"
                  isOpen={showingAddVideoModal}
                  onClose={() => setShowingAddVideoModal(false)}
                  uid={slugName}
                  collection={collection}
                  onSavingDocument={onSavingVideo}
                  canEdit={true}
                />
              </div>
              <div>
                Accepted formats are .jpg, .gif, .png & .mp4. Maximum size
                allowed is 4 MB. Minimum dimension allowed 600*400 Pixel
              </div>
            </div>
          )}

          <Box display="flex" justifyContent="flex-end">
            {activeStep === 1 && (
              <Box mr={2}>
                <Button
                  style={{ margin: "10px" }}
                  variant="contained"
                  color="default"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Box>
            )}
            <Box>
              <Button
                className="red_bg"
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                onClick={activeStep === 0 ? handleNext : submitHandler}
              >
                {activeStep === 0
                  ? "Next"
                  : activeStep === 1 && !props.canEdit
                  ? "Save and Continue ..."
                  : "Done"}
              </Button>
            </Box>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
export default withRouter(AddPropertyConfiguration);
