import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { getAllCities, getAllCountries, getAllStates } from "../../utils/api";
import toastr from "toastr";
import { withRouter } from "react-router";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { FormHelperText, Grid, TextField } from "@material-ui/core";
import { appContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  formControl: {
    border: " 1px solid #c5c5c5",
    borderRadius: "4px",
    padding: "5px 5px 0 5px",
    "& div": {
      height: "45px",
    },
  },
  selectEmpty: {
    Width: "100%",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function IndianAddress(props) {
  const classes = useStyles();
  const { cityState } = useContext(appContext);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [extraErrors, setExtraErrors] = useState("");
  const filter = createFilterOptions();

  const handleChangeSelectedState = (event, newStateValue) => {
    if (typeof newStateValue === "string") {
      cityState.state = newStateValue;
      cityState.city = "";
    } else if (newStateValue && newStateValue.name) {
      // Create a new value from the user input
      cityState.state = newStateValue.name;
      cityState.city = "";
      getAllCities(newStateValue.id)
        .then((result) => {
          setCities(result.data);
        })
        .catch((error) => {
          toastr.error("Problem in getting states: " + error.message);
        });
    } else {
      cityState.state = newStateValue;
    }
  };

  const handleChangeSelectedCity = (event, newCityValue) => {
    if (typeof newCityValue === "string") {
      cityState.city = newCityValue;
    } else if (newCityValue && newCityValue.name) {
      // Create a new value from the user input
      cityState.city = newCityValue.name;
    } else {
      cityState.city = newCityValue;
    }
  };

  useEffect(() => {
    getAllStates(101)
      .then((result) => {
        setStates(result.data);
        if (
          result &&
          result.data &&
          result.data.length > 0 &&
          cityState.state
        ) {
          getAllCities(
            result.data.filter((state) => state.name === cityState.state)[0].id
          )
            .then((result) => {
              setCities(result.data);
            })
            .catch((error) => {
              toastr.error("Problem in getting cities: " + error.message);
            });
        }
      })
      .catch((error) => {
        toastr.error("Problem in getting states: " + error.message);
      });
  }, [cityState]);
  const handleSearch = () => {
    if (cityState.city && cityState.city.length > 0) {
      props.history.push(`/buy/${cityState.city}`);
    }
    setExtraErrors("Please enter city");
  };
  return (
    <div className={classes.root}>
      <Box
        component="div"
        className={classes.search}
        variant="contained"
        bgcolor="white"
        borderRadius="4px"
        padding=" 0 10px"
      >
        <Grid container spacing={2} className="indianaddress">
          <Grid item xs={12} sm={4} md={3}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <Autocomplete
                variant="outlined"
                value={cityState.state}
                onChange={handleChangeSelectedState}
                options={states}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically

                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="state"
                    placeholder="State"
                    value={cityState.state}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
              error={extraErrors}
            >
              <Autocomplete
                value={cityState.city}
                onChange={handleChangeSelectedCity}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `${params.inputValue}`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="city-option"
                options={cities}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.name) {
                    return option.name;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select City"
                    placeholder="Select City"
                  />
                )}
              />
              <FormHelperText>{extraErrors}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2} md={2} alignItems="center">
            <Button
              variant="contained"
              color="Primary"
              startIcon={<SearchIcon />}
              onClick={handleSearch}
              className="red_bg"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
export default withRouter(IndianAddress);
