import React from "react";
import ReactPlayer from "react-player";
import { Dialog, Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./styles.scss";

const VideoPlayer = (props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <div className="dialog-box drop-box">
        <h2 className="image-title">{props.name}</h2>
        <div className="close-image-popup">
          <IconButton
            // edge="start"
            // color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <ReactPlayer url={props.url} playing controls={true} />

      <Typography>{props.note}</Typography>
    </Dialog>
  );
};

export default VideoPlayer;
