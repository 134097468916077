import React, { useContext, useEffect, useRef, useState } from "react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PhoneNumber from "../Phone/PhoneNumber";
import validateSeller from "./validateSeller";
import "./Developer.scss";
import { InputLabel } from "@material-ui/core";
import { createNotification, getUserData, getProperty } from "../../utils/api";
import Box from "@material-ui/core/Box";

function ContactDeveloper(props) {
  const { authUser } = useContext(appContext);
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const phoneRef = useRef();

  const initialState = {
    sellerName: "",
    buyerName: "",
    buyerEmail: "",
    sellerEmail: "",
    sellerPhone: "",
    propertyName: "",
    propertyId: "",
  };

  const SellerHandler = () => {
    // validate the phone input
    if (!phoneRef.current.isValid(phone)) {
      return;
    }
    createNotification(values.propertyId)
      .then((response) => {
        if (response.data) {
          props.handleClose();
          props.makeIsContacted(values.propertyId);
          toastr.success("Contact Details sent successfully");
        }
      })
      .catch((error) => {
        toastr.error("Can not continue. " + error.message);
      });
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateSeller, SellerHandler);

  useEffect(() => {
    if (props.builder) {
      setValues({
        sellerName:
          props.builder &&
          props.builder.name &&
          props.builder.name.first_name + " " + props.builder.name.last_name,
        buyerName:
          authUser &&
          authUser.name &&
          authUser.name.first_name + " " + authUser.name.last_name,
        sellerEmail: props.builder && props.builder.email,
        buyerEmail: authUser && authUser.email,
        sellerPhone: props.builder && props.builder.phone,
        propertyName: props.propertyName,
        propertyId: props.id,
      });
    }
    authUser &&
      authUser.unique_id &&
      getUserData(authUser.unique_id)
        .then((resp) => {
          setPhone(resp.phone);
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [props.builder, authUser, props.id]);

  useEffect(() => {
    getProperty(props.id)
      .then((response) => {
        setImage(response.defaultImageUrl);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [props.id]);

  //useEffect(() => {}, [authUser]);
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
      maxWidth="sm"
    >
      <div className="developer-container">
        <div className="login-title" gutterBottom>
          <Grid component="div" container className="builderinfo">
            <Grid item component="div" xs={12} sm={12} md={3}>
              <img
                src={image ? image : "/images/sell_home.jpg"}
                alt=" "
                width="100%"
                style={{ borderRadius: "5px" }}
              ></img>
            </Grid>
            <Grid item component="div" xs={12} sm={12} md={9}>
              <Box px={2}>
                <Box component="span">
                  {" "}
                  {props.builder &&
                    props.builder.name &&
                    props.builder.name.first_name +
                      " " +
                      props.builder.name.last_name}
                </Box>
                <Box component="h6">{props.propertyName}</Box>
                <Box component="h6">
                  {props.builder && props.builder.phone.slice(0, -10)}
                  {"-"}
                  {props.builder && props.builder.phone.slice(-10, -8)}
                  {"XXXXXX"}
                  {props.builder && props.builder.phone.slice(-2)}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <IconButton
            aria-label="close"
            className="close-btn"
            onClick={props.handleClose}
            style={{ top: "5px", right: "5px" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box component="p" fontSize="14px" fontWeight="bold" color="#000">
          Share your contact details
        </Box>
        <Grid>
          <Grid item>
            <div className="right">
              <div className="signIn-box drop-box">
                <div style={{ width: "100%", margin: "10px 0" }}>
                  <TextField
                    name="buyerName"
                    style={{ width: "100%" }}
                    placeholder="Username"
                    value={values.buyerName}
                    onChange={changeHandler}
                    label="Name"
                    variant="outlined"
                    onKeyPress={(e) => handleEnterClick(e)}
                    className={`${errors.buyerName && "error-text"}`}
                    id="buyerName"
                  />
                  {errors.buyerName ? (
                    <span className="validation-error">{errors.buyerName}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ width: "100%", margin: "10px 0" }}>
                  <TextField
                    name="buyerEmail"
                    style={{ width: "100%" }}
                    placeholder="Email address"
                    value={values.buyerEmail}
                    onChange={changeHandler}
                    label="Email address"
                    variant="outlined"
                    onKeyPress={(e) => handleEnterClick(e)}
                    className={`${errors.buyerEmail && "error-text"}`}
                    id="buyerEmail"
                    disabled
                  />
                  {errors.buyerEmail ? (
                    <span className="validation-error">
                      {errors.buyerEmail}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{ width: "100%", margin: "10px 0" }}
                  className="phonecls"
                >
                  <InputLabel>Phone</InputLabel>
                  <PhoneNumber
                    phone={phone}
                    setPhone={setPhone}
                    isPhoneVerified={authUser && authUser.phone_verified}
                    ref={phoneRef}
                  />
                </div>
                <Box
                  component="small"
                  fontSize="14px"
                  display="flex"
                  justifyContent="center"
                >
                  I Agree to Zenrth{"  "} &nbsp;
                  <a href="/termsOfUse"> Terms & Conditions</a>
                </Box>
                <Button
                style={{marginBottom:"20px", alignItems:"center"}}
                   className="submit-button"
                  variant="contained"
                  color="primary"
                   className="red_bg txt_white"
                  type="submit"
                  onClick={submitHandler}
                >
                  Contact Seller
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
export default ContactDeveloper;
