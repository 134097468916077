import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: " 20px 0",
    main: {
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
    },
    "& p": {
      padding: "5px 2px",
    },

    "& h5": {
      padding: "10px 0",
    },

    "& ul": {
      padding: "5px 0 5px 20px",
      paddingLeft:"15px",
    },

    "& li": {
      padding: "10px 0",
    },
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>
        <Paper elevation={2} style={{ padding: "10px 20px" }}>
          <Box
            component="h5"
            textAlign="center"
            fontSize="1.8rem"
            bgcolor="#f8f8f8"
            color="primary.main"
          >
           Terms & Conditions

          </Box>
          <Box component="h5"> 1. INTRODUCTION AND ACCEPTANCE</Box>
          <Box component="p" pl={2}>
          Welcome to  zenrth.com (“Website”) is an interactive online service 
          operated by Plataforma Networks (“us”, “we”, or “our”).

          </Box>
          <Box component="p">
          PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE WEBSITE. 
          BY ACCESSING AND/OR USING THE WEBSITE (OTHER THAN TO READ THESE TERMS OF USE FOR THE FIRST TIME) YOU ARE AGREEING TO COMPLY WITH THESE TERMS OF USE,
           WHICH MAY CHANGE FROM TIME TO TIME. 
           THESE TERMS OF USE REQUIRE YOU TO AGREE TO ARBITRATE DISPUTES RATHER THAN GOING TO COURT,
            GRANT US CERTAIN RIGHTS AND LICENSES,
             PROVIDE US CERTAIN INDEMNITIES, WAIVE CERTAIN OF YOUR RIGHTS AND REMEDIES, AND
              LIMIT OUR LIABILITY AND OBLIGATIONS TO YOU. READ THEM CAREFULLY AND DO NOT USE THE SITE 
              OR PURCHASE OUR PRODUCTS OR SERVICES IF YOU DO NOT AGREE.

          </Box>
          <Box component="p">
          You agree that these Terms of Use are supported by good and valuable consideration the receipt and 
          sufficiency of which you hereby acknowledge. Such consideration includes, without limitation, your use of the Website and the materials and information available on the same.
           In addition to these Terms of Use, Zenrth has established a Privacy Policy to explain how user information is collected and used by Zenrth. 
           A copy of this Privacy Policy can be found here, and is incorporated by reference into these Terms of Use.
            By accessing or using the Website, you are signifying your acknowledgement and agreement
             to Zenrth's Privacy Policy.
          </Box>

          <Box component="h5">
          2. INTELLECTUAL PROPERTY
          </Box>
           <Box component="p">
           The Website and included content (and any derivative works or enhancements of the same) including,
            but not limited to, all text, illustrations, files, images, software, scripts, graphics, photos, 
            sounds, music, videos, information, content, materials, products, services, URLs, technology,
             documentation, and interactive features (collectively, the “Website Content”) and all intellectual
              property rights to the same are owned by us, our licensors, or both. Additionally, all trademarks, 
              service marks, trade names and trade dress that may appear on the Website are owned by us, our licensors,
               or both. Except for the limited use rights granted to you in these Terms of Use, you shall not acquire
                any right, title or interest in the Website or any Website Content. Any rights not expressly granted in
                 these Terms of Use are expressly reserved.

           </Box>
           
           <Box component="h5">3. WEBSITE ACCESS AND USE</Box>

           <Box component="p">
           1. Access to the Website including, without limitation, 
           the Website Content is provided for your information and personal, non-commercial use only. 
           When using the Website, you agree to comply with all applicable federal, state, and local laws of USA 
           and India including, without limitation copyright law. Except as expressly permitted in these Terms of Use,
            you may not use, reproduce, distribute, create derivative works based upon, publicly display, 
            publicly perform, publish, transmit, or otherwise exploit Website Content for any purpose 
            whatsoever without obtaining prior written consent from us or, in the case of third-party content,
             its respective owner. In certain instances, we may permit you to download or print Website Content or both. In such a case, you may download or print (as applicable) one copy of Website Content for your personal, non-commercial use only. 
             You acknowledge that you do not acquire any ownership rights by downloading or printing Website Content.


           </Box>
           <Box component="p">
           2. Furthermore, except as expressly permitted in these Terms of Use, you may not:
             </Box>
          
          <Box component="ul">
                <Box component="li">
                Remove, alter, cover, or distort any copyright, trademark, or other proprietary 
                rights notice on the Website or Website Content;

                </Box>
                <Box component="li">
                Circumvent, disable or otherwise interfere with security-related features 
                of the Website including, without limitation, any features that prevent or restrict use or
                copying of any content or enforce limitations on the use of the Website or Website Content;

                </Box>
            <Box component="li">
                Use an automatic device (such as a robot or spider) or manual process to copy or “scrape” the Website 
              or Website Content for any purpose without the express written permission of Zenrth. 
              Notwithstanding the foregoing, Zenrth grants public search engine operators permission to use
              automatic devices (such as robots or spiders) to copy Website Content from the Website for 
              the sole purpose of creating (and only to the extent necessary to create) a searchable index of Website
                Content that is available to the public.
              We reserve the right to revoke this permission (generally or specifically) at any time;
              you are deemed notified of such processing</Box>
              <Box component="li">
                Collect or harvest any personally identifiable information from the Website including,
                without limitation, user names, passwords, email addresses;

            </Box>
            <Box component="li">
                Solicit other users to join or become members of any commercial online service or
                other organization without our prior written approval;

            </Box>
            <Box component="li">
              Attempt to or interfere with the proper working of the Website or impair,
              overburden, or disable the same;
            </Box>
         
          <Box component="li">
            Decompile, reverse engineer, or disassemble any portion of any the Website;

            </Box>
            <Box component="li">
               Use network-monitoring software to determine architecture of or extract usage data from 
              the Website;

            </Box>

              <Box component="li">
              Encourage conduct that violates any local, state or federal law, 
              either civil or criminal, or impersonate another user, person,
              or entity (e.g., using another person’s Membership (as defined in Section 5(B)) 
              without permission, etc.);
             </Box>

            <Box component="li">
                Engage in any conduct that restricts or inhibits any other user from 
                using or enjoying the Website.
            </Box>
            </Box>
          
          <Box component="p">
           3. You agree to cooperate fully with Zenrth to investigate any suspected or actual activity 
           that is in breach of these Terms of Use.            
             </Box>
           
          <Box component="h5">
          4. CONDITIONS FOR LINKING TO WEBSITE

          </Box>
          <Box component="p">
            We hereby grant you a non-exclusive, limited license, revocable at our discretion,
           for you to link to the Website home page from any site you own or control that is not commercially 
           competitive with the Website and does not criticize or otherwise injure us, so long as the site where
            the link resides, and all other locations to which such site links, comply with all applicable laws and
             do not in any way abuse, defame, stalk, threaten or violate the rights of privacy, publicity, 
             intellectual property or other legal rights of others or, in any way, post, publish, distribute,
              disseminate or facilitate any inappropriate, infringing, defamatory, profane, indecent, 
              obscene or illegal/unlawful information, topic, name or other material or that violates 
              the spirit of our mission. Such a link is not an endorsement of such other site(s) by us.
               All of our rights and remedies are expressly reserved.
               </Box>
                
          <Box component="h5">
          5. WEBSITE CONTENT & THIRD PARTY LINKS

          </Box>
 
          <Box component="p">
          1. We provide the Website including, without limitation Website Content for entertainment, 
          educational and promotional purposes only. You may not rely on any information and opinions 
          expressed on any of our Website for any other purpose. In all instances, it is your responsibility 
          to evaluate the accuracy, timeliness, completeness, or usefulness of Website Content. 
          Under no circumstances will we be liable for any loss or damage caused by your reliance on 
          any Website Content.

          </Box>
          <Box component="p">
          2. In many instances, Website Content will include content posted by a third-party or will 
          represent the opinions and judgments of a third-party. We do not endorse, warrant and are not 
          responsible for the accuracy, timeliness, completeness, or reliability of any opinion, advice, 
          or statement made on the Website by anyone other than authorized employees or spokespersons
           while acting in their official capacities.

          </Box>
          <Box component="p">
          3. If there is a dispute between persons accessing the Website or between persons accessing 
          the Website and any third party, you understand and agree that we are under no obligation to 
          become involved. If there is such a dispute, you hereby release Zenrth and its officers, 
          directors, employees, parents, partners, successors, agents, affiliates, subsidiaries,
           and their related companies from claims, demands, and damages of every kind or nature arising out of,
            relating to, or in any way connected with such dispute.
          </Box>
          <Box component="p">
          4. The Website may contain links to other websites maintained by third parties. We do not operate or control, in any respect, or necessarily endorse the content found on these third-party websites. You assume sole responsibility for your use of third-party links. We are not responsible for any content posted on third-party websites or liable to you for 
          any loss or damage of any sort incurred as a result of your dealings with any third-party or their website.

          </Box>
          <Box component="p">
          5. The materials appearing on Zenrth's website could include technical, typographical, 
          or photographic errors. Zenrth does not warrant that any of the materials on its website are 
          accurate, complete or current. Zenrth may make changes to the materials contained on its website
           at any time without notice.
           However Zenrth does not make any commitment to update the materials.
          </Box>
           

          <Box component="h5"> 6. MINIMUM AGE</Box>
          <Box component="p">
          No one under 16 is allowed to create an account in our site.

          </Box>
          <Box component="h5"> 7. MODIFICATION  </Box>
          <Box component="p">
          Zenrth may revise these terms of service for its website at any time without notice.
            By using this website you are agreeing to be bound by the then current version of these
              terms of service.
          </Box>

          <Box component="h5"> 8. GOVERNING LAW</Box>

          <Box component="p">
          These terms and conditions are governed by and construed in accordance with the laws of California,
           USA for users of USA and laws of India for users of India and you irrevocably 
           submit to the exclusive jurisdiction of the courts in that State or location.


          </Box>           
               
           
        </Paper>
      </Box>
    </Container>
  );
}
