import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button } from "@material-ui/core";
import { postFeedback } from "../../utils/api";
import { appContext } from "../../App";
import { HOME_PAGE } from "../../utils/utils";
import toastr from "toastr";
import Container from "@material-ui/core/Container";
import "./ContactUs.scss";

const ContactUs = (props) => {
  const { authUser } = useContext(appContext);
  const [rating, setRating] = useState(5);
  const [descError, setDescError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [type, setType] = React.useState("comments");
  const [values, setValues] = useState({
    email: "",
    fullName: "",
    description: "",
  });
  const [description, setDescription] = useState("");

  const handleRating = (e) => {
    setRating(e.target.value);
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const handleFeedback = (e) => {
    if (authUser === null) {
      const { name, value } = e.target;
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      if (e.target.name === "description") {
        setDescription(e.target.value);
        setDescError("");
      }
    }
  };

  const handleSubmitFeedback = () => {
    let feedback = {};
    let isValid = true;
    if (authUser !== null) {
      feedback.userFullName = `${authUser.name.first_name} ${authUser.name.last_name}`;
    } else {
      feedback.userFullName = values.fullName;
    }
    feedback.subject = `[Zenrth] Feedback from ${feedback.userFullName}`;
    if (authUser === null) {
      if (values.description === "") {
        setDescError("Please share your thoughts");
        isValid = false;
      }
      const isEmail = /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/;
      if (values.email === "") {
        setEmailError("Please enter Email Address");
        isValid = false;
      } else if (!isEmail.test(values.email)) {
        setEmailError("Please enter a valid Email Address");
        isValid = false;
      }
      if (values.fullName === "") {
        setNameError("Please enter your Full Name");
        isValid = false;
      }
    } else {
      if (description === "") {
        setDescError("Please share your thoughts");
        isValid = false;
      }
    }
    if (isValid === true) {
      feedback.recipientEmailIds = [process.env.REACT_APP_SUPPORT_EMAIL_ID];
      feedback.content = `Dear Admin,<br /><br />
          Here is the feedback from ${feedback.userFullName}.<br /><br /><br />
          User Name: ${
            authUser === null ? feedback.userFullName : authUser.username
          }, <br /> User mail Id: ${
        authUser === null ? values.email : authUser.email
      }, <br /><br />
          Rating: ${rating}, <br/> Feedback Type: ${type}, <br/> Feedback Content: ${
        authUser && authUser !== null ? description : values.description
      }<br /><br /><br />
          Thank you,<br />
          Zenrth`;

      postFeedback(feedback)
        .then((response) => {
          if (response && response.statusCode === 200) {
            toastr.success("Feedback submitted successfully");
            props.history.push(HOME_PAGE);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };
  return (
    <Container maxWidth="lg">
      <div className="feedback-container drop-box">
        <h1>Contact Us</h1>
        <p style={{ borderBottom: "1px solid #ccc" }}>
          We would love to hear your thoughts, concerns or problems with
          anything so we can improve!
        </p>
        <Box
          component="fieldset"
          className="feedback-rating"
          mb={3}
          borderColor="transparent"
        >
          <Typography component="legend" className="rating-text">
            Please rate our application:{" "}
          </Typography>
          <Rating
            name="half-rating"
            size="large"
            onChange={(e) => handleRating(e)}
            defaultValue={rating}
            precision={0.5}
          />
        </Box>
        <FormControl component="fieldset" className="feedback-type">
          <FormLabel component="legend" className="rating-text">
            Feedback type:
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={type}
            onChange={handleChange}
          >
            <FormControlLabel
              value="comments"
              control={<Radio />}
              label="Comments"
            />
            <FormControlLabel
              value="bug reports"
              control={<Radio />}
              label="Bug Reports"
            />
            <FormControlLabel
              value="questions"
              control={<Radio />}
              label="Questions"
            />
          </RadioGroup>
        </FormControl>
        <Typography
          component="legend"
          className="rating-text"
          style={{ marginTop: 10 }}
        >
          Provide your feedback <span style={{ color: "red" }}>*</span>
        </Typography>
        {authUser === null ? (
          <Typography>
            <TextField
              name="email"
              placeholder="Email Address"
              onChange={handleFeedback}
              variant="outlined"
              //onKeyPress={(e) => handleEnterClick(e)}
              style={{
                width: "60%",
                margin: "15px 0",
                background: "white",
              }}
              id="email"
            />
            {emailError && (
              <Typography className="custom-error">{emailError}</Typography>
            )}
            <TextField
              name="fullName"
              placeholder="Full Name"
              onChange={handleFeedback}
              variant="outlined"
              //onKeyPress={(e) => handleEnterClick(e)}
              style={{
                width: "60%",
                margin: "15px 0",
                background: "white",
              }}
              id="fullName"
            />
            {nameError && (
              <Typography className="custom-error">{nameError}</Typography>
            )}
          </Typography>
        ) : (
          ""
        )}
        <TextareaAutosize
          aria-label="minimum height"
          className="textarea"
          rowsMin={5}
          name="description"
          onChange={handleFeedback}
          //onKeyPress={(e) => handleEnterClick(e)}
          placeholder="Share your thoughts here..."
        />
        {descError && (
          <Typography className="custom-error">{descError}</Typography>
        )}
        <Button className="feedback-btn red_bg" onClick={handleSubmitFeedback}>
          Submit
        </Button>
      </div>
    </Container>
  );
};
export default withRouter(ContactUs);
