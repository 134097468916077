import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  home_img: {
    backgroundColor: "white",
    // height:"448px",
    borderRadius: "4px",
    position: "absolute",
    top: "60%",
    zIndex: "1",
    textAlign: "center",
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },

    "& h1": {
      fontSize: "calc(18px + 1.5vw)",
    },

    "& p": {
      color: "#414141",
      fontWaite: "500",
    },
  },
}));

function Help(props) {
  const classes = useStyles();
  return (
    <Box component="div" bgcolor="white" minHeight="650px">
      <Container>
        <Box
          component="div"
          className={classes.help}
          p={{ xs: 0, sm: 0, md: 2 }}
        >
          <Box
            component="h5"
            py={4}
            textAlign="center"
            fontSize="calc(15px + 1.5vw)"
          >
            See how the Zenrth can help you
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6}  sm={12}>
              <Box component="div" position="relative">
                <img
                  src="/images/sell_home.jpg"
                  alt="Sell A Home"
                  width="100%"
                />
                <Box
                  component="div"
                  className={classes.home_img}
                  mx={{ xs: 0, sm: 0, md: 4 }}
                >
                  <Box component="h1">Sell a home</Box>
                  <Box component="p">
                    If “sell my house” is at the top of your to-do list, you’ve
                    landed on the right page.
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.history.push("/sell-property")}
                    className="red_bg"
                  >
                    Sell a home
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item md={6}  sm={12}>
              <Box component="div" position="relative">
                <img src="/images/buy_home.jpg" alt="Buy A Home" width="100%" />
                <Box
                  component="div"
                  className={classes.home_img}
                  mx={{ xs: 0, sm: 0, md: 4 }}
                >
                  <Box component="h1">Buy a home</Box>
                  <Box component="p">
                    With quality photos, genuine sellers, Buying your dream home
                    is now easy!{" "}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.history.push("/buy")}
                    className="red_bg"
                  >
                    Find a home
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
export default withRouter(Help);
