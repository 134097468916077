import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import toastr from "toastr";
import {
  createProperty,
  getAmenities,
  getProperty,
  updateProperty,
} from "../../utils/api";
import { appContext } from "../../App";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import NewAddress from "../common/NewAddress";
import AddVideo from "../common/AddVideo";
import AddDocument from "../common/AddDocument";
import { DescriptionOutlined } from "@material-ui/icons";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToWords } from "to-words";
import "./NewRequest.scss";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { roundToTwoPlaces } from "../../utils/utils";
import AddPhotos from "../common/AddPhotos";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddPropertyConfiguration from "./AddPropertyConfiguration";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BuilderForm from "../Builder/BuilderForm";

const useStyles = makeStyles((theme) => ({
  tabmenu: {
    backgroundColor: "rgb(246, 247, 251)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: " flex-start",
      gap: "5px",
    },
  },
  menu: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function SellProperty(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [property, setProperty] = useState({});
  const [configuration, setConfiguration] = useState({});
  const steps = getSteps();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const toWords = new ToWords();

  function getSteps() {
    return [
      "Property Details",
      "Property Configuration",
      "Location",
      "Photos / Videos / Documents",
    ];
  }

  //const year = ["Year", "2019", "2020", "2021", "2022", "2023", "2024"];
  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };

  const transactiondata = [
    {
      id: "Newly Built",
      value: "Newly Built",
    },
    { id: "Resale ", value: "Resale" },
  ];

  //const amenitiesData = ["ClubHouse", "Power Backup"];

  const [amenities, setAmenities] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isAllSelected =
    amenitiesData.length > 0 && amenities.length === amenitiesData.length;
  const [type, setType] = useState("Property Type");

  const [selectionTransaction, setSelectionTransaction] = React.useState({
    value: "1",
  });
  const [selectionOccupancy, setSelectionOccupancy] = React.useState({
    value: "1",
  });

  useEffect(() => {
    loadAmenities();
  }, []);

  const loadAmenities = () => {
    setIsLoading(true);
    getAmenities()
      .then((response) => {
        setIsLoading(false);
        if (response) {
          setAmenitiesData(response);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Amenities:" + error.message);
      });
  };
  const [extraErrors, setExtraErrors] = useState({});
  const [transactionType, setTransactionType] = useState("");
  const [ageOfProperty, setAgeOfProperty] = useState(0);
  const [commonDescription, setCommonDescription] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [numberOfBlocks, setNumberOfBlocks] = useState("");
  const [reraId, setReraId] = useState("");
  const [propertyConfigurations, setPropertyConfigurations] = useState([]);
  const [index, setIndex] = useState(0);
  const [openBuilderModal, setOpenBuilderModal] = useState(false);

  const onCloseAddConfiguration = () => {
    setAddConfigurationModal(false);
  };

  const handleBuilder = () => {
    setOpenBuilderModal(true);
  };

  const onSavingBuilder = (builderId) => {
    setOpenBuilderModal(false);
    // props.history.push(`/builder/${builderId}`);
  };

  const handleInAgeOfProperty = () => {
    if (ageOfProperty === "e") setAgeOfProperty(0);
    else setAgeOfProperty(ageOfProperty + 1);
  };
  const handleDeAgeOfProperty = () => {
    if (ageOfProperty === "e") setAgeOfProperty(0);
    if (ageOfProperty <= 0) setAgeOfProperty(0);
    else setAgeOfProperty(ageOfProperty - 1);
  };

  const updateSelectionOfTransaction = (event, value) => {
    event.persist();
    const name = event.target.name;
    setSelectionTransaction({ ...selectionTransaction, [name]: value });
    setTransactionType(value);
  };

  const handleChangeAmenities = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setAmenities(
        amenities.length === amenitiesData.length ? [] : amenitiesData
      );
      return;
    }
    setAmenities(value);
  };

  function handleChangeAgeOfProperty(event) {
    const check = event.target.value;
    if (check === "e") setAgeOfProperty(0);
    if (parseInt(check)) setAgeOfProperty(parseInt(event.target.value));
    else setAgeOfProperty(event.target.value);
  }

  const changeCommonDescriptionHandler = (e, editor) => {
    setCommonDescription(editor.getData());
  };

  const [tempAddress, setTempAddress] = useState(null);
  const [address, setAddress] = useState(null);
  const [state, setState] = useState({
    name: "",
    locality: "",
    landmark: "",
    quantity: "",
    units: "",
  });
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [builder, setBuilder] = useState("");

  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [showingAddVideoModal, setShowingAddVideoModal] = useState(false);
  const [exclusiveChecked, setExclusiveChecked] = useState(true);
  const [authorityChecked, setAuthorityChecked] = useState(true);
  const [addConfigurationModal, setAddConfigurationModal] = useState(false);
  const collection = "Property";
  const handleExclusiveChecked = (event) => {
    setExclusiveChecked(event.target.checked);
  };

  const handleAuthorityChecked = (event) => {
    setAuthorityChecked(event.target.checked);
  };

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onSavingVideo = (message) => {
    toastr.success(message);
  };
  const updateDefaultImageUrl = (defaultImageUrl) => {
    setDefaultImageUrl(defaultImageUrl);
    setExtraErrors((currentValues) => {
      return {
        ...currentValues,
        image: "",
      };
    });
  };

  const changeBuilder = (event) => {
    setBuilder(event.target.value);
  };
  const handleNext = () => {
    const property = {};
    property.propertyName = propertyName;
    property.amenities = amenities;
    property.description = commonDescription;
    property.newlyConstructed = transactionType;
    property.numberOfBlocks = numberOfBlocks;
    property.reraId = reraId;
    setProperty(property);

    const location = {};
    location.landmark = state.landmark;
    location.locality = state.locality;
    location.line1 = tempAddress && tempAddress.line1;
    location.line2 = tempAddress && tempAddress.line2;
    location.city = tempAddress && tempAddress.city;
    location.state = tempAddress && tempAddress.state;
    location.country = tempAddress && tempAddress.country;
    location.zipcode = tempAddress && tempAddress.zipcode;

    setLocation(location);
    setAddress(tempAddress);

    if (activeStep === 1) {
      property.propertyConfiguration = propertyConfigurations;
      setProperty(property);
    }

    const extraErrors = {};

    if (activeStep === 0) {
      if (propertyName === undefined || propertyName === "")
        extraErrors.propertyname = "Please enter Property Name";
      if (builder === "") extraErrors.builder = "Please select Builder";
      if (transactionType.length === 0)
        extraErrors.transactiontype = "Please select type of Transaction";
      if (numberOfBlocks.length === 0)
        extraErrors.numberofblocks = "Please enter Number Of Blocks";
      if (amenities.length === 0)
        extraErrors.amenitiesErr = "Please select atleast one amenities";
      if (transactionType === "Resale" && ageOfProperty === 0)
        extraErrors.ageofproperty = "Select Age of Property";
      if (commonDescription.length === 0)
        extraErrors.commondesc = "Please enter description";
    } else if (activeStep === 2) {
      if (state.locality === "") extraErrors.locality = "Please enter Locality";
      if (
        !tempAddress.city ||
        !tempAddress.state ||
        !tempAddress.country ||
        !tempAddress.zipcode
      )
        extraErrors.address = "Please select Country, State, City and Zipcode";
    } else if (activeStep === 3) {
      if (defaultImageUrl === "")
        extraErrors.image = "Please upload atleast one image";
    }
    setExtraErrors(extraErrors);

    if (extraErrors && Object.keys(extraErrors).length > 0) {
      // toastr.error("All fields marked with * are mandatory");
      return;
    }
    setActiveStep(activeStep + 1);
    window.scrollTo(0, 0);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleAddMoreConfiguration = () => {
    setAddConfigurationModal(true);
    setEditModeConfig(false);
  };

  const handleAddConfiguration = () => {
    setAddConfigurationModal(true);
    setEditModeConfig(false);
  };

  const handleEditConfiguration = (ind) => {
    setAddConfigurationModal(true);
    setEditModeConfig(true);
    setIndex(ind);
  };
  const handleDeleteConfiguration = (ind) => {
    if (
      window.confirm(
        "Are you sure you want to delete the property configuration?"
      )
    ) {
      setPropertyConfigurations(
        propertyConfigurations.filter((config, index1) => index1 != ind)
      );
      toastr.success("Property Configuration deleted successfully");
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box className="amenities">
            <Grid container spacing={2}>
              <Box gutterBottom component="h5" my={2}>
                Property Details
              </Box>
              <Grid item xs={12} sm={12} md={5}></Grid>

              <Grid item xs={12} sm={12} md={5}>
                <Box my={2}>
                  <FormControl
                    error={extraErrors && extraErrors.propertyname}
                    style={{ width: "100%" }}
                  >
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Building/Society/Project Name *"
                      variant="outlined"
                      name="propertyName"
                      value={propertyName}
                      onChange={(e) => setPropertyName(e.target.value)}
                    />
                    <FormHelperText>{extraErrors.propertyname}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  className="recipient"
                  error={extraErrors && extraErrors.builder}
                  disabled={editMode}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select/Add Builder <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={builder}
                    onChange={changeBuilder}
                    variant="outlined"
                    placeholder="Builder"
                    name="builder"
                    label="Builder"
                  >
                    <MenuItem value="self">Self</MenuItem>
                    {builderList &&
                      builderList.length > 0 &&
                      builderList.map((builder) => {
                        return (
                          <MenuItem
                            value={
                              builder &&
                              builder.builderId &&
                              builder.builderId._id
                            }
                          >
                            <img
                              src={
                                builder &&
                                builder.builderId &&
                                builder.builderId.defaultImageUrl
                                  ? builder.builderId.defaultImageUrl
                                  : "/images/orgLogo.png"
                              }
                              alt="orgLogo"
                              width="20px"
                              style={{ marginRight: 8 }}
                            />{" "}
                            {builder &&
                              builder.builderId &&
                              builder.builderId.builderName}{" "}
                            <span
                              style={{
                                display: "inline-block",
                                width: "100%",
                                textAlign: "end",
                              }}
                            >
                              {builder &&
                              builder.builderId &&
                              builder.builderId.status === 1 ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <ErrorOutlineIcon style={{ color: "red" }} />
                              )}
                            </span>
                          </MenuItem>
                        );
                      })}
                    <MenuItem value="add" onClick={handleBuilder}>
                      Add Builder
                    </MenuItem>
                  </Select>{" "}
                  <FormHelperText>{extraErrors.builder}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box component="div" gutterBottom my={2} fontWeight="bold">
                  Transaction Type <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl error={extraErrors && extraErrors.transactiontype}>
                  <RadioGroup
                    row
                    name="value"
                    value={selectionTransaction.value}
                    onChange={updateSelectionOfTransaction}
                  >
                    {transactiondata.map((datum) => (
                      <FormControlLabel
                        label={datum.value}
                        key={datum.id}
                        value={datum.value}
                        control={<Radio color="primary" />}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText>{extraErrors.transactiontype}</FormHelperText>
                </FormControl>
              </Grid>
              {selectionTransaction.value === "Resale" && (
                <Grid item xs={12} sm={12} md={3}>
                  <Box component="div" gutterBottom py={2} fontWeight="bold">
                    Age of Property(in Years)
                  </Box>
                  <FormControl error={extraErrors && extraErrors.ageofproperty}>
                    <ButtonGroup>
                      <Button
                        variant="outlined"
                        onClick={handleDeAgeOfProperty}
                        className="btn_vist"
                      >
                        -
                      </Button>
                      <TextField
                        // className={classes.number}
                        type="number"
                        onChange={handleChangeAgeOfProperty}
                        inputProps={{ style: { textAlign: "center" } }}
                        value={ageOfProperty}
                        onKeyPress={(event) => {
                          if (
                            event?.key === "-" ||
                            event?.key === "+" ||
                            event?.key === "e"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      >
                        {ageOfProperty}
                      </TextField>
                      <Button
                        variant="outlined"
                        onClick={handleInAgeOfProperty}
                        className="btn_vist"
                      >
                        +
                      </Button>
                    </ButtonGroup>
                    <FormHelperText>{extraErrors.ageofproperty}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Box gutterBottom component="h6" my={2}>
                  Number of Blocks <span style={{ color: "red" }}>*</span>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl
                  error={extraErrors && extraErrors.numberofblocks}
                  style={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    type="number"
                    label="Number of Blocks"
                    variant="outlined"
                    name="numberOfBlocks"
                    value={numberOfBlocks}
                    onChange={(e) =>
                      setNumberOfBlocks(parseInt(e.target.value))
                    }
                  />
                  <FormHelperText>{extraErrors.numberofblocks}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  error={extraErrors && extraErrors.reraId}
                  style={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="RERA ID (Optional)"
                    variant="outlined"
                    name="reraid"
                    value={reraId}
                    onChange={(e) => setReraId(e.target.value)}
                  />
                  <FormHelperText>{extraErrors.reraId}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={12} md={12} xs={12}>
                <Grid item sm={12} md={4} className="amenities">
                  <Box component="h6" my={2}>
                    Amenities <span style={{ color: "red" }}>*</span>
                  </Box>
                  <FormControl
                    style={{ width: "100%" }}
                    // className={classes.formControl}
                    error={extraErrors && extraErrors.amenitiesErr}
                  >
                    <Select
                      fullWidth
                      variant="outlined"
                      labelId="amenities-label"
                      multiple
                      value={amenities}
                      onChange={handleChangeAmenities}
                      renderValue={(amenities) =>
                        _(amenities.join(", ")).truncate()
                      }
                    >
                      <MenuItem
                        value="all"
                        // classes={{
                        //   root: isAllSelected ? classes.selectedAll : "",
                        // }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            // classes={{ indeterminate: classes.indeterminateColor }}
                            checked={isAllSelected}
                            indeterminate={
                              amenities.length > 0 &&
                              amenities.length < amenitiesData.length
                            }
                            className="am-checked"
                          />
                        </ListItemIcon>
                        <ListItemText
                          //  classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {amenitiesData.map((amenity) => (
                        <MenuItem key={amenity} value={amenity}>
                          <ListItemIcon>
                            <Checkbox
                              checked={amenities.indexOf(amenity) > -1}
                              className="am-checked"
                            />
                          </ListItemIcon>
                          <ListItemText primary={amenity} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{extraErrors.amenitiesErr}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ maxWidth: "99%" }}
                className="max99"
              >
                <Box gutterBottom component="div" py={2} fontWeight="bold">
                  Description About Property{" "}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box component="div" className="ck-modify">
                  <CKEditor
                    data={commonDescription}
                    editor={ClassicEditor}
                    placeholder="Property Description"
                    onChange={changeCommonDescriptionHandler}
                    config={{
                      toolbar: [
                        "Heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "Link",
                      ],
                    }}
                  />
                </Box>
                {extraErrors.commondesc && (
                  <div className="custom-error">{extraErrors.commondesc}</div>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Box
                    className="red_bg"
                    component="button"
                    height="45px"
                    width="200px"
                    borderTop="1px solid gray"
                    borderRadius="4px"
                    color="white"
                    style={{
                      // backgroundColor: "#3D69B2",
                      outline: "none",
                      border: "none",
                    }}
                    variant="filled"
                    onClick={handleNext}
                  >
                    Next
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <BuilderForm
              openBuilderModal={openBuilderModal}
              onCloseModal={(e) => setOpenBuilderModal(false)}
              onSavingBuilder={onSavingBuilder}
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Grid item xs={12} sm={12} md={12}>
              {propertyConfigurations && propertyConfigurations.length > 0 ? (
                <>
                  <Box display="flex" justifyContent="flex-end">
                    <Box
                      component="button"
                      height="45px"
                      width="225px"
                      borderTop="1px solid gray"
                      borderRadius="4px"
                      color="white"
                      className="red_bg txt_white"
                      style={{
                        outline: "none",
                        border: "none",
                      }}
                      variant="filled"
                      onClick={handleAddMoreConfiguration}
                    >
                      + Add More Configurations
                    </Box>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table aria-label="all playlists table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Property Type</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {propertyConfigurations.map(
                          (propertyConfiguration, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {propertyConfiguration.name}
                              </TableCell>
                              <TableCell>
                                {propertyConfiguration.propertyType}
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Edit This Property">
                                  <IconButton
                                    className="del-btn"
                                    onClick={() =>
                                      handleEditConfiguration(index)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>{" "}
                                <Tooltip title="Delete This Property">
                                  <IconButton
                                    className="del-btn"
                                    onClick={() =>
                                      handleDeleteConfiguration(index)
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ padding: "10px" }}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <Box mr={2}>
                        <Button
                          variant="contained"
                          color="default"
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          className="red_bg"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Box display="flex" justifyContent="flex-end">
                    <Box
                      className="red_bg"
                      component="button"
                      height="45px"
                      width="200px"
                      borderTop="1px solid gray"
                      borderRadius="4px"
                      color="white"
                      style={{
                        // backgroundColor: "#3D69B2",
                        outline: "none",
                        border: "none",
                      }}
                      variant="filled"
                      onClick={handleAddConfiguration}
                    >
                      + Add Configuration
                    </Box>
                  </Box>
                </>
              )}

              {id && (
                <AddPropertyConfiguration
                  propertyId={id}
                  title="Property Configuration"
                  isOpen={addConfigurationModal}
                  onClose={onCloseAddConfiguration}
                  onSavingDocument={onSavingDocument}
                  canEdit={editModeConfig}
                  index={index}
                  propertyConfigurations={propertyConfigurations}
                  setPropertyConfigurations={setPropertyConfigurations}
                />
              )}
            </Grid>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6" component="h6">
              Property Location
            </Typography>

            <Box my={2}>
              <FormControl
                error={extraErrors && extraErrors.locality}
                style={{ width: "100%" }}
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Locality *"
                  variant="outlined"
                  name="locality"
                  value={state.locality}
                  onChange={handleChange}
                />
                <FormHelperText>{extraErrors.locality}</FormHelperText>
              </FormControl>
            </Box>

            <Box my={2}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Landmark"
                variant="outlined"
                name="landmark"
                value={state.landmark}
                onChange={handleChange}
              />
            </Box>

            <Grid item xs={12} sm={12} md={12}>
              <Box my={2} className="address">
                <FormControl error={extraErrors && extraErrors.address}>
                  <NewAddress value={address} onChange={handleChangeAddress} />
                  <FormHelperText>{extraErrors.address}</FormHelperText>
                </FormControl>
              </Box>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  className="red_bg"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        );
      case 3:
        return (
          <div>
            <Typography variant="outlined" component="h6">
              Photos / Videos / Documents
            </Typography>
            <div className="request-step-two">
              <div>
                <FormControl error={extraErrors && extraErrors.image}>
                  <Tooltip classes="req-tooltip" open={true}>
                    <img
                      src={
                        defaultImageUrl
                          ? defaultImageUrl
                          : "/images/default-req-img.gif"
                      }
                      alt="default"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        margin: "10px 0",
                      }}
                    />
                  </Tooltip>
                  <FormHelperText>{extraErrors.image}</FormHelperText>
                </FormControl>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        style={{
                          width: "100%",
                          font: "500 12px Roboto",
                          margin: "10px 5px",
                          padding: "20px 5px",
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                        }}
                        onClick={() => setShowingAddPhotoModal(true)}
                      >
                        <FontAwesomeIcon
                          color="#283E4A"
                          size="1x"
                          style={{ marginRight: 6 }}
                          icon={faFileUpload}
                        />{" "}
                        Add Images
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowingAddVideoModal(true)}
                        style={{
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                          font: "500 12px Roboto",
                          padding: "20px 5px",
                          margin: "10px 5px",
                          width: "100%",
                        }}
                        component="span"
                      >
                        <FontAwesomeIcon
                          color="#283E4A"
                          size="1x"
                          style={{ marginRight: 6 }}
                          icon={faFileUpload}
                        />{" "}
                        Add Videos
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        className="document-btn"
                        onClick={() => setShowingAddDocumentModal(true)}
                        style={{
                          width: "100%",
                          font: "500 12px Roboto",
                          padding: "20px 5px",
                          margin: "10px 5px",
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                        }}
                        component="span"
                      >
                        <DescriptionOutlined
                          style={{ fontSize: 14, marginRight: 6 }}
                        />{" "}
                        Upload supporting document(s)
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <AddPhotos
                title="Add Images"
                isOpen={showingAddPhotoModal}
                onClose={() => setShowingAddPhotoModal(false)}
                uid={id}
                collection={collection}
                profilephotoURL={defaultImageUrl}
                onProfilephotoURLChange={updateDefaultImageUrl}
                canEdit={true}
                multiple={true}
              />
              <AddDocument
                title="Add Supporting Documents"
                isOpen={showingAddDocumentModal}
                onClose={() => setShowingAddDocumentModal(false)}
                uid={id}
                collection={collection}
                onSavingDocument={onSavingDocument}
                canEdit={true}
              />
              <AddVideo
                title="Add Videos"
                isOpen={showingAddVideoModal}
                onClose={() => setShowingAddVideoModal(false)}
                uid={id}
                collection={collection}
                onSavingDocument={onSavingVideo}
                canEdit={true}
              />
            </div>
            <div>
              Accepted formats are .jpg, .gif, .png & .mp4. Maximum size allowed
              is 4 MB. Minimum dimension allowed 600*400 Pixel
            </div>
            <div>
              <FormControl
                required
                error={!exclusiveChecked}
                component="fieldset"
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exclusiveChecked}
                      onChange={handleExclusiveChecked}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="I am posting this property 'exclusively' on Zenrth"
                />
                {!exclusiveChecked && (
                  <FormHelperText>Please check the checkbox</FormHelperText>
                )}
              </FormControl>
              <FormControl
                required
                error={!authorityChecked}
                component="fieldset"
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={authorityChecked}
                      onChange={handleAuthorityChecked}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="I am the owner/I have the authority to post this property."
                />
                {!authorityChecked && (
                  <FormHelperText>Please check the checkbox</FormHelperText>
                )}
              </FormControl>

              <Box textAlign="right">
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleBack}
                  style={{ padding: 10, margin: 10 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="red_bg"
                  onClick={handleNext}
                  style={{ padding: 10, margin: 10 }}
                  disabled={!(exclusiveChecked && authorityChecked)}
                >
                  {props.match.params.id ? "Update" : "Create"}
                </Button>
              </Box>
            </div>
          </div>
        );
    }
  }

  const { authUser, builderList, setBuilderList } = useContext(appContext);
  const [editMode, setEditMode] = useState(false);
  const [editModeConfig, setEditModeConfig] = useState(false);
  const [id, setId] = useState("");
  const [location, setLocation] = useState({});
  const [defaultImageUrl, setDefaultImageUrl] = useState("");

  useEffect(() => {
    let finalValues = {};
    finalValues.createdUserId = authUser && authUser.unique_id;
    if (activeStep === 1 && !editMode) {
      //save property
      finalValues = { ...finalValues, ...property };
      finalValues.propertyName = propertyName;
      finalValues.builderId = builder;
      finalValues.status = -2; // means incomplete
      createProperty(finalValues)
        .then((response) => {
          if (response.data) {
            setId(response.data._id);
            setEditMode(true);
          }
        })
        .catch((error) => {
          toastr.error("Can not continue. " + error.message);
        });
    } else if (editMode) {
      if (activeStep === 1) {
        finalValues = { ...finalValues, ...property };
      } else if (activeStep === 2) {
        finalValues = { ...finalValues, ...property };
      } else if (activeStep === 3) {
        finalValues["location"] = location;
      } else if (activeStep === 4) {
        finalValues.status = 0;
        finalValues["defaultImageUrl"] = defaultImageUrl;
      }
      updateProperty(finalValues, id)
        .then(() => {
          if (activeStep === 4) {
            toastr.success(
              "Property Sell Request is" +
                (props.match.params.id ? " updated" : " created ") +
                " successfully."
            );
            props.history.push(`/menubar/properties`);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  }, [activeStep]);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (props.match.params.id) {
      loadProperty(props.match.params.id);
    }
  }, [props.match.params.id, activeStep]);

  const loadProperty = (id) => {
    setIsLoading(true);
    getProperty(id)
      .then((property) => {
        setIsLoading(false);
        if (property) {
          setEditMode(true);
          setId(property._id);
          setPropertyName(property.propertyName);
          setNumberOfBlocks(property.numberOfBlocks);
          setReraId(property.reraId);
          setSelectionTransaction({
            value: property.newlyConstructed,
          });
          setBuilder(property.builderId && property.builderId._id);
          setCommonDescription(property.description);
          setTransactionType(property.newlyConstructed);
          setAmenities(property.amenities);
          setPropertyConfigurations(property.propertyConfiguration);
          setAddress({
            line1: property.location && property.location.line1,
            line2: property.location && property.location.line2,
            city: property.location && property.location.city,
            state: property.location && property.location.state,
            country: property.location && property.location.country,
            zipcode: property.location && property.location.zipcode,
          });
          setState({
            locality: property.location && property.location.locality,
            landmark: property.location && property.location.landmark,
          });

          setDefaultImageUrl(property.defaultImageUrl);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching property:" + error.message);
      });
  };

  return (
    <Box my={3}>
      <Container maxWidth="lg">
        <Grid container className={classes.root} spacing={2}>
          <Grid item sm={12} md={9}>
            <Box className={classes.root} p={2} bgcolor="white">
              <Box
                variant="h6"
                component="h6"
                backgroundColor="white"
                fontSize="24px"
                fontWeight="bold"
                py={1}
              >
                Sell Your property
              </Box>
              <Stepper
                activeStep={activeStep}
                style={{ background: "transparent" }}
                alternativeLabel
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>{getStepContent(activeStep)}</div>
            </Box>
          </Grid>
          <Grid item sm={12} md={3}>
            <Box p={2} bgcolor="white">
              <Box variant="h6" component="h4" mb={1} fontWeight="bold">
                Post a high quality listing
              </Box>
              <p>
                to connnect with more buyers, make sure you are adding genuine
                images and share detailed information about the property
              </p>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default withRouter(SellProperty);
