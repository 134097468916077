import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  app: {
    backgroundColor: "#29393E",
    // height:"448px",
    borderRadius: "10px",
    color: "white",

    "& h1": {
      fontSize: "calc(18px + 1.4vw)",
    },

    "& p": {
      "&:nth-child(3)": {
        fontSize: "18px",
      },
      "&:nth-child(2)": {
        fontSize: "18px",
        lineHeight: "30px",
      },
    },
  },
}));

export default function App() {
  const classes = useStyles();
  return (
    <Box component="div" bgcolor="white" py={2}>
      <Container>
        <Box component="div" className={classes.app}>
          <Grid container>
            <Grid item md={6}>
              <Box
                px={{ xs: 4, sm: 4, md: 8 }}
                py={{ xs: 4, sm: 4, md: 8 }}
                className="downloadapp"
              >
                <h1>Download the Zenrth App</h1>
                <p>
                  Download the app to manage your projects, keep track of the
                  progress and complete tasks without procastinating. Stay on
                  track and complete on time!
                </p>
                <Box component="p" className="getapp">
                  Get the App
                </Box>
                <Box
                  component="a"
                  href="https://play.google.com/store/apps/details?id=com.fiveelementhomes.app&hl=en_IN&gl=US"
                  target="_blank"
                  mr="5px"
                >
                  <img src="/images/google_play_store.svg" alt="Google Play" />
                </Box>
                <Box
                  component="a"
                  href="https://apps.apple.com/in/app/5elementhomes/id1608378733"
                  target="_blank"
                >
                  <img src="/images/app_store.svg" alt="App Store" />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box
                component="div"
                display={{ xs: "none", sm: "none", md: "block" }}
              >
                <img src="/images/phone.png" width="100%" alt="App" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
