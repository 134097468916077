import React, { createContext, useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "./App.css";
import Routes from "./routes";
import { blue, indigo, red } from "@material-ui/core/colors";
import { getUserLocaleInfo, getBuilderList, getCurrentTime } from "./utils/api";
import axios from "axios";
import Loader from "./components/common/Loader";
import toastr from "toastr";
import { AVATAR_DONOR, AVATAR_DONEE, rupeeValue } from "./utils/utils";

export const appContext = createContext();

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
    primary: {
      main: red[700],
    },
  },
  typography: {
    fontFamily: ['"Roboto"', "sans-serif"].join(","),
  },
});

const App = () => {
  const [authUser, setAuthUser] = useState(null);
  const [cityState, setcityState] = useState({
    state: "",
    city: "",
  });
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [fpOpen, setfpOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(2023);
  const [isFinAdmin, setIsFinAdmin] = useState(false);
  const [isVolunteer, setIsVolunteer] = useState(false);
  const [userLocaleInfo, setUserLocaleInfo] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [region, setRegion] = useState(null);
  const [avatars, setAvatars] = useState([]);
  const [avatar, setAvatar] = useState(AVATAR_DONOR);
  const [rupee, setRupee] = useState(0);
  const [builderList, setBuilderList] = useState([]);
  const [builderUserPayload, setBuilderUserPayload] = useState(null);
  const [builderUnRegisteredUser, setBuilderUnRegisteredUser] = useState(false);
  const [inviteGeneralUserPayload, setInviteGeneralUserPayload] =
    useState(null);
  const [generalUnRegisteredUser, setGeneralUnRegisteredUser] = useState(false);


  useEffect(() => {
    rupeeValue().then((result) => {
      setRupee(result);
    });
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        setIsWaitingForResponse(false);
        return response;
      },
      (error) => {
        setIsWaitingForResponse(false);
        if (error.response.status === 401) {
          if (
            error.response.hasOwnProperty("config") &&
            error.response.config.hasOwnProperty("url") &&
            (error.response.config.url.indexOf("/login") >= 0 ||
              error.response.config.url.indexOf("/change/user/password") >= 0 ||
              error.response.config.url.indexOf("/verifyotp") >= 0 ||
              error.response.config.url.indexOf("/forgot-password") >= 0 ||
              error.response.config.url.indexOf("/user/feedback") >= 0 ||
              error.response.config.url.indexOf("/resendotp") >= 0)
          ) {
            return Promise.reject(error);
          }
          localStorage.removeItem("authUser");
          setAuthUser(null);
          //toastr.warning("Please signup or login to complete this action.");
          // window.location = "/login";
          setOpenSignup(true);
        } else {
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    setAvatars([AVATAR_DONOR, AVATAR_DONEE]);
    setAvatar(AVATAR_DONOR);
  }, []);

  useEffect(() => {
    getUserLocaleInfo()
      .then((response) => {
        setUserLocaleInfo(response);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    var parsedAuthUser;
    if (!authUser) {
      const localAuthUser = localStorage.getItem("authUser");
      if (localAuthUser) {
        parsedAuthUser = JSON.parse(localAuthUser);
        setAuthUser(parsedAuthUser);
      }
    } else {
      parsedAuthUser = authUser;
    }
    if (parsedAuthUser && parsedAuthUser.roles) {
      parsedAuthUser.roles.forEach((role) => {
        if (role === "admin") {
          setIsAdmin(true);
        } else if (role === "volunteerAdmin") {
          setIsVolunteer(true);
        } else if (role === "superAdmin") {
          setIsSuperAdmin(true);
        } else if (role === "finAdmin") {
          setIsFinAdmin(true);
        }
      });
    }
    axios.interceptors.request.use(
      (config) => {
        if (config) {
          if (config.data) {
            if (config.data.dontShowLoader) {
            } else {
              setIsWaitingForResponse(true);
            }
          } else if (config.params) {
            if (config.params.dontShowLoader) {
            } else {
              setIsWaitingForResponse(true);
            }
          } else {
            setIsWaitingForResponse(false);
          }
        }
        if (config) {
          if (config.data) {
            if (config.data.dontSendToken) {
            } else if (parsedAuthUser) {
              config.headers["Authorization"] =
                "Bearer " + parsedAuthUser.token_detail.token;
            }
          } else if (config.params) {
            if (config.params.dontSendToken) {
            } else if (parsedAuthUser) {
              config.headers["Authorization"] =
                "Bearer " + parsedAuthUser.token_detail.token;
            }
          } else {
            if (parsedAuthUser) {
              config.headers["Authorization"] =
                "Bearer " + parsedAuthUser.token_detail.token;
            }
          }
        }
        return config;
      },
      (error) => {
        setIsWaitingForResponse(false);
        return Promise.reject(error);
      }
    );
    if (authUser && authUser.unique_id) {
      getBuilderList(authUser.unique_id)
        .then((resp) => {
          setBuilderList(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
    getCurrentTime()
    .then((resp) => {
      const year = new Date(resp).getFullYear();
      setCurrentYear(year);
    })
    .catch((e) => {
      console.log(e.message);
    });
    //loadSummaryData();
    // if (authUser !== null && authUser) {
    //   getUserNotifications(authUser.unique_id)
    //     .then((response) => {
    //       setUserNotifications(response);
    //     })
    //     .catch((error) => {
    //       toastr.error(error && error.data && error.data.message);
    //     });
    // }
    // if (authUser && authUser.unique_id) {
    //   getOrganizationList(authUser.unique_id)
    //     .then((resp) => {
    //       setOrgList(resp);
    //     })
    //     .catch((error) => {
    //       toastr.error(error.message);
    //     });

    //   getMyContacts(authUser.unique_id)
    //     .then((resp) => {
    //       setContacts(resp);
    //     })
    //     .catch((error) => {
    //       toastr.error(error.message);
    //     });
    //   getTALDonorsContacts(
    //     authUser.unique_id,
    //     selectedOrganization && selectedOrganization._id
    //   )
    //     .then((resp) => {
    //       setTalContacts(resp);
    //     })
    //     .catch((error) => {
    //       toastr.error(error.message);
    //     });
    //   getMyContactGroups(authUser.unique_id)
    //     .then((resp) => {
    //       setContactGroups(resp);
    //     })
    //     .catch((error) => {
    //       toastr.error(error.message);
    //     });
    // }
  }, [authUser]);

  return (
    <div style={{ background: "#f6f7fb" }}>
      <ThemeProvider theme={theme}>
        <appContext.Provider
          value={{
            authUser,
            setAuthUser,
            cityState,
            setcityState,
            openLogin,
            setOpenLogin,
            openSignup,
            setOpenSignup,
            fpOpen,
            setfpOpen,
            userLocaleInfo,
            isSuperAdmin,
            setIsAdmin,
            setIsSuperAdmin,
            isAdmin,
            isFinAdmin,
            isVolunteer,
            region,
            setRegion,
            avatars,
            avatar,
            setAvatar,
            rupee,
            setRupee,
            builderList,
            setBuilderList,
            builderUserPayload,
            setBuilderUserPayload,
            builderUnRegisteredUser,
            setBuilderUnRegisteredUser,
            generalUnRegisteredUser,
            setGeneralUnRegisteredUser,
            inviteGeneralUserPayload,
            setInviteGeneralUserPayload,
            currentYear,
            setCurrentYear,
          }}
        >
          <Routes />
          <Loader
            isOpen={isWaitingForResponse}
            onClose={() => setIsWaitingForResponse(false)}
          />
        </appContext.Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
