import React, { useEffect } from "react";
import { getUserOrBuilderName } from "../utils/api";
import toastr from "toastr";

const PublicProfileLandingPage = (props) => {
  const loadUserOrBuilder = (id) => {
    getUserOrBuilderName(id)
      .then((response) => {
        if (response && response.redirectUrl) {
          window.location.replace(response.redirectUrl);
        } else {
          toastr.error("Information not found");
          window.location.replace("/");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    loadUserOrBuilder(props.match.params.id);
  }, []);
  return <div></div>;
};

export default PublicProfileLandingPage;
