import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { appContext } from "../../App";
import { updateProperty } from "../../utils/api";
import toastr from "toastr";
import { withRouter } from "react-router";

function DeleteDeactiveProperty(props) {
  const { authUser } = useContext(appContext);
  const handleUpdateProperty = () => {
    let finalValues = {};
    finalValues["userId"] = authUser && authUser.unique_id;
    finalValues.status = props.action;
    updateProperty(finalValues, props.property._id)
      .then(() => {
        props.property.status = props.action;
        toastr.success("Property status updated successfully.");
        props.history.push(`/menubar/properties`);
      })
      .catch((error) => {
        toastr.error(error.message);
      });
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to {props.action === -6 ? "delete" : "deactivate"}
        ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deactivating a property will not show it in the home page or in the
          search results. Are you sure you want to deactivate this property?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleUpdateProperty}
          color="primary"
          variant="contained"
          autoFocus
        >
          {props.action === -6 ? "Delete" : "Deactivate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default withRouter(DeleteDeactiveProperty);
