import React, { useContext, useEffect, useState } from "react";
import cuid from "cuid";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import useFormValidation from "../../../hooks/useFormValidation";
import validateLogin from "../validateLogin";
import { login, resendOTP } from "../../../utils/api";
import { DASHBOARD_PAGE } from "../../../utils/utils";
import { appContext } from "../../../App";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./Login.scss";

function Login(props) {
  const { setAuthUser, openLogin, setOpenLogin, setOpenSignup, setfpOpen } =
    useContext(appContext);
  const [showPassword, setShowPassword] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);

  const initialState = {
    account: "",
    password: "",
  };

  const loginHandler = () => {
    login(values)
      .then((response) => {
        if (response.data.data.account_verified === true) {
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
          localStorage.setItem(
            "stripeCustomerId",
            response.data.data.stripeCustomerId !== ""
              ? response.data.data.stripeCustomerId
              : "null"
          );
          if (rememberMe) {
            localStorage.setItem("rememberMe", "true");
            localStorage.setItem("account", values.account);
            localStorage.setItem("password", values.password);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("account");
            localStorage.removeItem("password");
          }
          setAuthUser(response.data.data);
          const redirectUri = localStorage.getItem("redirectUri");
          if (redirectUri) {
            localStorage.removeItem("redirectUri");
            props.history.push(redirectUri);
          } else if (props.location.state) {
            props.history.push(props.location.state.from.pathname);
          } else {
            props.history.push(DASHBOARD_PAGE);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            const obj = {};
            obj.email = values.account;
            resendOTP(obj)
              .then((response) => {
                toastr.error(
                  "Your email is not yet verified. Please complete the email verification."
                );
                props.history.push({
                  pathname: `/otp-validation/${values.account}`,
                  state: { password: values.password },
                });
              })
              .catch((error) => {
                toastr.error(error.message);
              });
          } else if (error.response.data.statusCode === 404) {
            toastr.error(error.response.data.message);
          } else {
            toastr.error(
              error.response.data.message ||
                "Unable to login. Please try again!"
            );
          }
        }
      });
    setOpenLogin(false);
  };

  const googleLoginHandler = () => {
    setOpenLogin(false);
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/google/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=google",
      "_self"
    );
  };

  const facebookLoginHandler = () => {
    setOpenLogin(false);
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/facebook/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=facebook",
      "_self"
    );
  };

  const linkedInLoginHandler = () => {
    setOpenLogin(false);
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/linkedin/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=linkedin",
      "_self"
    );
  };

  const twitterLoginHandler = () => {
    setOpenLogin(false);
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/twitter/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=twitter",
      "_self"
    );
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };
  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateLogin, loginHandler);

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe && rememberMe === "true") {
      setValues({
        account: localStorage.getItem("account"),
        password: localStorage.getItem("password"),
      });
      setRememberMe(true);
    }
  }, []);

  const showRegisterScreen = () => {
    setOpenLogin(false);
    setOpenSignup(true);
  };

  const showForgotPassword = () => {
    setOpenLogin(false);
    setfpOpen(true);
  };
  return (
    <Dialog
      onClose={() => setOpenLogin(false)}
      aria-labelledby="customized-dialog-title"
      open={openLogin}
    >
      <div className="login-container">
        <div className="login-title">
          <Typography component="span" variant="h5">
            Login to your account
          </Typography>
          <IconButton
            aria-label="close"
            className="close-btn"
            onClick={() => setOpenLogin(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Grid>
          <Grid item>
            <div className="right">
              <div className="signIn-box drop-box">
                <div style={{ width: "100%", margin: "0 auto" }}>
                  <TextField
                    name="account"
                    style={{ width: "100%" }}
                    placeholder="Email address or Username"
                    value={values.account}
                    onChange={changeHandler}
                    label="Email address"
                    variant="outlined"
                    onKeyPress={(e) => handleEnterClick(e)}
                    className={`${errors.account && "error-text"}`}
                    id="email"
                  />
                  {errors.account ? (
                    <span className="validation-error">{errors.account}</span>
                  ) : (
                    ""
                  )}
                  <FormControl
                    className="full-width border-radius"
                    style={{ width: "100%" }}
                  >
                    <TextField
                      className={`${errors.password && "error-text"}`}
                      id="outlined-adornment-confirm-password"
                      name="password"
                      style={{ width: "100%", marginTop: 15 }}
                      variant="outlined"
                      label="Password"
                      type={showPassword ? "password" : "text"}
                      value={values.password}
                      onChange={changeHandler}
                      onKeyPress={(e) => handleEnterClick(e)}
                    />
                    <InputAdornment position="end" className="showpassword">
                      <IconButton
                        aria-label="toggle password visibility"
                        style={{ top: "15px" }}
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </FormControl>
                  {errors && (
                    <span className="validation-error">{errors.password}</span>
                  )}
                </div>
                <Grid container spacing={3} className="remembr-me">
                  <Grid item xs={12} sm={6} className="row3">
                    <input
                      style={{ cursor: "pointer", marginRight: 4 }}
                      type="checkbox"
                      id="remember_me"
                      className="remember-me-check"
                      name="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    Remember me
                  </Grid>
                  <Grid item xs={12} sm={6} className="row3">
                    <span
                      onClick={showForgotPassword}
                      className="onHover"
                      style={{ cursor: "pointer" }}
                    >
                      Forgot Password?
                    </span>
                  </Grid>
                </Grid>
                <Button
                  className="submit-button red_bg"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={submitHandler}
                >
                  Login
                </Button>
                <div className="noAccount">
                  Don't have an account?{" "}
                  <span onClick={showRegisterScreen} >
                  <a href= " #">
                       Signup
                    </a>
                  </span>
                </div>
                <div className="hr-line">
                  <span>or you can join with</span>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Grid container spacing={3} className="social-logins">
                    <ul>
                      <li onClick={googleLoginHandler}>
                        <img
                          src="/images/google_icon.svg"
                          alt="google"
                          width="25px"
                        />
                      </li>
                      <li onClick={facebookLoginHandler}>
                        <img
                          src="/images/facebook_icon.svg"
                          alt="facebook"
                          width="15px"
                        />
                      </li>
                      {/* <li onClick={linkedInLoginHandler}>
                        <img src="/favicon.ico" alt="linkedin" width="25px" />
                      </li> */}
                    </ul>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
export default Login;
