import React, { useContext, useState, Fragment } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-number-input";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { signup } from "../../../utils/api";
import useFormValidation from "../../../hooks/useFormValidation";
import validateSignup from "../validateSignup";
import { appContext } from "../../../App";
import "./Signup.scss";
import cuid from "cuid";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import validator from "validator";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUp = (props) => {
  const {
    userLocaleInfo,
    orgUserPayload,
    openSignup,
    setOpenSignup,
    setOpenLogin,
  } = useContext(appContext);
  const initialState = {
    email: orgUserPayload && orgUserPayload.email ? orgUserPayload.email : "",
    customer: "",
    first_name: "",
    last_name: "",
    password: "",
    referal: "",
    terms: false,
  };
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [otherErrors, setOtherErrors] = useState("");

  const signupHandler = () => {
    const finalValues = values;
    //validate the phone input
    setOtherErrors("");
    if (phone && !isPossiblePhoneNumber(phone)) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "Please enter valid phone number",
        };
      });
      return;
    } else {
      finalValues.phone = phone;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "",
        };
      });
    }
    if (finalValues.customer == "buyerowner") {
      finalValues.roles = ["buyer", "owner"];
    } else {
      finalValues.roles = [finalValues.customer];
    }

    signup(finalValues)
      .then((response) => {
        if (response) {
          // props.setPassword(finalValues.password);
          // props.setEmail(finalValues.email);
          setOpenSignup(false);
          props.history.push({
            pathname: `/otp-validation/${response.data.data.email}`,
            state: { password: finalValues.password },
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          toastr.warning(
            "You already have an account with Zenrth. Please sign in to continue."
          );
          //props.history.push("/kindness-event-signin");
        } else if (error.response && error.response.data) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const googleLoginHandler = () => {
    setOpenSignup(false);
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/google/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=google",
      "_self"
    );
  };

  const facebookLoginHandler = () => {
    setOpenSignup(false);
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/facebook/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=facebook",
      "_self"
    );
  };

  const showSigninPage = () => {
    setOpenLogin(true);
    setOpenSignup(false);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateSignup, signupHandler);

  var pswd = values.password;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const classes = useStyles();
  const handleCloseSignup = () => {
    setOpenSignup(false);
  };
  const updatePhone = (phone) => {
    setPhone("+" + phone);
  };
  const [mobileNumberLength, setMobileNumberLength] = useState(0);

  return (
    <Dialog
      onClose={handleCloseSignup}
      aria-labelledby="customized-dialog-title"
      open={openSignup}
    >
      <div className="login-container">
        <div className="login-title">
          <Typography component="span" variant="h5">
            Create an account
          </Typography>
          <IconButton
            aria-label="close"
            className="close-btn"
            onClick={handleCloseSignup}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Grid container>
          <Grid item>
            <div className="right">
              <div className="signup">
                <div className="customer">
                  <FormLabel className="lable">I am</FormLabel>
                  <RadioGroup
                    className={`full-width grid_spend  signup-input ${
                      errors.customer && "error-text"
                    }`}
                    row
                    aria-label="customer"
                    name="customer"
                    value={values.customer}
                    onChange={changeHandler}
                    onKeyPress={(e) => handleEnterClick(e)}
                  >
                    <FormControlLabel
                      value="buyerowner"
                      control={<Radio />}
                      label="Buyer/Owner"
                    />
                    <FormControlLabel
                      value="agent"
                      control={<Radio />}
                      label="Agent"
                    />
                    <FormControlLabel
                      value="builder"
                      control={<Radio />}
                      label="Builder"
                    />
                  </RadioGroup>
                  {errors && (
                    <div className="custom-error">{errors.customer}</div>
                  )}
                </div>
                <div className="flname">
                  <div className="names">
                    <TextField
                      className={`full-width border-radius signup-input ${
                        errors.first_name && "error-text"
                      }`}
                      variant="outlined"
                      label="First Name *"
                      name="first_name"
                      onChange={changeHandler}
                      onKeyPress={(e) => handleEnterClick(e)}
                      value={values.first_name}
                    />
                    {errors && (
                      <div className="custom-error">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="names">
                    <TextField
                      className={`full-width border-radius signup-input ${
                        errors.last_name && "error-text"
                      }`}
                      variant="outlined"
                      label="Last Name *"
                      name="last_name"
                      onChange={changeHandler}
                      onKeyPress={(e) => handleEnterClick(e)}
                      value={values.last_name}
                    />
                    {errors && (
                      <div className="custom-error">{errors.last_name}</div>
                    )}
                  </div>
                </div>
                <div className="textfield-container">
                  {orgUserPayload && orgUserPayload.email ? (
                    <TextField
                      className={`full-width border-radius signup-input  ${
                        errors.email && "error-text"
                      }`}
                      label="Email address *"
                      name="email"
                      variant="outlined"
                      onChange={changeHandler}
                      onKeyPress={(e) => handleEnterClick(e)}
                      value={values.email}
                      disabled
                    />
                  ) : (
                    <TextField
                      className={`full-width border-radius signup-input email ${
                        errors.email && "error-text"
                      }`}
                      label="Email address *"
                      name="email"
                      variant="outlined"
                      onChange={changeHandler}
                      onKeyPress={(e) => handleEnterClick(e)}
                      value={values.email}
                    />
                  )}
                  {errors && <div className="custom-error">{errors.email}</div>}
                  <div className="pwd">
                    <div className="pass-container">
                      <div className="names pass-field">
                        <FormControl
                          className="full-width border-radius"
                          style={{ width: "100%" }}
                        >
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.password && "error-text"
                            }`}
                            variant="outlined"
                            label="Password *"
                            type={showPassword ? "password" : "text"}
                            name="password"
                            onChange={changeHandler}
                            onKeyPress={(e) => handleEnterClick(e)}
                            value={values.password}
                          />
                          <InputAdornment
                            position="end"
                            className="showpassword"
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              style={{
                                top: "8px",
                                position: "absolute",
                                right: "10px",
                              }}
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>

                          {(errors ||
                            !pswd.match(/[a-z]/) ||
                            !pswd.match(/[A-Z]+/) ||
                            !pswd.match(/\d/) ||
                            !pswd.match(/[^a-zA-Z0-9\-\/]/) ||
                            pswd.length < 6) && (
                            <div className="custom-error">
                              {errors.password}
                            </div>
                          )}
                        </FormControl>
                      </div>

                      {pswd &&
                        (!pswd.match(/[a-z]/) ||
                          !pswd.match(/[A-Z]+/) ||
                          !pswd.match(/\d/) ||
                          !pswd.match(/[^a-zA-Z0-9\-\/]/) ||
                          pswd.length < 6) && (
                          <div className="password-strength">
                            <ul>
                              <li>
                                {pswd.match(/[a-z]/) ? (
                                  <Alert severity="success">
                                    Contains at least{" "}
                                    <strong>one letter</strong>
                                  </Alert>
                                ) : (
                                  <Alert severity="error">
                                    Contains at least{" "}
                                    <strong>one letter</strong>
                                  </Alert>
                                )}
                              </li>
                              <li>
                                {pswd.match(/[A-Z]+/) ? (
                                  <Alert severity="success">
                                    Contains at least{" "}
                                    <strong>one capital letter</strong>
                                  </Alert>
                                ) : (
                                  <Alert severity="error">
                                    Contains at least{" "}
                                    <strong>one capital letter</strong>
                                  </Alert>
                                )}
                              </li>
                              <li>
                                {pswd.match(/\d/) ? (
                                  <Alert severity="success">
                                    Contains at least <strong>one digit</strong>
                                  </Alert>
                                ) : (
                                  <Alert severity="error">
                                    Contains at least <strong>one digit</strong>
                                  </Alert>
                                )}
                              </li>
                              <li>
                                {pswd.match(/[^a-zA-Z0-9\-\/]/) ? (
                                  <Alert severity="success">
                                    Contains at least{" "}
                                    <strong>one special character</strong>
                                  </Alert>
                                ) : (
                                  <Alert severity="error">
                                    Contains at least{" "}
                                    <strong>one special character</strong>
                                  </Alert>
                                )}
                              </li>
                              <li
                                className={
                                  pswd.length >= 6 ? "valid" : "invalid"
                                }
                                style={{ "text-align": "start" }}
                              >
                                {pswd.length >= 6 ? (
                                  <Alert severity="success">
                                    Contains at least{" "}
                                    <strong>6 characters</strong>
                                  </Alert>
                                ) : (
                                  <Alert severity="error">
                                    Contains at least{" "}
                                    <strong>6 characters</strong>
                                  </Alert>
                                )}
                              </li>
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="signphone">
                    <span
                      style={{
                        position: "absolute",
                        fontSize: 12,
                        left: 10,
                        top: -10,
                        background: "#fff",
                        padding: "0 6px",
                        zIndex: "99",
                        color: "gray",
                      }}
                    >
                      Phone number
                    </span>
                    <PhoneInput
                    className="full-width border-radius signup-input"
                      variant="outlined"
                      fullWidth
                      placeholder=" Phone number "
                      name=" Phone number"
                      onChange={setPhone}
                      defaultCountry={
                        userLocaleInfo && userLocaleInfo.country_code
                      }
                      value={phone}
                      onKeyPress={(e) => handleEnterClick(e)}
                    />
                    {otherErrors && otherErrors.phone && (
                      <div className="custom-error">{otherErrors.phone}</div>
                    )}
                  </div>
                </div>
                <p className="terms">
                  By clicking Sign Up, you agree to Zenrth{" "}
                  <a href=" /TermsofUse" target="blank">
                    Terms & Conditions
                  </a>
                </p>
                <div className="register-btn">
                  <Button
                    color="primary"
                    className="signup-btn red_bg"
                    variant="contained"
                    type="submit"
                    onClick={submitHandler}
                  >
                    Sign Up
                  </Button>
                </div>
                <div className="sub-header-name">
                  Already have an account?
                  <span className="forgot-password">
                    <span onClick={() => showSigninPage()} className="onHover">
                      <a href=" #">Login</a>
                    </span>
                  </span>
                </div>
                {!orgUserPayload && (
                  <Fragment>
                    <div className="hr-line">
                      <span>or you can join with</span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Grid container spacing={3} className="social-logins">
                        <ul>
                          <li onClick={googleLoginHandler}>
                            <img
                              src="/images/google_icon.svg"
                              alt="google"
                              width="25px"
                            />
                          </li>
                          <li onClick={facebookLoginHandler}>
                            <img
                              src="/images/facebook_icon.svg"
                              alt="facebook"
                              width="15px"
                            />
                          </li>
                        </ul>
                      </Grid>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default withRouter(SignUp);
